import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
    }, {
        path: '/market',
        name: 'Market',
        component: () => import('@/views/Market.vue'),
    }, {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/user/Login.vue'),
    }, {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/user/Register.vue'),
    }, {
        path: '/forget',
        name: 'Forget',
        component: () => import('@/views/user/Forget.vue'),
    }, {
        path: '/assets',
        name: 'Assets',
        component: () => import('@/views/assets/Index.vue'),
    }, {
        path: '/contract',
        name: 'Contract',
        component: () => import('@/views/contract/Index.vue'),
    }, {
        path: '/kline',
        name: 'Kline',
        component: () => import('@/views/KLine.vue'),
    }, {
        path: '/news/details',
        name: 'details',
        component: () => import('@/views/notice/Details.vue'),
    }, {
        path: '/notice/details',
        name: 'Ndetails',
        component: () => import('@/views/notice/Details.vue'),
    }, {
        path: '/support/details',
        name: 'Sdetails',
        component: () => import('@/views/notice/Details.vue'),
    }, {
        path: '/agreement',
        name: 'Agreement',
        component: () => import('@/views/notice/Details.vue'),
    }, {
        path: '/notify/details',
        name: 'notifyDetails',
        component: () => import('@/views/notice/Details.vue'),
    }, {
        path: '/finance',
        name: 'finance',
        component: () => import('@/views/finance/Index.vue'),
    }, {
        path: '/finance/mining',
        name: 'Mining',
        component: () => import('@/views/finance/mining/Index.vue'),
    }, {
        path: '/mining/list',
        name: 'MiningList',
        component: () => import('@/views/finance/mining/ProductList.vue'),
    }, {
        path: '/mining/records',
        name: 'MiningRecords',
        component: () => import('@/views/finance/Records.vue'),
    }, {
        path: '/finance/loan',
        name: 'FinanceLoan',
        component: () => import('@/views/finance/loan/Index.vue'),
    }, {
        path: '/finance/loan/details',
        name: 'FinanceLoanDetails',
        component: () => import('@/views/finance/loan/ProductDetails.vue'),
    }, {
        path: '/loan/records',
        name: "LoanRecords",
        component: () => import('@/views/finance/loan/components/Records.vue')
    }, {
        path: '/loan/apply',
        name: "LoanApply",
        component: () => import('@/views/finance/loan/ApplyQuota.vue')
    }, {
        path: '/loan/apply/form',
        name: "LoanApplyForm",
        component: () => import('@/views/finance/loan/ApplyForm.vue')
    }, {
        path: '/loan/apply/call',
        name: "LoanApplyCall",
        component: () => import('@/views/finance/loan/ApplyCallback.vue')
    }, {
        path: '/finance/quantify',
        name: 'FinanceAI',
        component: () => import('@/views/finance/quantify/Index.vue'),
    }, {
        path: '/finance/quantify/details',
        name: 'FinanceAIDetails',
        component: () => import('@/views/finance/quantify/ProductDetail.vue'),
    }, {
        path: '/finance/quantify/buy',
        name: 'FinanceAIBuy',
        component: () => import('@/views/finance/quantify/BuyForm.vue'),
    }, {
        path: '/finance/quantify/records',
        name: 'FinanceAIRecords',
        component: () => import('@/views/finance/quantify/components/Records.vue'),
    }, {
        path: '/transfer',
        name: 'Transfer',
        component: () => import('@/views/transfer/Index.vue'),
    }, {
        path: '/transfer/records',
        name: 'TransferRecords',
        component: () => import('@/views/transfer/Records.vue'),
    }, {
        path: '/flash/records',
        name: 'FlashRecords',
        component: () => import('@/views/transfer/Records.vue'),
    }, {
        path: '/deposit',
        name: 'Deposit',
        component: () => import('@/views/assets/deposit/Index.vue'),
    }, {
        path: '/deposit/speedy',
        name: 'DepositSpeedy',
        component: () => import('@/views/assets/deposit/DepositSpeedy.vue'),
    }, {
        path: '/deposit/wallet',
        name: 'DepositWallet',
        component: () => import('@/views/assets/deposit/DepositWallet.vue'),
    }, {
        path: '/dw/records',
        name: 'DWRecords',
        component: () => import('@/views/assets/Records.vue'),
    }, {
        path: '/withdraw',
        name: 'Withdraw',
        component: () => import('@/views/assets/withdraw/Index.vue'),
    }, {
        path: '/usercenter',
        name: 'UserCenter',
        component: () => import('@/views/usercenter/Index.vue'),
    }, {
        path: "/support",
        name: "Support",
        component: () => import('@/views/notice/Index.vue')
    }, {
        path: "/notice",
        name: "Notice",
        component: () => import('@/views/notice/Index.vue')
    }, {
        path: "/contract/notice",
        name: "ContractNotice",
        component: () => import('@/views/notice/Index.vue')
    }, {
        path: "/whithdraw/notice",
        name: "whithdrawNotice",
        component: () => import('@/views/notice/Index.vue')
    }, {
        path: "/deposit/notice",
        name: "depositNotice",
        component: () => import('@/views/notice/Index.vue')
    }, {
        path: "/systems/notice",
        name: "systemsNotice",
        component: () => import('@/views/notice/Index.vue')
    }, {
        path: "/about",
        name: "About",
        component: () => import('@/views/notice/Details.vue')
    }, {
        path: "/guide",
        name: "Gudie",
        component: () => import('@/views/notice/Details.vue')
    }, {
        path: "/flash",
        name: "Flash",
        component: () => import('@/views/flash/Index.vue')
    }, {
        path: "/more",
        name: "More",
        component: () => import('@/views/more/Index.vue')
    }, {
        path: "/delivery",
        name: "Delivery",
        component: () => import('@/views/delivery/Index.vue')
    }, {
        path: "/setting",
        name: "Setting",
        component: () => import('@/views/setting/Index.vue')
    }, {
        path: "/notify",
        name: "Notify",
        component: () => import('@/views/message/Index.vue')
    }, {
        path: "/secure",
        name: "SecureCenter",
        component: () => import('@/views/usercenter/SecureCenter.vue')
    }, {
        path: "/customer",
        name: "Customer ",
        component: () => import('@/views/customer/Index.vue')
    }, {
        path: "/bill",
        name: "Bill ",
        component: () => import('@/views/bill/Index.vue')
    }, {
        path: "/share",
        name: "Share ",
        component: () => import('@/views/share/Index.vue')
    }, {
        path: "/share/records",
        name: "ShareRecords",
        component: () => import('@/views/share/Records.vue')
    }, {
        path: "/certification",
        name: "Certification",
        component: () => import('@/views/usercenter/Certification.vue')
    }, {
        path: "/certification/form",
        name: "CertificationForm",
        component: () => import('@/views/usercenter/components/CertificationForm.vue')
    }, {
        path: "/c2c",
        name: "C2c",
        component: () => import('@/views/c2c/Index.vue')
    }, {
        path: "/c2c/order",
        name: "C2cOrder",
        component: () => import('@/views/c2c/Order.vue')
    }, {
        path: "/c2c/order/confirm",
        name: "C2cOrderConfirm",
        component: () => import('@/views/c2c/OrderConfirm.vue')
    }, {
        path: "/c2c/order/records",
        name: "C2cOrderRecords",
        component: () => import('@/views/c2c/Records.vue')
    }, {
        path: "/c2c/payment",
        name: "C2cPayment",
        component: () => import('@/views/c2c/PaymentMethod.vue')
    }, {
        path: "/c2c/payment/details",
        name: "C2cPaymentDetails",
        component: () => import('@/views/c2c/PaymentDetail.vue')
    }, {
        path: "/c2c/payment/add",
        name: "C2cPaymentAdd",
        component: () => import('@/views/c2c/PaymentAdd.vue')
    }, {
        path: "/receive",
        name: "Receive",
        component: () => import('@/views/assets/Receive.vue')
    }, {
        path: "/credit",
        name: "Credit",
        component: () => import('@/views/usercenter/CreditScore.vue')
    }, {
        path: "/download",
        name: "Download",
        component: () => import('@/views/download/Index.vue')
    }, {
        path: "/download/des",
        name: "DownloadDes",
        component: () => import('@/views/download/Description.vue')
    }, {
        path: "/safari/guide",
        name: "SafariGuide",
        component: () => import('@/views/download/SafariGuide.vue')
    },
]

const router = new VueRouter({
    mode: 'hash',
    routes,
})
router.beforeEach((to, from, next) => {
    if (to.path == "/loan/apply/form") {
        if (from.path == '/agreement') {
            to.meta.noreload = "1"
        } else {
            to.meta.noreload = ""
        }
    }
    next()
})

export default router;