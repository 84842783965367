import Vue from 'vue'
import { mapActions, mapMutations, mapGetters } from "vuex"
Vue.mixin({
    computed: {
        ...mapGetters(["getLanguage", "getRateList", "getCurrentRate"]),
        rateData() {
            let current = this.getRateList.filter(item => {
                return item.code == this.getCurrentRate.code
            })
            return current && current[0];
        },
    },
    methods: {
        ...mapActions(["userInfoFetch", "userCertificationFetch"]),
        ...mapMutations(["setUserInfo", "setIsLogin", "setAuthInfo"]),

        getDecimal(value) {
            let val = Math.abs(value)
            if (val > 1 || val == 0) {
                return 2;
            } else if (val <= 1 && val > 0.01) {
                return 4;
            } else if (val <= 0.01 && val > 0.001) {
                return 5;
            } else if (val <= 0.001 && val > 0.00001) {
                return 6;
            } else {
                return 8;
            }
        },
        formatTimestamp(time) {
            let offset = new Date().getTimezoneOffset();
            let seconds = offset * 60;
            return time - seconds;
        },

        formatTime(bj_time) {
            let offset = new Date().getTimezoneOffset();
            let range = -8 - (offset / 60);
            let date = new Date(bj_time).getTime() + (range * 60 * 60 * 1000);
            let pyz = (offset / 60) > 0 ? '-' + Math.abs((offset / 60)) : "+" + Math.abs((offset / 60))
            return `${new Date(date).toLocaleString()} (UTC${pyz})`;
        },
        formatTimeLocalDate(time) {
            return new Date(time).toLocaleDateString().replaceAll("/", "-")
        },
        randomString(e) {
            e = e || 32;
            var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
                a = t.length,
                n = "";
            for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
            return n
        },
        toFixed(val, decimal = null) {
            if (isNaN(val)) {
                return '--'
            }
            let _decimal = decimal ? decimal : this.getDecimal(val)
            return Number(val).toFixed(_decimal)
        },
        decimalFixed(val, decimal = null) {
            let _decimal = decimal ? decimal : this.getDecimal(val)
            let b = Math.pow(10, _decimal);
            let num = (parseInt(val * b) / b).toFixed(_decimal);
            if (isNaN(num)) {
                return '--'
            }
            return num
        },
        numConvert(num, decimal = null) {
            if (num == '--' || num === 0 || isNaN(num) || num == Infinity || num == 'undefined') {
                return 0
            }
            let multiple, unit;
            if (num >= 1000000000000000) {
                multiple = 10000000000000
                unit = 'Q'
            } else if (num >= 1000000000000) {
                multiple = 10000000000
                unit = 'T'
            } else if (num >= 1000000000) {
                multiple = 10000000
                unit = 'B'
            } else if (num >= 1000000) {
                multiple = 10000
                unit = 'M'
            } else {
                multiple = 10
                unit = 'K'
            }
            if (num >= 1000) {
                let strNum = String(num / multiple / 100),
                    sp = strNum.split('.');
                if (sp[1]) {
                    return sp[0] + '.' + sp[1].slice(0, 2) + unit
                }
                return sp + unit
            }
            if (decimal) {
                return this.toFixed(num, decimal)
            }
            return num
        },
        reloadLangs() {
            if (this.getLanguage == "Simplified_Chinese") {
                this.$vuetify.lang.current = "zhHans";
            } else if (this.getLanguage == "Traditional_Chinese") {
                this.$vuetify.lang.current = "zhHant";
            } else if (this.getLanguage == "English") {
                this.$vuetify.lang.current = "en";
            } else if (this.getLanguage == "Spanish") {
                this.$vuetify.lang.current = "es";
            } else if (this.getLanguage == "Spanish") {
                this.$vuetify.lang.current = "es";
            } else if (this.getLanguage == "Japanese") {
                this.$vuetify.lang.current = "ja";
            } else if (this.getLanguage == "Thai") {
                this.$vuetify.lang.current = "th";
            } else if (this.getLanguage == "Korean") {
                this.$vuetify.lang.current = "ko";
            } else if (this.getLanguage == "Vietnamese") {
                this.$vuetify.lang.current = "vi";
            } else if (this.getLanguage == "German") {
                this.$vuetify.lang.current = "de";
            } else if (this.getLanguage == "French") {
                this.$vuetify.lang.current = "fr";
            } else if (this.getLanguage == "Swedish") {
                this.$vuetify.lang.current = "sv";
            } else if (this.getLanguage == "Portuguese") {
                this.$vuetify.lang.current = "pt";
            } else if (this.getLanguage == "Indonesia") {
                this.$vuetify.lang.current = "id";
            } else if (this.getLanguage == "Polish") {
                this.$vuetify.lang.current = "pl";
            } else if (this.getLanguage == "Dutch") {
                this.$vuetify.lang.current = "nl";
            } else if (this.getLanguage == "Italian") {
                this.$vuetify.lang.current = "it";
            } else if (this.getLanguage == "Russian") {
                this.$vuetify.lang.current = "ru";
            } else if (this.getLanguage == "Arabic") {
                this.$vuetify.lang.current = "ar";
            }
        },
        async getUserInfoFuc() {
            let res = await this.userInfoFetch();
            if (res.errCode == 0) {
                this.setUserInfo(res.data);
                this.setIsLogin(true);
            }
        },
        async getUserCertification() {
            let res = await this.userCertificationFetch();
            if (res.errCode == 0) {
                this.setAuthInfo(res.data);
            }
        },
    }
})