import axios from 'axios'
import config from '@/config'
import { getCookie } from '@/utils/cookie'
// 创建axios实例


const service = axios.create({
    baseURL: config.BASEURL, // api的base_url
    timeout: 40000, // 请求超时时间,
    headers: {
        // "Content-Type": "application/x-www-form-urlencoded"
        "Content-Type": "application/json",
        // "App_language": getCookie("lang"),
        // "App_auth_by_token": getCookie('user-cookie'),
        // "App_analog": getCookie('account-mode'),
        // "App_client_timezone": offset
    },
})
// request拦截器
service.interceptors.request.use(configs => {
    let offset = -(new Date().getTimezoneOffset());
    offset = offset > 0 ? '+' + offset / 60 : '-' + offset / 60
    configs.headers = {
        ...configs.headers,
        "App_language": getCookie("lang"),
        "App_auth_by_token": getCookie('user-cookie'),
        "App_analog": getCookie('account-mode'),
        "App_client_timezone": offset
    }
    return configs;
}, error => {
    Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
    response => {
        if (response.status == 200) {
            if (response.data.errCode == 10010) {
                if (response.config.url == "/api/app/user/get/info" || response.config.url == '/api/app/funds/overview') {
                    return response.data
                } else {
                    location.href = '/#/login'
                }
                return
            } else {
                return response.data
            }

        }
    },
    async error => {
        return Promise.reject(error)
    }
)




export default service