let config = {
    // BASEURL: window.location.origin
    BASEURL: "https://main.media176.com",
    WS_URL: "wss://main.media176.com/ws/sub-quotes",
    CHAT_WS_URL: "wss://main.media176.com/ws/app/chat/",
    CHAT_C2C_WS_URL: "wss://main.media176.com/ws/app/c2c/chat/"
}
if (process.env.NODE_ENV == 'development') {
    config = {
        BASEURL: 'https://main.media176.com',
        WS_URL: "wss://main.media176.com/ws/sub-quotes",
        CHAT_WS_URL: "wss://main.media176.com/ws/app/chat/",
        CHAT_C2C_WS_URL: "wss://main.media176.com/ws/app/c2c/chat/"
    }
}
export default config;