export default {
    setIsLogin: (state, v) => state.isLogin = v,
    setConfigData: (state, v) => state.configData = v,
    setBaseQuotes: (state, v) => state.baseQuotes = v,
    setUpdateQuotes: (state, v) => state.updateQuotes = v,
    setInnerHeight: (state, v) => state.innerHeight = v,
    setSymbolList: (state, v) => state.symbolList = v,
    setIsShowBalance: (state, v) => state.showBalance = v,
    setInvestData: (state, v) => state.investData = v,
    setUserInfo: (state, v) => state.userInfo = v,
    setLanguage: (state, v) => state.language = v,
    setRateList: (state, v) => state.rateList = v,
    setCurrentRate: (state, v) => state.currentRate = v,
    setAuthInfo: (state, v) => state.authInfo = v,
    setThemeType: (state, v) => state.themeType = v,
    setSocket: (state, v) => state.socket = v,
    setQuoteData: (state, v) => state.quoteData = v,
    setLanguageList: (state, v) => state.languageList = v,
    setContractQuote: (state, v) => state.contractQuote = v,
    setMiningData: (state, v) => state.miningData = v,
    setFunds: (state, v) => state.funds = v,
    setLoanList: (state, v) => state.loanList = v,
    setDeliveryQuote: (state, v) => state.deliveryQuote = v,
    setSelectFiatCoin: (state, v) => state.selectFiatCoin = v,
    setSocketLastTime: (state, v) => state.socketLastTime = v,
    setFormFiles: (state, v) => state.formFiles = v,
    setFormImgs: (state, v) => state.formImgs = v
}