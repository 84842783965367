// import service from '@/config/fetch'
const version = '/api/app'
import service from "../config/fetch"
import { getCookie } from "@/utils/cookie"
export default {
    basisSlideFetch: async (ctx, data) => {
        return service({
            url: `${version}/basis/slide`,
            method: 'post',
            data
        })
    },
    //语言
    languageFetch: async (ctx, data) => {
        return service({
            url: `${version}/language`,
            method: 'post',
            data
        })
    },
    //产品分类
    coinListFetch: async (ctx, data) => {
        return service({
            url: `${version}/coin/list`,
            method: 'post',
            data
        })
    },
    //首页公告
    basisNewsFetch: async (ctx, data) => {
        return service({
            url: `${version}/basis/news`,
            method: 'post',
            data
        })
    },
    //首页资讯
    basisNoticeFetch: async (ctx, data) => {
        return service({
            url: `${version}/basis/notice`,
            method: 'post',
            data
        })
    },
    //
    quotesSortFetch: async (ctx, data) => {
        return service({
            url: `${version}/quotes/sort`,
            method: 'post',
            data
        })
    },
    // 公告 有详情
    basisArticleDetailListFetch: async (ctx, data) => {
        return service({
            url: `${version}/basis/article/detail/list`,
            method: 'post',
            data
        })
    },
    // 配置
    configFetch: async (ctx, data) => {
        return service({
            url: `${version}/config`,
            method: 'post',
            data
        })
    },
    // 根据合约历史行情
    quotesGetFetch: async (ctx, data) => {
        return service({
            url: `/quotes/get`,
            method: 'post',
            data
        })
    },
    // 历史行情
    quotesHistoryFetch: async (ctx, data) => {
        return service({
            url: `${version}/quotes/history`,
            method: 'post',
            data
        })
    },
    // 汇率
    fundsUSDTLegalTenderFetch: async (ctx, data) => {
        if (ctx.state.rateList.length > 0) {
            return {
                errCode: 0,
                data: ctx.state.rateList
            }
        }
        let response = await service({
            url: `${version}/funds/usdt/legal/tender`,
            method: 'post',
            data
        })
        if (response.errCode == 0) {
            ctx.commit('setRateList', response.data)
        }
        return response
    },
    // 交易对接口
    symbolListFetch: async (ctx, data) => {
        return service({
            url: `${version}/symbol/list`,
            method: 'post',
            data
        })
    },
    // symbol type
    symbolTypeFetch: async (ctx, data) => {
        return service({
            url: `${version}/symbol/type`,
            method: 'get',
            params: data
        })
    },
    //根据id 获取最新行情
    quotesGetNewIdFetch: async (ctx, data) => {
        return service({
            url: `${version}/quotes/get/new/id`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/user/login
    userLoginFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/login`,
            method: 'post',
            data
        })
    },
    //资金汇总
    fundsOverviewFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/overview`,
            method: 'post',
            data
        })
    },
    //划转配置
    fundsConversionConfigFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/conversion/config`,
            method: 'get'
        })
    },
    // 划转提交
    conversionSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/conversion/submit`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/spot/varieties
    spotVarietiesFetch: async (ctx, data) => {
        return service({
            url: `${version}/spot/varieties`,
            method: 'get'
        })
    },
    // 闪兑记录
    spotOrderFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/exchange/rec`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/spot/submit
    spotSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/spot/submit`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/funds/rec
    fundsRecFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/rec`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/funds/type
    fundsTypeFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/type`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/funds/wallet/money
    fundsWalletMoneyFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/wallet/money`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/conversion/config
    conversionConfigFetch: async (ctx, data) => {
        return service({
            url: `${version}/conversion/config`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/funds/wallet/type
    fundsWalletTypeFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/wallet/type`,
            method: 'post',
            data
        })
    },
    // 划转记录
    conversionRecFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/conversion/rec`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/demo/reset/assets
    demoResetAssetsFetch: async (ctx, data) => {
        return service({
            url: `${version}/demo/reset/assets`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/second/product
    secondProductFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/product`,
            method: 'get',
            params: data
        })
    },
    // https://api.tldrus.com/api/app/second/product/period
    secondProductPeriodFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/product/period`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/second/now/period
    secondNowPeriodFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/now/period`,
            method: 'post',
            data
        })
    },
    //永续资产信息
    perpetualUserPerpetuaInfoFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/userPerpetuaInfo`,
            method: 'post',
            data
        })
    },

    secondOrderListFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/orders`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/second/order/submit
    secondOrderSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/order/submit`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/second/order/submit/by/time
    secondOrderSubmitByTimeFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/order/submit/by/time`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/now
    nowFetch: async (ctx, data) => {
        return service({
            url: `${version}/now`,
            method: 'post',
            data
        })
    },
    //初始行情数据
    quotesGetByNewFetch: async (ctx, data) => {
        return service({
            url: `/quotes/getByNew`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/second/order/cancel
    secondOrderCancelFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/order/cancel`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/perpetual/varieties
    perpetualVarietiesFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/varieties`,
            method: 'get'
        })
    },
    //深度数据
    perpetualQuotesDepthFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/quotesDepth`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/perpetual/config
    //杠杠配置
    perpetualConfigFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/config`,
            method: 'get'
        })
    },
    // https://api.tldrus.com/api/app/quotes/depth
    //深度
    quotesDepthFetch: async (ctx, data) => {
        return service({
            url: `${version}/quotes/depth`,
            method: 'post',
            data
        })
    },
    //用户权益
    perpetualUserInfoFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/user/perpetual/info`,
            method: 'get'
        })
    },
    // 用户信用分
    userCreditGetScoreFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/credit/getScore`,
            method: 'post'
        })
    },
    // 等级
    userLevelIconFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/level/icon`,
            method: 'post'
        })
    },
    // 永续下单
    perpetualOrderSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/order/submit`,
            method: 'post',
            data
        })
    },
    //持仓记录
    perpetualPositionFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/position`,
            method: 'post',
            data
        })
    },
    //委托记录
    perpetualPlanFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/plan`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/perpetual/change/position/stop/win/lose
    perpetualChangeFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/order/changeStopLossOrWin`,
            method: 'post',
            data
        })
    },
    //cancel
    perpetualCancelFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/plan/cancel`,
            method: 'post',
            data
        })
    },
    //历史订单
    perpetualOrderFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/order`,
            method: 'post',
            data
        })
    },
    //场外OTC
    rechargeOtcFetch: async (ctx, data) => {
        return service({
            url: `${version}/recharge/otc`,
            method: 'post',
            data
        })
    },
    //visa充值通道
    thirdRechargeChannelListFetch: async (ctx, data) => {
        return service({
            url: `${version}/third/party/recharge/channel/list`,
            method: 'get',
        })
    },
    //
    thirdRechargeChannelSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/third/party/recharge/channel/pay/view`,
            method: 'post',
            data
        })
    },
    // 充值通道
    rechargePaymentFetch: async (ctx, data) => {
        return service({
            url: `${version}/recharge/payment`,
            method: 'post',
            data
        })
    },
    // 充值通道对应的上分地址
    rechargePaymentInfoFetch: async (ctx, data) => {
        return service({
            url: `${version}/recharge/payment/info`,
            method: 'post',
            data
        })
    },
    // 充值
    rechargePaymentSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/recharge/payment/submit`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/upload/s3/pre-signed
    //图片签名
    uploadSignedFetch: async (ctx, data) => {
        return service({
            url: `${version}/upload/s3/pre-signed`,
            method: 'post',
            data
        })
    },
    // 上传文件
    commonUploadFetch: async (ctx, data) => {
        return service({
            url: `/common/upload`,
            method: 'post',
            data,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    },
    //提示文案
    promptPromptFetch: async (ctx, data) => {
        return service({
            url: `${version}/prompt/prompt`,
            method: 'post',
            data
        })
    },
    //ai量化产品列表
    aiProductListFetch: async (ctx, data) => {
        return service({
            url: `${version}/mining/ai/product/list`,
            method: 'get',
        })
    },
    // 充值记录
    rechargePaymentRecFetch: async (ctx, data) => {
        return service({
            url: `${version}/recharge/payment/rec`,
            method: 'post',
            data
        })
    },
    //提现地址
    withdrawAddressCoinFetch: async (ctx, data) => {
        return service({
            url: `${version}/withdraw/address/coin`,
            method: 'post',
            data
        })
    },
    //添加地址
    withdrawAddressAddFetch: async (ctx, data) => {
        return service({
            url: `${version}/withdraw/address/add`,
            method: 'post',
            data
        })
    },
    // 提现
    withdrawSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/withdraw/submit`,
            method: 'post',
            data
        })
    },
    // 提现记录
    withdrawRecFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/withdraw/rec`,
            method: 'post',
            data
        })
    },
    // 文章列表
    basisArticleListFetch: async (ctx, data) => {
        return service({
            url: `${version}/basis/article/list`,
            method: 'post',
            data
        })
    },
    //帮助中心文字
    basisHelpFetch: async (ctx, data) => {
        return service({
            url: `${version}/basis/help`,
            method: 'post',
            data
        })
    },
    //系统消息
    messageCenterRecordFetch: async (ctx, data) => {
        return service({
            url: `${version}/message/center/record`,
            method: 'post',
            data
        })
    },
    //根据id获取文章详情
    basisArticleDetailFetch: async (ctx, data) => {
        return service({
            url: `${version}/basis/article/detail`,
            method: 'post',
            data
        })
    },
    //获取文章单篇
    basisArticleFetch: async (ctx, data) => {
        return service({
            url: `${version}/basis/article`,
            method: 'post',
            data
        })
    },
    // 区号国家
    userPhoneCountryFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/phone/country`,
            method: 'post',
            data
        })
    },
    // 注册
    userRegisterFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/register`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/user/send-email-code
    userEmailCodeFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/send-email-code`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/user/send-phone-code
    userPhoneCodeFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/send-phone-code`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/user/find-password
    userFindPasswordFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/find-password`,
            method: 'post',
            data
        })
    },
    //挖矿产品
    miningProductFetch: async (ctx, data) => {
        return service({
            url: `${version}/mining/product`,
            method: 'post',
            data
        })
    },
    // 挖矿提交
    miningOrderSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/mining/order/submit`,
            method: 'post',
            data
        })
    },
    //挖矿订单
    miningOrderFetch: async (ctx, data) => {
        return service({
            url: `${version}/mining/order`,
            method: 'post',
            data
        })
    },
    //提前赎回
    miningOrderRedemptionFetch: async (ctx, data) => {
        return service({
            url: `${version}/mining/order/early-redemption`,
            method: 'post',
            data
        })
    },
    //挖矿收益
    miningIncomeFetch: async (ctx, data) => {
        return service({
            url: `${version}/mining/income`,
            method: 'post',
            data
        })
    },
    //用户信息
    userInfoFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/get/info`,
            method: 'post',
            data
        })
    },
    //认证信息
    userCertificationFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/certification/status`,
            method: 'post',
            data
        })
    },
    //获取消息
    chatRecordFetch: async (ctx, data) => {
        return service({
            url: `${version}/chat/record`,
            method: 'post',
            data
        })
    },
    //最后一条消息
    chatLatestRecordIdFetch: async (ctx, data) => {
        return service({
            url: `${version}/chat/latest/record/id`,
            method: 'post',
            data
        })
    },
    //问候语
    chatWelcomeMessageFetch: async (ctx, data) => {
        return service({
            url: `${version}/chat/welcome_message`,
            method: 'post',
            data
        })
    },
    //发送消息
    chatSendFetch: async (ctx, data) => {
        return service({
            url: `${version}/chat/send`,
            method: 'post',
            data
        })
    },
    //修改头像
    userUpdateAvatarFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/update/header/img`,
            method: 'post',
            data
        })
    },
    //修改昵称
    userUpdateNickNameFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/update/nick/name`,
            method: 'post',
            data
        })
    },
    //修改密码
    userChangePasswordFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/change-password`,
            method: 'post',
            data
        })
    },
    //修改邮箱
    userChangeEmailFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/change-email`,
            method: 'post',
            data
        })
    },
    //修改手机
    userChangePhoneFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/change-phone`,
            method: 'post',
            data
        })
    },
    //初级验证
    userCertificationOrdinaryFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/certification/ordinary`,
            method: 'post',
            data
        })
    },
    //高级验证
    userCertificationAdvancedFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/certification/advanced`,
            method: 'post',
            data
        })
    },
    //谷歌验证
    userGoogleAuthFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/google/auth`,
            method: 'post',
            data
        })
    },
    //开启关闭谷歌
    userGoogleAuthOpenFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/google/auth/open`,
            method: 'post',
            data
        })
    },
    //邀请
    userShareFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/share`,
            method: 'post',
            data
        })
    },
    //用户等级
    rankUserFetch: async (ctx, data) => {
        return service({
            url: `${version}/rank/user`,
            method: 'get',
        })
    },
    //用户等级
    rankTeamFetch: async (ctx, data) => {
        return service({
            url: `${version}/rank/team`,
            method: 'get',
        })
    },
    //团队
    shareTeamFetch: async (ctx, data) => {
        return service({
            url: `${version}/share/team`,
            method: 'post',
            data
        })
    },
    //返佣记录
    shareMyAwardFetch: async (ctx, data) => {
        return service({
            url: `${version}/share/my/award`,
            method: 'post',
            data
        })
    },
    //历史趋势
    fundsOverviewIncomeFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/overview/income`,
            method: 'post',
            data,
        })
    },
    //信用借贷产品
    borrowCoinFetch: async (ctx, data) => {
        return service({
            url: `${version}/borrow/coin`,
            method: 'post',
            data,
        })
    },
    //standardId 产品
    borrowProductFetch: async (ctx, data) => {
        return service({
            url: `${version}/borrow/product`,
            method: 'post',
            data,
        })
    },
    //用户借贷数据
    borrowMyInfoFetch: async (ctx, data) => {
        return service({
            url: `${version}/borrow/myInfo`,
            method: 'post',
            data,
        })
    },
    // 借贷协议
    basisArticleByTypeFetch: async (ctx, data) => {
        return service({
            url: `${version}/basis/article/byType`,
            method: 'post',
            data,
        })
    },
    //借贷submit
    borrowOrderSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/borrow/order/submit`,
            method: 'post',
            data,
        })
    },
    //当前借贷记录
    borrowOrderFetch: async (ctx, data) => {
        return service({
            url: `${version}/borrow/order`,
            method: 'post',
            data,
        })
    },
    //提前还贷
    borrowOrderRepaymentFetch: async (ctx, data) => {
        return service({
            url: `${version}/borrow/order/repayment`,
            method: 'post',
            data,
        })
    },
    //抵押类型
    borrowApplyInfoFetch: async (ctx, data) => {
        return service({
            url: `${version}/borrow/apply/info`,
            method: 'post',
        })
    },
    //提交借贷申请
    borrowApplySubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/borrow/apply/submit`,
            method: 'post',
            data
        })
    },
    //申请状态
    borrowApplyFetch: async (ctx, data) => {
        return service({
            url: `${version}/borrow/apply`,
            method: 'post',
            data
        })
    },
    //获取授权状态
    rechargePaymentAddressApproveFetch: async (ctx, data) => {
        return service({
            url: `${version}/recharge/payment/address/is/approve`,
            method: 'post',
            data
        })
    },
    //支付接口
    rechargePaymentAddressPayFetch: async (ctx, data) => {
        return service({
            url: `${version}/recharge/payment/address/normal/pay`,
            method: 'post',
            data
        })
    },
    //授权提交
    rechargePaymentAddressApprovePayFetch: async (ctx, data) => {
        return service({
            url: `${version}/recharge/payment/address/approve/pay`,
            method: 'post',
            data
        })
    },
    //轮询充值结果
    rechargePaymentAddressApprovePayResultFetch: async (ctx, data) => {
        return service({
            url: `${version}/recharge/payment/address/approve/pay/result`,
            method: 'post',
            data
        })
    },
    //闪兑汇率
    fundsExchangeRateFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/exchange/rate`,
            method: 'post',
            data
        })
    },
    //闪兑提交
    fundsExchangeSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/exchange/submit`,
            method: 'post',
            data
        })
    },
    //mining 配置
    miningConfigFetch: async (ctx, data) => {
        return service({
            url: `${version}/mining/config`,
            method: 'get'
        })
    },
    //交割产品列表
    futuresProductFetch: async (ctx, data) => {
        return service({
            url: `${version}/futures/product`,
            method: 'post',
            data
        })
    },
    //交割配置
    futuresConfigFetch: async (ctx, data) => {
        return service({
            url: `${version}/futures/config`,
            method: 'post',
            data
        })
    },
    //永续提交
    futuresProductSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/futures/product/submit`,
            method: 'post',
            data
        })
    },
    //交割订单列表
    futuresProductOrderFetch: async (ctx, data) => {
        return service({
            url: `${version}/futures/product/order`,
            method: 'post',
            data
        })
    },
    //已读点击
    messageCenterReadFetch: async (ctx, data) => {
        return service({
            url: `${version}/message/center/update/read`,
            method: 'post',
            data
        })
    },
    //账号绑定状态
    messageInfoBindFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/info/bind`,
            method: 'get',
        })
    },
    //绑定
    userInfoBindUpdateFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/info/bind/update`,
            method: 'post',
            data
        })
    },
    //资金密码状态
    userWithdrawalPasswordStatusFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/withdrawal/password/status`,
            method: 'post',
            data
        })
    },
    //修改资金密码
    userUpdateWithdrawalPasswordFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/update/withdrawal/password`,
            method: 'post',
            data
        })
    },
    //设置资金密码
    userSetWithdrawalPasswordFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/set/withdrawal/password`,
            method: 'post',
            data
        })
    },
    //验证其列表
    userVerifyTypeFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/verify/type`,
            method: 'post',
            data
        })
    },
    //获取当前验证其
    userVerifyNowFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/verify/now`,
            method: 'post',
            data
        })
    },
    //检验验证其
    userCheckCodeFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/check/code`,
            method: 'post',
            data
        })
    },
    //开启验证其
    userVerifyOpenFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/verify/open`,
            method: 'post',
            data
        })
    },
    // 关闭验证其
    userVerifyCancelFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/verify/cancel`,
            method: 'post',
            data
        })
    },
    //获取验证其私钥
    userVerifyFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/verify`,
            method: 'post',
            data
        })
    },
    //获取未读
    messageCenterListFetch: async (ctx, data) => {
        return service({
            url: `${version}/message/center/type/list`,
            method: 'post',
            data
        })
    },
    //ai量化信息
    miningAiInfoFetch: async (ctx, data) => {
        return service({
            url: `${version}/mining/ai/info`,
            method: 'get',
        })
    },
    //ai可用
    miningQuantityAvailableFetch: async (ctx, data) => {
        return service({
            url: `${version}/mining/quantityAvailable`,
            method: 'post',
        })
    },
    //订单提交
    miningOrderSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/mining/order/submit`,
            method: 'post',
            data
        })
    },
    //c2c币种列表
    c2cCoinStandardListFetch: async (ctx, data) => {
        return service({
            url: `${version}/c2c/coin/standard/list`,
            method: 'get',
            data
        })
    },
    //c2c产品列表
    c2cProductListFetch: async (ctx, data) => {
        return service({
            url: `${version}/c2c/product/list`,
            method: 'post',
            data
        })
    },
    //c2c订单列表
    c2cOrderListFetch: async (ctx, data) => {
        return service({
            url: `${version}/c2c/order/list`,
            method: 'post',
            data
        })
    },
    //C2C 订单提交
    c2cOrderSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/c2c/order/submit`,
            method: 'post',
            data
        })
    },
    //C2C 法币列表
    c2cFiatCurrencyListFetch: async (ctx, data) => {
        return service({
            url: `${version}/c2c/fiat/currency/list`,
            method: 'get',
            data
        })
    },
    //C2C 购买订单取消
    c2cOrderCancelFetch: async (ctx, data) => {
        return service({
            url: `${version}/c2c/order/cancel`,
            method: 'post',
            data
        })
    },
    //C2C 购买订单确认付款
    c2cOrderBuySubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/c2c/order/buy/submit`,
            method: 'post',
            data
        })
    },
    //C2C 客服发送消息
    c2cChatSendFetch: async (ctx, data) => {
        return service({
            url: `${version}/c2c/chat/send`,
            method: 'post',
            data
        })
    },
    //C2C 客服记录列表
    c2cChatRecordFetch: async (ctx, data) => {
        return service({
            url: `${version}/c2c/chat/record`,
            method: 'post',
            data
        })
    },
    //C2C 收款地址列表
    c2cUserPaymentAddressListFetch: async (ctx, data) => {
        return service({
            url: `${version}/c2c/user/payment/address/list`,
            method: 'post',
            data
        })
    },
    //C2C 收款地址添加
    c2cAddUserPaymentAddressFetch: async (ctx, data) => {
        return service({
            url: `${version}/c2c/add/user/payment/address`,
            method: 'post',
            data
        })
    },
    //C2C 收款地址修改
    c2cUpdateUserPaymentAddressFetch: async (ctx, data) => {
        return service({
            url: `${version}/c2c/update/user/payment/address`,
            method: 'post',
            data
        })
    },
    //c2c rate
    c2cRateFetch: async (ctx, data) => {
        return service({
            url: `${version}/c2c/rate`,
            method: 'post',
            data
        })
    },
    //个人弹窗
    userPopupMsgFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/popup/msg`,
            method: 'get'
        })
    },
    //信用分
    userCreditListFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/credit/list`,
            method: 'post',
            data
        })
    },
    //钱包登录
    userWalletLoginFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/wallet/login`,
            method: 'post',
            data
        })
    },
}