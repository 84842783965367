import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "簡體中文",
        dialogDetermine: "確定",
        dreivatives: "交割",
        markets: "行情",
        assets: "資產",
        liquidity: "流動性挖礦",
        helpCentre: "幫助中心",
        announcementCenter: "公告中心",
        about: "關於",
        bulletinCentre: "公告中心",
        serviceAgreement: "服務協定",
        support: "支援",
        onlineSupportCustomerService: "網路客服",
        suggestionsAndFeedback: "建議和回饋",
        service: "服務",
        download: "下載",
        coinApplication: "上幣申請",
        privacyStatement: "隱私權聲明",
        trade: "合約交易",
        standardConteact: "永續",
        signOut: "登出登入",
        assetWallets: "資產錢包",
        financeRecords: "帳變紀錄",
        identification: "身份認證",
        personalCenter: "個人中心",
        chooseLanguage: "選擇語言",
        tips: "提示",
        dark: "黑夜模式",
        light: "白天模式",
        languageTitle: "語言",
        convertCurrency: "折算貨幣",
        cancel: "取消",
        aboutUs: "關於我們",
        standard: "本位永續",
        share: "分享",
        introduce: "平台介紹",
        set: "設定",
        changePassword: "更改登入密碼",
        denomination: "計價貨幣",
        color: "色彩配置",
        code: "版本號",
        color1: "紅漲綠跌",
        color2: "綠漲紅跌",
        setLangue: "設定語言",
        vip: "VIP權益",
        vip2: "當前VIP等級",
        safeMenu: "安全中心",
        conventional: "常規",
        other: "其他",
        quickTips: "最多新增3個快捷功能按鈕",
        quickTransaction: "快速交易",
        safeConvenient: "安全便捷",
        timeSharing: "分時",
        currentLevel: "目前等級",
        levelBenefits: "%{level}級收益",
        teamLevel: "團隊等級",
        levelLower: "%{level}級下級",
        rules: "規則說明",
        bindWithdrawalAddress: "綁定提現位址",
        tipsBindWithdrawalAddress: "您還未綁定提現位址，請先去綁定",
        walletAddressFormatIncorrect: "錢包地址格式不正確",
        tradeRules1: "預測未來一段時間的漲跌。 以下單時間的價格為起始價，以預測週期最後一秒的價格為結算價，結算價大於起始價則為漲，結算價小於起始價則為跌。",
        tradeRules2: "1.什麼是合約",
        tradeRules3: "本平台提供USDT本位的永續合約，用戶可判斷漲跌，選擇買進做多或賣出做空合約來取得數位資產價格上漲/下降的收益。 永續合約沒有交割日，用戶可以一直持有。",
        tradeRules4: "2.什麼是保證金",
        tradeRules5: "在虛擬合約市場上，用戶只需根據合約價格，以一定比例交納少量資金作為履行合約的財力擔保，便可參與合約的買賣，這種資金就是虛擬合約保證金。 \n本平台的合約採用分帳戶的逐倉保證金模式。 每個幣種對應一個合約帳戶，不同的合約幣種的帳戶資產和部位相互獨立，且不同合約帳戶之間的劃轉及交易互不影響。 用戶的合約帳戶在開倉後，該合約帳戶內的所有倉位持股的風險和收益將合併計算。",
        tradeRules6: "3.如何計算保證金",
        tradeRules7: "持倉保證金 = 合約面額 * 持倉合約數量 / 槓桿倍數\n凍結保證金 = 當前委託內所有已成功掛單開倉委託的保證金總和\n可用保證金 = 帳戶餘額 - 持倉保證金 - 凍結保證金",
        tradeRules8: "4.如何計算保證金率",
        tradeRules9: "保證金率是衡量使用者資產風險的指標。 \n保證金費率 =（未實現盈虧 + 持倉保證金 + 可用保證金 + 凍結保證金 - 平倉手續費） / 持倉保證金\n保證金率越小，帳戶的風險越高。 當保證金率小於等於0%時，會觸發強制平倉。",
        tradeRules10: "5.什麼是預估強平價",
        tradeRules11: "預估強平價，即係統計算得到的理論上觸發強制平倉的價格，僅做參考用，與實際值可能有些許偏差。",
        tradeRules12: "6.什麼是帳戶總權益、未實現盈虧",
        tradeRules13: "帳總權益 = 帳戶餘額 + 未實現盈虧\n未實現盈虧，是用戶在該幣種的合約目前持有的部位的盈虧，未實現盈虧會隨著最新成交價格變動而變動。 \n多倉未實現盈虧=（1/持倉均價- 1/最新成交價）* 多倉合約張數* 合約面額* 最新成交價\n空倉未實現盈虧=（1/最新成交價- 1/ 持倉均價）* 空倉合約張數* 合約面額* 最新成交價",
        text1: "帳戶安全",
        text2: "晝夜模式",
        text3: "清理快取",
        text4: "確定要登出登入嗎？",
        text5: "確定要清理快取嗎？",
        text6: "多語言",
        text7: "為保護您的安全，建議至少開啟一種雙重身分驗證"
    },
    login: {
        login: "登入",
        register: "註冊",
        title1: "隨時隨地交易，查看即時行情",
        title2: "隨時隨地交易，查看即時行情",
        title3: "最值得信賴的加密貨幣交易平台",
        formTitle1: "帳號登入",
        formTitle2: "歡迎回來！ 使用您的電子郵件、電話號碼登錄",
        btn1: "智慧型手機",
        btn2: "信箱",
        btn3: "登入",
        btn4: "註冊",
        btn5: "忘記密碼？",
        field1: "手機號",
        field2: "密碼",
        field3: "信箱",
        placeholder1: "國家",
        placeholder2: "請輸入手機號",
        placeholder3: "請輸入密碼",
        placeholder4: "請輸入信箱",
        content12: "請輸入手機號",
        content13: "請輸入密碼",
        content14: "請輸入信箱",
        content16: "數位資產金融服務",
        loginSuccess: "登入成功 ",
        chooseArea: "請選擇區號",
        placeholder5: "請選擇國家區號",
        placeholder6: "記住我的密碼",
        placeholder7: "下載APP",
        placeholder8: "聯絡客戶服務",
        placeholder9: "選擇國家或地區",
        placeholder10: "區號",
        placeholder11: "錢包登入",
        placeholder12: "操作失敗，請切換到",
        placeholder13: "錢包"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "關於",
        aboutCoinbeadCenterDetails: "擁有全球領先的區塊鏈資產交易平台，同時經營整個生態體系。",
        footDetails1: "每日交易量",
        footDetails2: "交易/每秒",
        footDetails3: "幫助中心",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "是全球著名的數位資產國際站，主要為全球用戶提供比特幣（BTC）、萊特幣（LTC）、以太幣（ETH）等數位資產的幣和衍生性商品",
        coinbeadCenterDetailsm: "採用GSLB、網路伺服器、網路交易、網路、多機互備的高速記憶體交易引擎、冷錢包、智慧離線化的熱錢包等先進的技術，以Web端手機端、PC端等多終端為我們的客戶 提供安全、穩定、可靠的數位資費交易服務。 同時我們根據用戶的建議和需求，改進和升級產品和服務，為每位客戶提供更好的服務，和創新用戶體驗的提升永無止境。",
        infrastructureCenterTitle: "基礎建設",
        infrastructureCenterDetails: "我們的使命是為整個區塊鏈生態系統提供基礎設施服務",
        freeCenterTitle: "自由",
        freeCenterDetails: "我們的願景是讓金錢在全球範圍內自由流動。 我們相信，傳播這種自由，能讓世界變得更好",
        futureCenterTitle: "開拓科技的未來",
        futureCenterDetails: "Open up the future of technology",
    },
    applicationForListing: {
        title: "上幣申請",
        projectName: "項目名稱",
        tokenName: "代幣名稱",
        projectDescription: "專案簡介",
        contractAddress: "合約地址",
        officialWebsite: "官方網址",
        ContactAndEmail: "聯絡人郵件",
        ContactAndName: "聯絡人姓名",
        file: "上傳附件",
        pleaseEnter: "請輸入",
        enterFile: "請上傳文件",
        upload1: "目前限制選擇 1 個文件，",
        upload2: "本次選擇了",
        upload3: "個文件，",
        upload4: "共選擇了",
        upload5: "個文件",
    },
    public: {
        submit: "提交",
        copySuccessfully: "複製成功",
        copyFailed: "複製失敗",
        noDate: "暫無數據",
        submitSuccessfully: "提交成功",
        goBack: "回上一頁",
        cancel: "取消",
        home: "首頁",
        loading: "載入中...",
        confirm: "確認",
        loadingFailed: "網路例外",
        nomore: "沒有更多了",
        tryAgain: "請刷新頁面/退出程序 後重新上傳!",
        hot: "熱門區",
    },
    inviteFriends: {
        inviteFriends: "分享好友",
        freedomOfWealth: "讓財富一起自由",
        myInvitationCode: "我的邀請碼",
        clickCopy: "點選複製",
        myInvitationLink: "我的邀請連結",
        clicktoSaveTheQRCode: "點擊儲存二維碼",
        saveQRCode: "邀請二維碼",
        numberOfSubordinates: "下級人數",
        rebateIncome: "返傭收益",
        people: "人",
        myPromotionRevenue: "我的推廣收益",
        numberOfDirectSubordinates: "直屬下級人數",
        totalNumberOfSubordinates: "總下級人數",
        directSubordinateReturnsCommission: "直屬下級返傭",
        theTotalCommission: "總返傭",
        checkHistory: "查看返傭記錄",
        history: "返傭記錄",
        theLowerAccount: "下級帳戶",
        type: "類型：",
        serviceCharge: "手續費：",
        percentageofCommissionReturned: "返傭比例：",
        commissionrebateAmount: "返佣金額：",
        time: "時間：",
        typeName1: "充值返傭",
        typeName2: "MT下單手續費三級經銷",
        typeName3: "MT獲利三級經銷",
        typeName4: "推薦獎勵",
        typeName30: "充值返傭",
        typeName31: "週期交易獲利返傭",
        typeName32: "流動性挖礦獲利返傭",
        typeName45: "流動性挖礦獲利返傭",
        typeName46: "自身手續費返傭",
        typeName50: "交割合約手續費返傭",
        typeName51: "永續合約手續費返傭",
        typeName52: "交割合約手續費額外返傭",
        typeName53: "永續合約手續費額外返傭",
        typeName54: "永續合約手續費額外回饋",
        shareRules1: "邀請說明",
        shareRules2: "進入邀請中心，複製邀請連結或邀請碼，分享給您的好友。 好友透過您的邀請碼註冊就可以成為您的下級。",
        shareRules3: "賺取返傭",
        shareRules4: "下級進行交易的時候，您可以獲得相應的返傭，最多支援三級下級。 舉個例子，您邀請了好友A，A邀請了B，B邀請了C，則A、B、C在平台內進行合約等交易，您都可以獲得相應的返傭。",
        shareRules5: "團隊等級",
        shareRules6: "推廣的一級下級越多，團隊等級越高，可享有的返傭越高。 團隊等級分為LV1-LV6，升級規則請見下列表格，其中'N'為已儲值並完成實名認證一級下級人數。",
        shareRules7: "下級進行交割合約投注，您可獲得其投注額相應比例的返傭。",
        upgradeConditions: "要求",
        recommendedNumberOfPeople: "推薦人數"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "建議及回饋",
        text: "如果使用過程中遇到bug或有產品建議歡迎回饋",
        email: "電子郵件地址",
        enterEmail: "請輸入電子郵件地址",
        enterCorrectEmail: "請輸入正確的信箱地址",
        title: "標題",
        enterTitle: "請輸入問題標題",
        describeTheProblem: "描述問題",
        enterDescribeTheProblem: "請輸入你所描述的問題",
        submit: "提交",
    },
    help: {
        enterSearch: "搜尋",
        title: "幫助中心",
        search: "請輸入您的問題",
        text1: "了解您的需求，持續為你提供更便利的服務"
    },
    announcement: {
        enterSearch: "搜尋",
        title: "公告中心",
        search: "請輸入您的問題"
    },
    privacy: {
        title: "隱私權聲明",
        content1: "1. 收集用戶的哪些個人資訊？",
        content2: "收集個人資訊是為了提供產品和服務並持續改進。",
        content3: "以下是我們收集的個人資訊類型：",
        content4: "您提供給我們的資訊：我們接收並儲存您提供的與本平台相關的任何資訊。 您可以選擇不提供某些信息，但可能因此無法使用某些「本平台服務」。 您提供給我們的資訊範例包括：",
        content5: "姓名",
        content6: "地址",
        content7: "國籍",
        content8: "證件照",
        content9: "手機號碼/信箱號碼",
        content10: "有助於我們識別您身份的其他信息",
        content11: "自動收集的資訊：我們會自動收集並儲存有關您使用「本平台服務」的某些類型的信息，與許多網站一樣，我們會使用Cookie以及其他獨特的識別碼。 當您的網頁瀏覽器或裝置存取本平台時，我們便會獲得某些類型的資訊。 我們收集和分析的資訊範例包括：",
        content12: "將個人電腦連接到網際網路的因特網協定(IP)位址；",
        content13: "個人設備或電腦的登入資訊、電子郵件地址、密碼和位置；",
        content14: "版本和時區設定；",
        content15: "交易歷史記錄。",
        content16: "其他來源的資訊：我們可能會從其他來源（如徵信社的信用歷史記錄）收集有關您的資訊。 我們將透過這些資訊防範和偵測詐欺行為。",
        content17: "2. 兒童可以使用嗎？",
        content18: "本平台不允許未滿18歲的任何個人使用「服務」。",
        content19: "3. Cookie及其他識別碼是用來幹什麼的？",
        content20: "我們使用Cookie及類似工具優化使用者體驗、提供我們的服務並了解客戶如何使用我們的服務，以便做出針對性改善。 我們使用Cookie是為了確保我們的系統能夠識別您的瀏覽器或設備並向您提供服務。",
        content21: "我們使用操作類Cookie及類似工具（統稱「Cookies」）來提供服務，例如：",
        content22: "在登入並使用我們的服務時識別您的身份。",
        content23: "提供客製化功能和服務。",
        content24: "防範詐欺活動。",
        content25: "提高安全性。",
        content26: "追蹤您的偏好（如貨幣和語言）。",
        content27: "我們也使用Cookie來了解使用者如何使用我們的服務，以便做出針對性改善。",
        content28: "4. 是否會分享我的個人資訊？",
        content29: "用戶資訊是我們業務的重要組成部分，我們不會向他人出售用戶的個人資訊。 本平台僅在下述情況中與其子公司或關聯方共享使用者的個人信息，這些子公司或關聯方須遵守本隱私權聲明或至少遵循與本隱私權聲明中所述保護措施具有同等保護力度的實踐。",
        content30: "第三方服務供應商：我們僱用其他公司和個人代表我們履行職能。 此類職能範例包括：分析數據、提供行銷協助、處理付款、傳輸內容以及評估和管理信貸風險。 這些第三方服務供應商可以存取履行職能所需的個人信息，但不得將其用於其他目的。 此外，他們必須遵循本隱私權聲明和相應的資料保護法來處理個人資訊。",
        content31: "業務轉移：隨著我們的業務不斷發展，我們可能會出售或購買其他業務或服務。 在此類交易中，使用者資訊通常屬於被轉移的業務資產，但仍受任何現有隱私權聲明中的承諾約束（當然，使用者另行同意的除外）。 另外，如果本司或其大部分資產被收購，用戶資訊也將被轉移。 對本司及其他人的保護：當我們認為發布帳戶及其他個人資訊是遵守法律或我們的監管義務、執行或應用我們的使用條款和其他協議、使用者或其他人的權利、財產或安全的適當舉措 時，我們會發布所述帳戶和其他個人資訊。 這包括與其他公司和組織交換訊息，以防範欺詐行為並降低信用風險。",
        content32: "5. 個人資料的國際轉移",
        content33: "本司可能將您的資料轉移到歐洲經濟區（「EEA」）以外的地區。 本司會採取適當的保障措施，確保此轉移符合適用的資料保護規則，除非歐洲委員會已確認接收轉移資料的國家/地區可提供足夠的保護等級。",
        content34: "6. 我的個人資訊安全嗎？",
        content35: "我們在設計系統時充分考慮到您的安全和隱私。 我們使用加密協定和軟體，在傳輸過程中努力保護個人資訊安全。",
        content36: "在收集、儲存和揭露個人資訊方面，我們始終採取實體保障、電子保障和程序保障措施。 我們的安全程序意味著在向您揭露個人資訊之前，我們可能需要核實您的身分。",
        content37: "對您來說，最重要的事情莫過於防止個人帳戶密碼遭受未經授權的存取。 我們建議為您的帳戶設定有別於其他線上帳戶的獨特密碼。 並且在使用共享電腦之後，請務必登出登入。",
        content38: "7. 我應如何保護個人資訊？",
        content39: "如果您對我們收集和處理個人資訊的方法有任何疑問或異議，請聯絡 客服人員",
        content40: "您在同意我們出於特定目的處理您的個人資訊後，可以隨時將意向撤回，我們將停止出於該目的處理您的資料。",
        content41: "此外，在遵守相應法律的前提下，您有權要求存取、修正和刪除個人資料並要求遷移資料。 您也可以反對我們處理您的個人數據，或要求在某些情況下限制我們處理您的個人資料。",
        content42: "8. 歐盟GDPR和英國資料保護法－法律依據",
        content43: "歐盟GDPR和英國資料保護法要求我們在使用個人資訊時遵循法律依據。 我們的依據取決於使用個人資訊的具體目的。 這些依據包括：",
        content44: "履行合約 - 向您提供產品或服務或與您溝通相關事宜，包括我們使用您的個人資訊來接受和處理訂單以及處理付款。",
        content45: "我們的合法商業利益和使用者利益 - 我們偵測並防範詐欺和濫用行為，為我們的使用者、我們自己或他人的安全提供保護。",
        content46: "您的同意 - 我們需要徵得您的同意，以便出於向您傳達的特定目的而處理您的個人資訊。 當您同意我們出於特定目的處理您的個人資訊後，您可以將意向隨時撤回，我們將停止出於該目的處理您的資料。",
        content47: "遵守法律義務 - 我們按法律要求使用您的個人資訊。 例如，我們收集銀行帳戶資訊是出於身份驗證目的。",
        content48: "上述內容及其他法律依據視我們使用個人資訊的具體目的而定。",
        content49: "9. 使用條件、通知和修訂",
        content50: "您的使用以及任何隱私權爭議均須遵守本聲明及我們的使用條款。 如果對本平台的隱私權有任何疑慮，請聯絡我們並提供詳盡說明，我們將竭誠為您解決問題。 您也有權聯絡所在地的資料保護機構。",
        content51: "我們的業務不斷變化，隱私權聲明也會隨之改變。 您應該經常查看我們的網站，以了解最新變更。 如果您不同意修訂後的內容，應立即停止存取。 隱私權政策更新版發布後，您繼續造訪即表示您認同更新內容並同意遵守更新後的隱私權政策。 除非另有說明，否則目前的隱私權聲明適用於我們掌握的所有關於您和您帳戶的資訊。",
    },
    serviceAgreement: {
        title: "服務協定",
        content1: "本網站是專門供使用者進行數位資產交易及提供相關服務（以下稱為「該服務」或「服務」）的平台。 為了本協議表述之方便，本網站在本協議中合稱使用「我們」或其他第一人稱稱呼。 只要登陸該網站的自然人或其他主體均為本網站的用戶，本協議表述之便利，以下使用「您」或其他第二人稱。 為了本協議表述之便利，我們和您在本協議中合稱為“雙方”，我們或您單稱為“一方”。",
        content2: "重要提示：",
        content3: "我們在此特別提醒您：",
        content4: "1 數位資產本身不由任何金融機構或公司或本網站發行",
        content5: "2 數位資產市場是全新的、未經確認的，而且可能不會成長；",
        content6: "3 數位資產主要由投機者大量使用，零售和商業市場使用相對較少，數位資產交易存在極高風險，其全天不間斷交易，沒有漲跌限制，價格容易受莊家、全球政府政策的影響而大幅 波動；",
        content7: "4  如果本公司根據其單方判斷認為您違反了本協議，或者根據您所在管轄區域的法律本網站提供的服務或您使用本網站提供的服務的行為是非法的，本公司有權隨時暫停或終止您的帳戶 ，或暫停或終止您使用本網站提供的服務或數位資產交易。 禁止【中國大陸、中國台灣、以色列、伊拉克、孟加拉、玻利維亞、厄瓜多、吉爾吉斯、塞瓦斯托波爾、英國（零售用戶） 】的任何人使用本網站提供的合約交易服務 。 前述國家或地區名單會隨著不同國家或地區的政策和產品類型的不同而變化，屆時我們可能不會特別通知您，請您及時關注本協議的更新。",
        content8: "數位資產交易有極高風險，不適合絕大部分人士。 您了解並瞭解此交易有可能導致部分損失或全部損失，所以您應該以能承受的損失程度來決定交易的金額。 您了解和理解數位資產會產生衍生風險，所以如有任何疑問，建議先尋求專業顧問的協助。 此外，除了上述提及的風險以外，還會有未能預測的風險存在。 您應慎重考慮並以清晰的判斷能力去評估自己的財政狀況及上述各項風險而作出任何買賣數位資產的決定，並承擔由此產生的全部損失，我們對此不承擔任何責任。",
        content9: "敬告您：",
        content10: "1 您了解本網站僅作為您獲取數位資產資訊、尋找交易方、就數位資產的交易進行協商及開展交易的場所，本網站不參與您的任何交易，故您應自行謹慎判斷確定相關數位資產及/ 或資訊的真實性、合法性和有效性，並自行承擔因此產生的責任與損失。",
        content11: "2 本網站的任何意見、訊息、探討、分析、價格、建議和其他資訊都是一般的市場評論，並不構成投資建議。 我們不承擔任何因依賴該資訊直接或間接而產生的損失，包括但不限於任何利潤損失。",
        content12: "3 本網站保留隨時自行決定、修改或變更本內容的權利，我們已採取合理措施確保網站資訊的準確性，但並不能保證其準確性程度，亦不會承擔任何因本網站上的資訊或因未 能連結互聯網、傳送或接收任何通知和資訊時的延誤或失敗而直接或間接產生的損失。",
        content13: "4 使用網路形式的交易系統亦有風險，包括但不限於軟體，硬體和網路連線失敗等。 由於我們無法控制網路的可靠性和可用性，我們不會對失真，延誤和連線失敗而承擔任何責任。",
        content14: "5 禁止使用本網站從事洗錢、走私、商業賄賂等一切非法交易活動或違法行為，若發現任何涉嫌非法交易或違法行為，本站將採取各種可使用之手段，包括但不限於凍結帳戶，通知相關 權力機關等，我們不承擔由此產生的所有責任並保留向相關人士追究責任的權利。",
        content15: "6 禁止使用本網站進行惡意操縱市場、不正當交易等一切不道德交易活動，若發現此類事件，本網站將對所有惡意操縱價格、惡意影響交易系統等不道德的行為採取警告、限制交易、關 停帳戶等預防性的保護措施，我們不承擔由此產生的所有責任並保留向相關人士追究責任的權利。",
        content16: "一、總則",
        content17: "1.1 《使用者協議》（以下稱為「本協議」或「本條款及條件」），由正文、《隱私權條款》、《了解你的客戶和反洗錢政策》以及本網站已經發布的或將來可能發布的各 類別規則、聲明、說明等構成。",
        content18: "1.2 您在使用本網站提供的各項服務之前，應仔細閱讀本協議，如有不理解之處或其他必要，請諮詢專業律師。 如您不同意本協議及/或隨時對其的修改，請您立即停止使用本網站提供的服務或不再登陸本網站。 您一旦登陸本網站、使用本網站的任何服務或任何其他類似行為即表示您已了解並完全同意本協議各項內容，包括本網站對本協議隨時所做的任何修改。",
        content19: "1.3 您透過依照本網站的要求填寫相關信息，並經過其他相關程序後成功註冊可以成為本網站的會員（以下稱為「會員」），在進行註冊過程中點擊「同意」按鈕即表示您以電子簽署的 形式與本公司達成協議；或您在使用本網站過程中點擊任何標有「同意」或類似意思的按鈕的行為或以其他本網站允許的方式實際使用本網站提供的服務時，均表示您完全了解 、同意且接受本協議下的全部條款的約束，無您手寫的書面簽字不影響本協議對您的法律約束力。",
        content20: "1.4 成為本網站的會員後，您將獲得一個會員帳號及相應的密碼，該會員帳號和密碼由您負責保管；您應對以您帳號進行的所有活動和事件負法律責任。",
        content21: "1.5 只有成為本網站的會員才可使用本網站提供的數位資產交易平台進行交易及享受其他本網站規定的只有會員才可獲得的服務；非會員只有登陸網站、瀏覽網站及其他本網站規定的可獲得 的服務。",
        content22: "1.6 透過註冊和使用任何由本網站提供的服務和功能，您將被視為已閱讀，理解並：",
        content23: "1.6.1 接受本協議所有條款及條件的約束。",
        content24: "1.6.2 您確認您已年滿18歲或根據不同的可適用的法律規定的具有可訂立合約的法定年齡，您在本網站的註冊、銷售或購買、發布資訊等接受本網站服務的行為應符合對您 有管轄權的主權國家或地區相關法律法規，並有充分的能力接受這些條款，並訂立交易，使用本網站進行數位資產交易。",
        content25: "1.6.3 您保證交易中涉及的屬於您的數位資產均為合法取得並享有所有權。",
        content26: "1.6.4 您同意您為您自身的交易或非交易行為承擔全部責任和任何收益或虧損。",
        content27: "1.6.5 您確認註冊時提供的資訊是真實且準確的。",
        content28: "1.6.6 您同意遵守任何有關法律的規定，就稅務目的而言，包括報告任何交易利潤。",
        content29: "1.6.7 您同意在任何時候不得從事或參與損害本網站或公司利益的行為或活動，無論是否與本網站提供的服務有關。",
        content30: "1.6.8 本協議只是就您與我們之間達成的權利義務關係進行約束，而不涉及本網站使用者之間與其他網站及您之間因數位資產交易而產生的法律關係及法律糾紛。",
        content31: "二、協議修訂",
        content32: "我們保留不時修訂本協議、並以網站公示的方式進行公告、不再單獨通知您的權利，修訂後會在本協議顯示的[最近更新時間]標註修訂時間，一經在網站公佈，立即自動生效。 您應不時瀏覽及關注本協議的更新變更時間及更新內容，如您不同意相關變更，應立即停止使用本網站服務；您繼續使用本網站服務，即表示您接受並同意經修訂的協議的約束 。",
        content33: "三、註冊",
        content34: "3.1 註冊資格",
        content35: "您確認並承諾：在您完成註冊程序或以其他本網站允許的方式實際使用本網站提供的服務時，您應是具備可適用的法律規定的可簽署本協議及使用本網站服務應具有的能力 的自然人、法人或其他組織。 您一旦點擊同意註冊按鈕，即表示您本身或您的有權代理人已經同意該協議內容並由其代理註冊及使用本網站服務。 若您不具備前述主體資格，則您及您的有權代理人應承擔因此而導致的一切後果，且本公司保留註銷或永久凍結您帳戶，並向您及您的有權代理人追究責任的權利 。",
        content36: "3.2 註冊目的",
        content37: "您確認並承諾：您進行本網站註冊並非出於違反法律法規或破壞本網站數位資產交易秩序的目的",
        content38: "3.3註冊流程",
        content39: "3.3.1 您同意根據本網站使用者註冊頁面的要求提供有效電子郵件、手機號碼等信息，您可以使用您提供或確認的郵箱、手機號碼或本網站允許的其它方式作為登陸手段進入本網站。 如有必要，依照不同法域相關法律法規的規定，您必須提供您的真實姓名、身分證件等法律法規及隱私權條款及反洗錢條款規定的相關資訊並不斷更新註冊資料，符合及時、詳盡、準確的 要求。 所有原始鍵入的資料將引用為註冊資料。 您應對該等資訊的真實性、完整性和準確性負責，並承擔因此產生的任何直接或間接損失及不利後果。",
        content40: "3.3.2 如您所在主權國家或地區的法律法規、規則、命令等規範對手機號碼有實名要求，您同意提供註冊的手機號碼是經過實名登記的，如您不按照規定提供，因此給您帶來的任何 直接或間接損失及不利後果應由您負擔。",
        content41: "3.3.3 您合法、完整並有效提供註冊所需資訊並經驗證，有權獲得本網站帳號及密碼，您取得本網站帳號及密碼時視為註冊成功，可在本網站進行會員登陸。",
        content42: "3.3.4 您同意接收本網站所發送的與本網站管理、營運相關的電子郵件和/或簡訊。",
        content43: "四、服務",
        content44: "本網站只為您透過本網站進行數位資產交易活動（包括但不限於數位資產交易等服務）提供網路交易平台服務。",
        content45: "4.1 服務內容",
        content46: "4.1.1 您有權在本網站瀏覽數位資產各項產品的即時行情及交易資訊、有權透過本網站提交數位資產交易指示並完成數位資產交易。",
        content47: "4.1.2 您有權在本網站查看您會員帳號下的信息，有權應用本網站提供的功能進行操作。",
        content48: "4.1.3 您有權依照本網站發佈的活動規則參與本網站組織的網站活動。",
        content49: "4.1.4 本網站承諾為您提供的其他服務。",
        content50: "4.2 服務規則 您承諾遵守下列本網站服務規則：",
        content51: "4.2.1 您應遵守法律法規、規章、及政策要求的規定，保證帳戶中所有數位資產來源的合法性，不得在本網站或利用本網站服務從事非法或其他損害本網站或第三方權益的活動，包括但 不限於發送或接收任何違法、違規、侵犯他人權益的訊息，發送或接收傳銷資料或存在其他危害的資訊或言論，未經本網站授權使用或偽造本網站電子郵件題頭資訊等。",
        content52: "4.2.2 您應遵守法律法規並妥善使用和保管您的帳號及登陸密碼、資金密碼、和您註冊時綁定的手機號碼、以及手機接收的手機驗證碼。 您對使用您的帳號和登陸密碼、資金密碼、手機驗證碼進行的任何操作和後果承擔全部責任。 當您發現本網站帳號、登陸密碼、或資金密碼、驗證碼被未經您授權的第三方使用，或有其他帳號安全問題時，應立即有效通知本網站，要求本網站暫停本網站帳號的服務 。 本網站有權在合理時間內對您的該等請求採取行動，但本網站對在採取行動前已產生的後果（包括但不限於您的任何損失）不承擔任何責任。 您在未經本網站同意的情況下不得將本網站帳號以贈與、借用、租用、轉讓或其他方式處分給他人。",
        content53: "4.2.3 您同意就您的本網站的帳號、密碼下發生的所有活動（包括但不限於資訊揭露、發佈資訊、網路點選同意或提交各類規則協議、網路續約協議或購買服務等）承擔責任。",
        content54: "4.2.4 您在本網站進行數位資產交易時不得惡意幹擾數位資產交易的正常進行、破壞交易秩序；不得以任何技術手段或其他方式乾擾本網站的正常運作或乾擾其他使用者對本網站服務的使用；不得以虛構 事實等方式惡意詆毀本網站的商譽。",
        content55: "4.2.5 如您因網路交易與其他使用者產生糾紛的，不得透過司法或行政以外的途徑要求本網站提供相關資料。",
        content56: "4.2.6 您在使用本網站提供的服務過程中，所產生的應納稅賦，以及一切硬體、軟體、服務及其它方面的費用，均由您獨自判斷和承擔。",
        content57: "4.2.7 您應遵守本網站不時發布和更新的本協議以及其他服務條款和操作規則，有權隨時終止使用本網站提供的服務。",
        content58: "4.3 產品規則",
        content59: "4.3.1 瀏覽交易訊息",
        content60: "您在本網站瀏覽交易資訊時，應仔細閱讀交易資訊中所包含的全部內容，包括但不限於價格、委託量、手續費、買入或賣出方向， 您完全接受交易資訊中所包含的全部內容後方 可點擊按鈕進行交易。",
        content61: "4.3.2 看交易明細",
        content62: "您可以透過您的帳戶查看對應的成交記錄。",
        content63: "五、本網站的權利和義務",
        content64: "5.1 如您不具備本協議約定的註冊資格，則本網站有權拒絕您進行註冊，對已註冊的，本網站有權註銷您的會員帳號，本網站保留向您或您的有權代理人追究責任 的權利。 同時，本網站保留其他任何情況下決定是否接受您註冊的權利。",
        content65: "5.2 根據本網站本身的判斷，本網站發現您或您的關聯帳戶使用者不適合高風險投資時，有權中止或終止您的帳戶以及所有關聯帳戶的使用。",
        content66: "5.3 本網站發現帳戶使用者並非帳戶初始註冊人時，有權中止或終止該帳戶的使用。",
        content67: "5.4 本網站透過技術檢測、人工抽檢等檢測方式合理懷疑您提供的資訊錯誤、不實、失效或不完整時，有權通知您更正、更新資訊或中止、終止為其提供本網站服務。",
        content68: "5.5 本網站有權在發現本網站上顯示的任何資訊有明顯錯誤時，對資訊予以更正。",
        content69: "5.6 本網站保留隨時修改、中止或終止本網站服務的權利，本網站行使修改或中止服務的權利不需事先告知您；本網站終止本網站一項或多項服務的，終止自本網站在網站上 發布終止公告當天生效。",
        content70: "5.7 本網站將採取必要的技術手段和管理措施來保障本網站的正常運行，並提供必要、可靠的交易環境和交易服務，維護數位資產交易秩序。",
        content71: "5.8 如您連續一年未使用本網站會員帳號及密碼登陸本網站，則本網站有權註銷您的本網站帳號。 帳號註銷後，本網站有權將相應的會員名稱開放給其他使用者註冊使用。",
        content72: "5.9 本網站透過加強技術投入、提升安全防範等措施保障您的數位資產的安全，並將在您帳戶出現可預見的安全風險時提前通知您。",
        content73: "5.10 本網站有權隨時刪除本網站內各類不符合法律法規或本網站規定等的內容訊息，本網站行使該等權利不需事先通知您。",
        content74: "5.11 本網站有權根據您所屬主權國家或地區的法律法規、規則、命令等規範的要求，向您要求提供更多的資訊或資料等，並採取合理的措施，以符合當地的規範之要求，您 有義務配合；本網站有權根據您所屬主權國家或地區的法律法規、規則、命令等規範的要求，暫停或永久停止對您開放本網站部分或全部服務。",
        content75: "5.12 本網站保留自行決定提前七個工作天發出通知後關閉您的帳戶的權利。 因此，您有七個工作天撤單和平倉等作業。 如果您在該期限到期時仍未撤單和平倉，我們會強制撤單和平倉，並將您帳戶中剩餘的數位資產退還給您。",
        content76: "5.13 為保護交易者權益，本網站有權在特殊情況下（例如係統故障、網路故障、極端行情等），對已上線產品進行調整，例如：合約進行提前交割結算，提前交割結算的合約品種、合約 週期及結算交割價格，以本網站公告為準。",
        content77: "六、賠償",
        content78: "6.1 在任何情況下，我們對您的直接損害的賠償責任均不會超過我們因您使用本網站為期三（ 3）個月服務而向您收取的總服務費。",
        content79: "6.2 如您發生違反本協議或其他法律法規等情況，您須向我們至少賠償200萬美元及承擔由此產生的全部費用（包括律師費等），如不夠彌補實際損失，您須補全。",
        content80: "七、尋求禁制令救濟的權利",
        content81: "我們和您都承認，普通法對您違約或可能違約情況的救濟措施可能不足以彌補我們遭受的全部損失，故我們有權在您違約或可能違約情況下尋求禁令救濟以及普通法或衡平法允許 的其他所有的補救措施。",
        content82: "八、責任限制與免責",
        content83: "8.1 您了解並同意，在任何情況下，我們不就以下各事項負責：",
        content84: "8.1.1 收入的損失；",
        content85: "8.1.2 交易利潤或合約損失；",
        content86: "8.1.3 業務中斷造成的損失；",
        content87: "8.1.4 預期可節省的貨幣的損失；",
        content88: "8.1.5 資訊問題造成的損失；",
        content89: "8.1.6 機會、商譽或聲譽的損失；",
        content90: "8.1.7 資料的損壞或損失；",
        content91: "8.1.8 購買替代產品或服務的成本；",
        content92: "8.1.9 任何由於侵權（包括過失）、違約或其他任何原因產生的間接的、特殊的或附帶性的損失或損害，不論這種損失或損害是否可以為我們合理預見；不論我們是否事先被告知存在此類 損失或損害的可能性。",
        content93: "8.1.1 條至8.1.9條均是彼此獨立的。",
        content94: "8.2 您了解並同意，我們不對因下述任一情況而導致您的任何損害賠償負責：",
        content95: "8.2.1 您的特定交易事項可能有重大違法或違約情形。",
        content96: "8.2.2 您在本網站的行為涉嫌違法或不道德。",
        content97: "8.2.3 透過本網站服務購買或取得任何資料、資訊或進行交易等行為或替代行為所產生的費用及損失。",
        content98: "8.2.4 您對本網站服務的誤解。",
        content99: "8.2.5 任何非因我們的原因而引起的與本網站提供的服務有關的其它損失。 v",
        content100: "8.3 我們對因資訊網路設備維護、資訊網路連線故障、電腦、通訊或其他系統的故障、電力故障、天氣原因、意外事故、罷工、勞動爭議、暴動、起義、暴動、生產力或生產資料不足、火災、 洪水、風暴、爆炸、戰爭、銀行或其他合作方原因、數位資產市場崩潰、政府行為、 司法或行政機關的命令、其他不在我們可控範圍內或我們無能力控制的行為或第三方的原因而 造成的不能服務或延遲服務，以及造成您的損失，我們不承擔任何責任。",
        content101: "8.4 我們無法保證本網站所包含的全部資訊、程式、文字等完全安全，不受任何病毒、木馬等惡意程式的干擾和破壞，故您登陸、使用本網站任何服務或下載及使用該下載的任何程式、 資訊、數據等均為您個人的決定並自行承擔風險及可能產生的損失。 ",
        content102: "8.5 我們對本網站中連結的任何第三方網站的任何資訊、產品及業務及其他任何形式的不屬於我們的主體的內容等不做任何保證和承諾，您如果使用第三方網站提供的任何服務、資訊及 產品等均為您個人決定且承擔由此產生的一切責任。",
        content103: "8.6 我們對於您使用本網站服務不做任何明示或暗示的保證，包括但不限於本網站提供服務的適用性、沒有錯誤或疏漏、持續性、準確性、可靠性、適用於某一特定用途。 同時，我們也不對本網站提供的服務所涉及的技術及資訊的有效性、準確性、正確性、可靠性、品質、穩定、完整和及時性作出任何承諾和保證。 是否登陸或使用本網站提供的服務是您個人的決定且自行承擔風險及可能產生的損失。 我們對於數位資產的市場、價值及價格等不做任何明示或暗示的保證，您理解並了解數位資產市場是不穩定的，價格和價值隨時會大幅波動或崩盤，交易數位資產是您個人的自由 選擇及決定且自行承擔風險及可能產生的損失。",
        content104: "8.7 本協議中規定的我們的保證和承諾是由我們就本協議和本網站提供的服務的唯一保證和陳述，並取代任何其他途徑和方式產生的保證和承諾，無論是書面的或口頭的，明示 的或暗示的。 所有這些保證和陳述僅代表我們自身的承諾和保證，並不保證任何第三方遵守本協議中的保證和承諾。",
        content105: "8.8 我們並未放棄本協議中未提及的在法律適用的最大範圍內我們享有的限制、免除或抵銷我們損害賠償責任的任何權利。",
        content106: "8.9 您註冊後即表示認可我們依照本協議中規定的規則進行的任何操作，產生的任何風險均由您承擔。",
        content107: "九、協議的終止",
        content108: "9.1 本網站有權依據本協議約定終止全部本網站服務，本協議於本網站全部服務終止之日終止。",
        content109: "9.2 本協議終止後，您無權要求本網站繼續向其提供任何服務或履行任何其他義務，包括但不限於要求本網站為您保留或向您披露其原本網站帳號中的任何信息， 向您或第 三方轉發任何其未曾閱讀或發送過的訊息等。",
        content110: "9.3 本協議的終止不影響守約方向違約方要求承擔其他責任。",
        content111: "十、智慧財產",
        content112: "10.1 本網站所包含的全部智力成果包括但不限於網站標誌、資料庫、網站設計、文字和圖表、軟體、照片、錄影、音樂、聲音及其前述組合，軟體編譯、相關原始碼和軟體(包括小應用 程序和腳本) 的智慧財產權權利均歸本網站所有。 您不得為商業目的複製、更改、拷貝、發送或使用前述任何資料或內容。",
        content113: "10.2 本網站名稱所包含的所有權利 (包括但不限於商譽及商標、標誌) 均為本公司所有。",
        content114: "10.3 您接受本協議即視為您主動將您在本網站發表的任何形式的資訊的著作權， 包括但不限於：複製權、發行權、出租權、展覽權、表演權、放映權、廣播權、訊息 網路傳播權、攝制權、改編權、翻譯權、彙編權以及應由著作權人享有的其他可轉讓權利無償獨家轉讓給本網站所有，本網站有權利就任何主體侵權單獨提起訴訟並獲得全部賠償。 本協議效力及於您在本網站發佈的任何受著作權法保護的作品內容， 無論該內容形成於本協議簽訂前或本協議簽訂後。",
        content115: "10.4 您在使用本網站服務過程中不得非法使用或處分本網站或他人的智慧財產權權利。 您不得將已發表於本網站的資訊以任何形式發布或授權其它網站（及媒體）使用。",
        content116: "十一、資訊保護",
        content117: "以本網站單獨公示的《隱私權條款》的約定為準。",
        content118: "十二、計算",
        content119: "所有的交易計算結果都經過我們的核實，所有的計算方法都已經在網站上公示，但是我們不能保證網站的使用不會受到干擾或沒有誤差。",
        content120: "十三、出口控制",
        content121: "您瞭解並承認，根據相關法律，您不得將本網站上的任何資料（包括軟體）出口、再出口、進口或轉移，故您保證不會主動實施或協助或參與任何上述違反法規的出口或相關 轉移或其他違反適用的法律和法規的行為；如發現此類情形，應及時向我們積極報告並協助我們處理。",
        content122: "十四、轉讓",
        content123: "本協議中約定的權利及義務同樣約束從該權利義務中獲取到利益的各方的受讓人，繼承人，遺囑執行人和管理員。 您不得在我們不同意的前提下轉讓給任何第三人，但我們可隨時將我們在本協議中的權利和義務轉讓給任何第三人，並給予您通知。",
        content124: "十五、可分割性",
        content125: "如本協議中的任何條款被任何有管轄權的法院認定為不可執行的，無效的或非法的，並不影響本協議的其餘條款的效力。",
        content126: "十六、非代理關係",
        content127: "本協議中的任何規定均不可被認為創造了、暗示了或以其他方式將我們視為您的代理人、受託人或其他代表人，本協議有其他規定的除外。",
        content128: "十七、棄權",
        content129: "我們或您任何一方對追究本協議約定的違約責任或其他責任的棄權並不能認定或解釋為對其他違約責任的棄權；未行使任何權利或救濟不得以任何方式被解釋為對該等權利或救濟 的放棄。",
        content130: "十八、標題",
        content131: "所有標題僅供協議表述方便，並不用於擴大或限制該協議條款的內容或範圍。",
        content132: "十九、適用法律",
        content133: "19.1 在提出爭議或索賠之前，您確認並同意先透過郵箱聯絡本網站以非正式方式解決糾紛，我們將嘗試盡快在內部解決您的爭議；雙方同意透過善意協商以解決爭議(討論內容應保密，並 受適用規則保護而不被用作任何法律程序中的證據)。",
        content134: "19.2 您確認並同意，如果發生任何爭議、爭論、分歧或權利主張，包括本條款的存在、有效性、解釋、履行、違反或終止或由本條款引起的或與之相關的任何爭議（「爭議」） ，上述雙方應依據屆時有效的《國際商會仲裁規則》將爭議提交國際商會國際仲裁院（「ICC國際仲裁院」）。 仲裁地點為伯利茲，仲裁的適用法律應依據國際商會仲裁規則決定。 除非雙方另有約定，否則根據國際商會仲裁規則僅指定一名仲裁員。 仲裁員應擁有對其自身管轄權進行裁決的排他性權力，包括但不限於就仲裁協議的存在、範圍或有效性或任何索賠或反索賠的可仲裁性提出的任何異議。 任何仲裁都將以英語進行。 無論仲裁以何種方式進行，仲裁員都應出具一份合理的書面裁決，足以解釋裁決和裁決所依據的基本結論和結論(如果有的話)。 仲裁裁決應為終局裁決，對各方均具有約束力，並可在任何有管轄權的法院被強制執行。",
        content135: "二十、協議的生效和解釋",
        content136: "20.1 本協議於您點擊本網站註冊頁面的同意註冊並完成註冊程序、取得本網站帳號和密碼時生效，對本網站和您均具有約束力。",
        content137: "20.2 本協議的最終解釋權歸本網站所有。",
        content138: "了解你的客戶和反洗錢政策",
        content139: "一、導言",
        content140: "1.1 我們保證審慎遵守「了解你的客戶」和反洗錢相關的法律法規且不得故意違反該《了解你的客戶和反洗錢政策》。 在我們合理控制的範圍內我們將採取必要的措施和技術為您提供安全的服務，盡可能使您免於遭受犯罪嫌疑人的洗錢行為帶來的損失。",
        content141: "1.2 我們的了解你的客戶和反洗錢政策是一個綜合性的國際政策體系，包括您隸屬的不同法律轄區的了解你的客戶和反洗錢政策。",
        content142: "二、了解你的客戶和反洗錢政策如下：",
        content143: "2.1 頒布了解你的客戶和反洗錢政策並時時更新以滿足相應的法律法規規定的標準；",
        content144: "2.2 頒布和更新運行本網站的一些指導原則和規則，並且我們的員工將按照該原則和規則的指導提供服務；",
        content145: "2.3 設計並完成內部監控和控制交易的程序，如以嚴格的手段驗證身份，安排組建專業團隊專門負責反洗錢工作；",
        content146: "2.4 採用風險預防的方法對客戶進行盡職調查和持續的監督；",
        content147: "2.5 審查並定期檢查已發生的交易；",
        content148: "2.6 向主管機關通報可疑交易；",
        content149: "2.7 身分證明文件、地址證明文件和交易記錄的證明文件將維持至少六年，例如提交給監管部門，恕不另行通知您。",
        content150: "三、身分資訊與核實確認",
        content151: "3.1 身分資訊",
        content152: "3.1.1 根據不同的司法管轄區的不同規定及不同的實體類型，我們收集的您的資訊內容可能不一致，原則上將向註冊的個人收集以下資訊：",
        content153: "個人基本資料：您的姓名，住址（及永久地址，如果不同） ，出生日期及國籍等可獲得的其他情況。 身分驗證應該是根據官方或其他類似權威機構所發放的文件，例如護照，身分證或其他不同的轄區要求的和引發的身分證明文件。 您提供的地址將使用適當的方法進行驗證，例如檢查乘用交通工具的票據或利率票據或檢查選民登記冊等。",
        content154: "有效的照片：在您註冊之前，您須提供您將您的身分證件放在胸前的照片；",
        content155: "聯絡方式：電話/手機號碼和/或有效的電子郵件地址。",
        content156: "3.1.2 如果您是公司或其他合法實體，我們將收集以下資訊以確定您或信託帳戶的最終受益人。",
        content157: "公司註冊、登記證；公司的章程與備忘錄副本；公司的股權機構和所有權說明的詳細證明資料，證明決定本網站帳戶的開立以及執行的授權委託人的董事會決議；按照要求需要提供的公司董事 、大股東及本網站帳戶有權簽字人的身份證明文件；該公司的主要營業地址，如果與公司的郵寄地址不同，提供郵寄地址。 如果公司在本地的地址與它的主要營業地址不一致的，則被視為風險較高的客戶，需要提交額外附加文件。",
        content158: "根據不同的司法管轄區的不同規定及不同的實體類型，我們要求的其他認證和權威部門發布的文件以及我們認為必要的文件。",
        content159: "3.1.3 我們只接受英文版本或漢語版本的身份訊息，如不是，請您將您的身分資訊翻譯成英文的版本並加以公證。",
        content160: "3.2 確認核實",
        content161: "3.2.1 我們要求您提供身分證明文件的全部頁面內容。",
        content162: "3.2.2 我們要求您提供您將您的身分證明文件放在您胸前的照片。",
        content163: "3.2.3 證明文件的副本一般應核對和原始憑證進行核對。 然而，如果某個可信賴的合適的認證人可證明該副本文件是原始文件準確而全面的複製的，該副本可接受。 這樣的認證人包括大使、司法委員、地方治安官等。",
        content164: "3.2.4 對識別最終受益人和帳戶控制權的要求是確定哪些個人最終所有或控制直接客戶，和/或確定正在進行的交易是由他人代為執行。 如果是企業，則大股東的身分（例如那些持有10％或以上的投票權益）應被驗證。 一般，持股25 ％會被認定為正常風險內，其股東身份須驗證；持股10%或擁有更多的投票權或股票時被認定為高風險的情況，股東身份須加以驗證。",
        content165: "四、監控交易",
        content166: "4.1 我們根據安全性和實際交易情況時時設定和調整日常交易和提幣最高限額;",
        content167: "4.2 如果交易頻繁集中發生在某個註冊用戶或存在超乎合理的情況，我們的專業團隊將評估並決定他們是否可疑;",
        content168: "4.3 我們憑藉自身的判斷認定為可疑交易的情況，我們可能會採取暫停該交易、拒絕該交易等限制性措施，甚至如果可能將盡快逆轉該交易，同時向主管部門報告，但不會通知您;",
        content169: "4.4 我們保留拒絕來自於不符合國際反洗錢標準轄區的人或可被視為政治公眾人物的人的註冊申請，我們保留隨時暫停或終止根據我們自身判斷為可疑交易的交易，但我們這樣做並不 違反對您的任何義務和責任。",
    },
    register: {
        formTitle1: "創建",
        formTitle2: "使用您的電子郵件信箱或手機號碼進行註冊",
        formTitle3: "帳戶",
        field1: "驗證碼",
        field2: "獲取",
        field3: "確認密碼",
        field4: "邀請碼",
        field41: "（選填）",
        field5: "我已閱讀並同意",
        field6: "服務條款",
        placeholder1: "請輸入驗證碼",
        content7: "請輸入驗證碼",
        content12: "請輸入手機號",
        content13: "請輸入密碼",
        content14: "請輸入信箱",
        content18: "請再次輸入密碼",
        content19: "請同意服務條款",
        content20: "請輸入正確的驗證碼",
        content23: "還是沒有收到驗證碼？",
        content24: "請輸入下面的驗證碼",
        registerSuccess: "註冊成功",
        content25: "密碼不一致",
        content26: "請輸入正確的信箱地址",
        content27: "密碼重置成功"
    },
    download: {
        content1: "交易，隨時隨地",
        content2: "多終端完美相容，隨時滿足各種場景的交易需求",
        content3: "隨時查看即時行情",
        content4: "輕鬆買賣BTC, ETH, XRP和其他數位資產",
        content5: "獲取您關注的加密貨幣價格變化預警",
        content6: "看比特幣現貸、期貸、選擇權價格",
        content7: "比較各種交易所加密貨幣價格",
        title: "下載",
        install: "安裝說明",
        one: "1、允許下載描述文件",
        two: "2、已下載描述文件",
        three: "3、進入手機【設定】找到【通用】",
        four: "4、描述文件與裝置管理員",
        five: "5、點選該描述文件",
    },
    identity: {
        noAuthentication: {
            title: "初級認證",
            pleaseSelectYourCountry: "請選擇您的國家",
            name: "姓名",
            enterName: "請輸入您的姓名",
            city: "所在的城市",
            enterCity: "请输入您所在的城市",
            IDNumber: "證件號碼",
            enterIDNumber: "請輸入您的證件號碼",
        },
        noAdvancedAuthentication: {
            title: "高級認證",
            infoCountry: "國家/地區",
            infoName: "姓名",
            infoNumber: "證件號碼",
            infoAddress: "地址",
        },
        text: "未認證",
        recertification: "重新認證",
        certification: "認證",
        authenticationInProgress: "已認證",
        inAuthentication: "認證中",
        uploadTheFrontOfTheDocument: "上傳證件正面",
        uploadTheReverseSideOfTheDocument: "上傳證件反面",
        uploadAPhotoOfAHandHeldID: "上傳手持證件照片",
        pleaseUpload: "請 ",
        pleaseCompleteOrdinaryCertificationFirst: "需等待初級認證審核成功",
        refused: "已拒絕",
        reason: "理由",
        succeeded: "已成功",
        uploading: "上傳中",
        newTitle: "選擇您的身分證件的核發國家/地區",
        pleaseOrdinaryCertificationFirst: "請先進行初級認證",
        text1: "修改頭像",
        text2: "暱稱",
        text3: "帳號",
        text4: "暱稱修改",
        text5: "新暱稱",
        text6: "輸入您的新暱稱",
        text7: "舊密碼",
        text8: "輸入您的舊密碼",
        text9: "新密碼",
        text10: "輸入您的新密碼",
        text11: "確認密碼",

        text12: "再次輸入您的新密碼",
        text13: "新信箱",
        text14: "驗證碼",
        text15: "請輸入驗證碼",
        text16: "您的手機號",
        text17: "新手機號碼",
        text18: "請輸入您的手機號碼",
        text19: "手機驗證碼",
        text20: "初級認證",
        text21: "請認證您的身分資訊",
        text22: "所在國家",
        text23: "所在城市",
        text24: "輸入您的所在城市",
        text25: "輸入您的姓名",
        text26: "您的證件編號",
        text27: "輸入您的證件編號",
        text28: "證件正面照",
        text29: "點擊上傳您的證件",
        text30: "重新上傳",
        text31: "上傳",
        text32: "手持證件照",
        text33: "點擊上傳您的手持證件照",
        text34: "您已經綁定谷歌驗證器",
        text35: "金鑰",
        text36: "請輸入谷歌驗證碼",
        text37: "傳送",
        text38: "不可為空",
        text39: "郵箱格式有誤",
        text40: "兩次密碼不一致",
        text41: "複製成功",
        text42: "複製失敗",
        text43: "頭像修改",
        text44: "修改密碼",
        text45: "信箱綁定",
        text46: "修改信箱",
        text47: "手機綁定",
        text48: "修改手機",
        text49: "谷歌驗證",
        text50: "綁定谷歌驗證器",
        text51: "輸入谷歌驗證碼",
        text52: "儲存",
        text53: "確定提交",
        text54: "修改",
        text55: "綁定",
        text56: "關閉谷歌驗證",
        text57: "下一步",
        text58: "完成",
        text59: "谷歌驗證已綁定",
        text60: "谷歌驗證已解綁",
        text61: "初級認證已提交",
        text62: "高級認證已提交",
        text63: "未上傳圖像",
        text64: "重新發送",
        text65: "請輸入暱稱",
        text66: "暱稱保存成功",
        text67: "還未上傳圖像",
        text68: "密碼修改成功",
        text69: "郵箱修改成功",
        text70: "頭像保存成功",
        text71: "上傳圖像",
        text72: "您的信箱",
        text73: "輸入您的新信箱",
        text74: "您的姓名",
        text75: "去認證",
        text76: "請認證您的手持證件照片"
    },
    retrievePassword: {
        formTitle1: "重設密碼",
        formTitle2: "為了您的資產安全，修改密碼後24小時內禁止交易。",
        formTitle3: "找回密碼",
        formTitle4: "確認修改",
        formTitle5: "請輸入舊密碼",
        formTitle6: "請輸入新密碼"
    },
    home: {
        title1: "最受歡迎的加密貨幣交易平台",
        title3: "為什麼是 ",
        title4: " 是一個高效能的開源區塊鏈平台，旨在支援和運行安全、合規和可預測的數位基礎設施。",
        field1: "老牌交易所",
        field2: "數位資產金融服務",
        field3: "數位資產金融服務",
        field4: "設立多重投資人保護基金",
        field5: "快速交易",
        field6: "CME Gruop能夠以超低延遲匹配訂單，以獲得終極交易體驗。",
        title5: "市場趨勢",
        title6: "看更多",
        table1: "幣種",
        table2: "最新價",
        table3: "24H漲跌",
        table4: "24H成交量",
        table5: "24H交易額",
        table6: "行情",
        title7: "關於 ",
        title8: " 致力於為用戶提供最先進的交易工具、創新的技術及無與倫比的優質客戶服務。",
        title9: "數位資產領域日新月異，只有高瞻遠矚，迅速進步，才能跟上科技革新的腳步。",
        title901: " 為我們的用戶和全球流動性供應商提供最先進的數位資產交易服務。",
        title10: "我們堅信每位用戶都值得享有最棒的交易體驗。",
        title11: "可信賴的保障安全",
        title12: "我們複雜的安全措施合SAFU基金保護您的數位資產免受所有風險。 ",
        title13: "最優的交易費率",
        title14: "優惠的交易率，富有競爭力的VIP權益，享有最優品質的服務。",
        title15: "24*7小時客服支持",
        title16: "24/7小時全天候、全職營運模式，第一時間為您解答相關諮詢。",
        title17: "0手續費買賣",
        title18: "使用多種付款方式交易數位貨幣，0手續費，安全快速。",
        title19: "交易，隨時隨地",
        title20: "多終端完美相容，隨時滿足各種場景的交易需求",
        title21: "隨時查看即時行情",
        title22: "輕鬆買賣BTC, ETH, XRP和其他數位資產",
        title23: "獲取您關注的加密貨幣價格變化預警",
        title24: "看比特幣現貸、期貸、選擇權價格",
        title25: "比較各種交易所加密貨幣價格",
        title26: "立即註冊",
        title27: "美元",
        title28: "24小時交易量",
        title29: "綜合流動性提供者",
        title30: "使用者",
        title31: "更多",
        title32: "幣安貸新增抵押資產CTSI&KSM，推出BUSD&USDT降息優惠！",
        title33: "我們的優勢",
        title34: "安全穩定",
        title35: "頂尖的技術團隊，全方位安全保護，自主研發高速交易合引擎，海量交易並發下仍可穩定可靠運作。",
        title36: "專業合規",
        title37: "專業營運團隊，多年區塊鏈及金融經驗，持有合規數位資產交易牌照，百分百備付金保證。",
        title38: "用戶至上",
        title39: "面向全球市場，多種語言支持，7*24小時不間斷運行，強大的社區支持，專業的客戶服務。",
        title40: "高效能",
        title41: "每秒30萬筆交易，訂單回應時間不到1毫秒。",
        title42: "關於我們",
        title43: "隨時隨地交易",
        title44: "完美相容多終端，隨時滿足各種場景的交易需求",
        title45: "（掃碼下載）",
        title46: "數位貨幣新聞",
        title47: "我們的合作夥伴",
        title48: "跌幅榜",
        title49: "24h成交額",
        title50: "24h漲跌幅"
    },
    quotation: {
        marketTrading: "行情",
        turnover: "成交量",
        optional: "自選",
        contractMarket: "合約市場",
        currencyPair: "幣對",
        latestPrice: "收盤價",
        upsAndDowns: "漲跌幅",
        highest: "最高",
        lowest: "最低",
        transactionAmount: "交易額",
        operate: "操作",
        buy: "交易",
        topTurnovers: "成交榜",
        topGainers: "漲幅榜",
        tradingPair: "交易對",
        buyOrder: "買盤",
        sellOrder: "賣盤"
    },
    accountChange: {
        demo: "模擬",
        realAccount: "實際帳戶",
        demoAccount: "模擬帳戶",
        accountRole: "帳戶角色",
        pleaseSelect: "請選擇",
        AccountChangeType: "帳變類型",
        AccountType: "帳戶類型",
        currencyType: "幣種類型",
        allType: "全部類型",
        allAccount: "全部帳戶",
        allCoin: "所有幣種",
        coin: "幣種",
        amount: "變化金額",
        balance: "帳變餘額",
        time: "時間",
        title: "帳變紀錄",
        filtrar: "篩選",
        check: "切換到",
        showOnly: "只顯示持幣幣種",
        holdAssets: "持幣餘額",
        accountList: "帳戶列表"
    },
    contractTransaction: {
        chart: "图表",
        basicEdition: "基礎版",
        depthMap: "深度圖",
        second: "秒",
        minute: "分",
        day: "天",
        week: "週",
        month: "月",
        open: "開",
        trade: "交易",
        buyLong: "做多買入",
        buyShort: "做空買入",
        cannotBuy: "該幣種不支援交割合約交易",
        cannotBuy1: "該幣種不支援永續交易",
        tradingAssets: "交易資產",
        amountAvailable: "可用數量",
        timeStr: "時間",
        transactionPeriod: "交易期間",
        quantity: "數量",
        enterQuantity: "請輸入數量",
        buyIn: "做多",
        latestTransaction: "最新成交",
        time: "時間",
        direction: "方向",
        price: "價格",
        sell: "做空",
        positionOrder: "交割訂單",
        historicalOrders: "歷史訂單",
        product: "產品",
        realAccount: "(真實帳戶)",
        demoAccount: "(模擬帳戶)",
        openingTime: "下單時間",
        openingPrice: "開倉價格",
        curretPrice: "目前價格",
        liquidationPrice: "平倉價格",
        profitOrLoss: "盈虧",
        endTime: "平倉時間",
        areYouSureToBuy: "確定買入嗎？",
        areYouSureToLongBuy: "確定做多買嗎？",
        areYouSureToShortBuy: "確定做空買嗎？",
        successfullyBought: "買入成功",
        minBuy: "最低買入",
        assets: "資產",
        withdraw: "提現",
        entrustedOrder: "委託訂單",
        total: "合計",
        continueToPlaceOrder: "繼續下單",
        orderConfirmation: "訂單確認",
        buyRu: "買入",
        sellChu: "賣出",
        areYouSureToSell: "確定賣出嗎？",
        operation: "操作",
        buyCover: "補倉",
        commitSuccess: "交易成功",
        onlyEnterIntegers: "只能輸入整數",
        trend: "走勢",
        lotteryTime: "截止下單",
        countdown: "倒數計時",
        period: "期號",
        cycle: "時間週期",
        unlimited: "無限制",
        put: "買入看跌",
        call: "買入看漲",
        pending: "委託中",
        unopened: "未開獎",
        opened: "已開獎",
        settlementPrice: "結算價格",
        rateOfReturn: "殖利率",
        currentPositions: "目前持倉",
        positionQuantity: "持倉數量",
        guarantee: "保證金",
        text1: "漲",
        text2: "跌",
        text3: "等待買入",
        text4: "下單數量",
        text5: "成交數量",
        text6: "撤銷",
        text7: "交割時間",
        text8: "撤銷單提醒",
        text9: "確定撤銷訂單嗎",
        text10: "數量不合法",
        text11: "下單提醒",
        text12: "確定買入看漲嗎",
        text13: "確定買入看跌嗎",
        text14: "撤單成功"
    },
    mining: {
        title1: "世界領先的區塊鏈數位資產挖礦平台",
        title2: "公開透明 · 安全穩定",
        title3: "請選擇貨幣",
        title4: "挖礦年化報酬率",
        title5: "天",
        title6: "挖礦期限",
        title7: "產品詳情",
        btn: "立即挖礦",
        btn2: "關閉",
        tabs1: "進行中",
        tabs2: "已贖回",
        table1: "產品名稱",
        table2: "挖礦期限",
        table3: "挖礦時間",
        table4: "挖礦金額",
        table5: "到期時間",
        table6: "到期收益",
        table7: "訂單狀態",
        egularMining: "定期挖礦",
        inProgress: "進行中",
        redeemed: "已贖回",
        redemption: "贖回",
        regularMining: "定期挖礦",
        earlyRedemption: "提前贖回",
        need: "需",
        handlingFee: "手續費",
        redemptionConfirmationMessage: "請確認是否提前贖回？",
        form1: "挖礦年化報酬率",
        form2: "挖礦期限",
        form3: "挖幣幣種",
        form4: "認購金額(最小/最大)",
        form5: "提前贖回手續費: ",
        form6: "購買金額",
        form7: "可用資金",
        form8: "購買金額",
        form9: "可用資金",
        form10: "請輸入購買金額",
        form11: "全部",
        form12: "預計收益",
        form13: "可用資金不足",
        form14: "請輸入購買金額",
        form15: "我的訂單",
        form16: "效益",
        form17: "訂單詳情",
        form18: "提前贖回須知:",
        form19: "認購金額(最小)",
        form20: "認購金額(最大)",
        form21: "持有數量",
        form22: "買入時間",
        form23: "贖回時間",
        form24: "已結束",
        form25: "申購成功",
        form26: "贖回成功",
        form27: "輕鬆理財超25%收益，跑贏大盤行情，購買後還能領獎勵",
        form28: "邀請好友獎勵50USDT，合約代金券還能回饋20%佣金"
    },
    assets: {
        content1: "帳戶資產",
        content2: "今日收益",
        content3: "目前帳戶",
        content4: "實際帳戶即為您的實際帳戶資產，可用於交易，提現",
        content5: "提幣",
        content6: "充幣",
        content7: "兌換",
        content8: "劃轉",
        content9: "請選擇",
        content10: "模擬帳戶",
        content11: "實際帳戶",
        content12: "獲得資產",
        content13: "您確定要切換到實際帳戶？",
        content14: "您確定要切換到模擬帳戶？",
        content15: "獲取成功",
        table1: "幣種",
        table2: "數量",
        table3: "凍結",
        table4: "可用",
        dialog1: "產品詳情",
        dialog2: "1、該資產僅允許在模擬帳戶模式下使用",
        dialog3: "2、為防止濫用，1個月內僅允許領取一次",
        dialog4: "3、領取資產將在原基礎上新增領取資產金額，並不會改變持倉狀態",
        dialog5: "4、如您有其他疑問，請聯絡客服",
        dialog6: "關閉",
        dialog7: "立即取得演示資產",
        dialog8: "轉入",
        dialog9: "轉出",
        dialog10: "切換",
        dialog11: "估價",
        dialog12: "演示資產",
        dialog13: "我的帳戶",
        dialog14: "帳戶總資產",
        dialog15: "資產總覽",
        dialog16: "總資產估值",
        dialog17: "資產估值",
        dialog18: "今日收益",
        dialog19: "凍結資金",
        dialog20: "您每月可領取模擬資產額度",
        dialog21: "領取",
        dialog22: "您已領取成功",
        dialog23: '30日累計收益',
        dialog24: "理財產品",
        dialog25: "我的理財",
        dialog26: "歷史理財",
        dialog27: "歷史記錄",
        dialog28: "資金記錄"
    },
    validator: {
        dialog14: "驗證器",
        dialog15: "綁定驗證器",
        dialog16: "驗證器開關",
        dialog17: "驗證碼",
        dialog18: "驗證器二維碼",
        dialog19: "驗證器金鑰",
        dialog20: "儲存",
        dialog21: "請選擇驗證器",
        dialog22: "複製密鑰",
        dialog23: "保存成功",
        dialog24: "請輸入驗證碼",
        dialog25: "友情提示：確認儲存前請您開啟對應的APP掃描下方二維碼或手動新增鑰匙！",
        dialog26: "啟動您的驗證器使帳戶的安全提供最大的保障。",
        dialog27: "您在每次登入或提幣時都需要輸入一組透過手機存取的唯一驗證碼。",
        dialog28: "設定驗證器",
        dialog29: "幫助",
        dialog30: "您也未綁定身份驗證碼。 借助身份驗證器獲得更強大的保護。",
        dialog31: "目前還未設定驗證器，請先設定",
        dialog32: "去設定"
    },
    recharge: {
        title1: "不知道如何使用數位貨幣購買？",
        title2: "只有三個簡單的步驟!",
        title3: "從下拉式選單中選擇你需要購買的數位貨幣，然後輸入購買數量或金額。",
        title4: "選擇你喜歡的付款方式",
        title5: "確認付款後，您購買的數位貨幣將自動轉入您的帳戶。",
        title6: "請選擇您要儲值的幣種",
        title7: "充幣記錄",
        table1: "幣種",
        table2: "數量",
        table3: "鏈名稱",
        table4: "狀態",
        table5: "原因",
        table6: "時間",
        table7: "查看憑證",
        dialog1: "請輸入下面的驗證碼",
        dialog2: "付款碼",
        dialog3: "複製",
        dialog4: "地址",
        dialog5: "充幣數量",
        dialog6: "交易截圖",
        dialog7: "重要通知",
        dialog8: "請填寫充幣數量",
        dialog9: "上傳圖片",
        dialog10: "請輸入內容",
        dialog11: "請上傳儲值截圖",
        dialog12: "未審核",
        dialog13: "已拒絕",
        dialog14: "已入金",
        tip1: "提交成功",
        tip2: "複製成功",
        tip3: "複製失敗",
        tip4: "請選擇要儲值的幣種",
        tip5: "1.以上充幣地址為平台官方收款地址，請認準平台官方充幣地址，誤充造成資金損失需自行負擔；",
        tip6: "2.請務必確認電腦及瀏覽器安全，防止資訊被竄改或外洩；",
        tip7: "3.您儲值上述位址後，需要整個網路節點的確認後方可到帳；",
        tip8: "4.上述代幣體系與幣種，請選擇好後劃轉相應金額進行充幣，請勿轉入任何其他無關資產，否則將不可找回。",
        tip9: "儲存二維碼",
        tip10: "儲值",
        tip11: "重要通知",
        tip12: "場外連結",
        tip13: "儲值二維碼",
        tip14: "下一步",
        tip15: "儲值記錄",
        tip16: "儲值詳情",
        tip17: "充幣地址",
        tip18: "快速支付",
        tip20: "餘額不足",
        tip22: "已提交，等待確認",
        tip23: "等待付款",
        tip24: "金額",
        tip25: "支付已提交，等待區塊確認",
        tip26: "付款被拒絕",
        tip27: "付款失敗",
        tip28: "付款成功",
        bankChannel: "金融卡通道",
        tip29: "歷史搜尋",
        tip30: "幣種列表",
        tip31: "選擇網路",
        tip32: "當您充值該幣種時,請僅限於透過下方支援的網路儲值，如您透過其他網路儲值會造成資金遺失",
        tip33: "當您提現該幣種時,請僅限於透過下方支援的網路提現，如您透過其他網路提現會造成資金遺失",
        tip34: "快速儲值",
        tip35: "掃碼儲值",
        tip36: "手動儲值",
        tip37: "儲值網絡",

        tip38: "儲值地址",
        tip39: "儲值金額",
        tip40: "交易回執上傳",
        tip41: "上傳圖片",
        tip42: "確認提交",
        tip43: "確認支付",
        tip44: "備註:請確認您需要儲值的地址,若填錯導致金額損失我方不負責",
        tip45: "提醒",
        tip46: "地址複製成功!",
        tip47: "地址複製失敗!",
        tip48: "可用餘額不足",
        tip49: "提交儲值成功,等待鏈上確認完成",
        tip50: "儲值失敗",
        tip51: "儲值金額有誤",
        tip52: "連線失敗",
        tip53: "錢包未登入",
        tip54: "連線被拒絕",
        tip55: "提交成功,等待客服審核通過",
        tip56: "請上傳交易回執截圖",
        tip57: "提交失敗",
        tip58: "儲值記錄",
        tip59: "提現記錄",
        tip60: "儲值幣種",
        tip61: "提現幣種",
        tip62: "儲值地址",
        tip63: "截圖顯示",
        tip64: "點擊放大圖片",
        tip65: "儲值時間",
        tip66: "拒絕理由",
        tip67: "提現金額",
        tip68: "到帳金額",
        tip69: "提現網絡",
        tip70: "提現地址",
        tip71: "提現時間",
        tip72: "關閉",
        tip73: "交易截圖",
        tip74: "待審核",
        tip75: "已拒絕",
        tip76: "已完成",
        tip77: "已拒絕",
        tip78: "已審核",
        tip79: "已出款",
        tip80: "提取",
        tip81: "轉帳網絡",
        tip82: "地址/票據",
        tip83: "全部提現",
        tip84: "請勿直接提現至眾籌或ICO地址，否則將無法收到眾籌或ICO發放的代幣",
        tip85: "到帳數量",
        tip86: "確認提現",
        tip87: "提現地址錯誤",
        tip88: "提現金額錯誤",
        tip89: "提現金額至少為",
        tip90: "最大提現金額為",
        tip91: "提現請求已提交",
        tip92: "地址列表",
        tip93: "您還沒有新增地址",
        tip94: "立即新增",
        tip95: "新增地址",
        tip96: "新增地址",
        tip97: "請選擇",
        tip98: "地址",
        tip99: "請選擇轉帳網絡",
        tip100: "請輸入地址",
        tip101: "地址添加成功",
        tip102: "交易中",
    },
    fundExchange: {
        title1: "如何兌換資金？",
        title2: "選擇需要兌換的相應貨幣。",
        title3: "選擇要兌換的相應貨幣。",
        title4: "輸入兌換的數量，然後點擊提交，完成資金兌換。",
        title5: "從",
        title6: "到",
        title7: "兌換數量",
        title8: "匯率",
        title9: "可用",
        title10: "請輸入兌換數量",
        title11: "沒有對應的交易對可以進行兌換",
        title12: "請輸入要兌換的數量",
        title13: "兌換成功",
        title14: "兌換記錄",
        table1: "使用貨幣",
        table2: "數量",
        table3: "匯率",
        table4: "兌換幣種",
        table5: "到帳",
        table6: "時間",
        title15: "從",
        title16: "目前無可用資產，請選擇其他"
    },
    fundTransfer: {
        title1: "如何轉移資金？",
        title2: "選擇需要轉移的目前帳戶。",
        title3: "選擇要轉移的帳戶。",
        title4: "輸入轉帳金額，點選提交，完成轉帳。",
        title5: "幣種",
        title6: "劃轉數量",
        title7: "請輸入要劃轉的數量",
        title8: "劃轉記錄",
        title9: "請選擇錢包類型",
        title10: "請選擇幣種",
        title11: "請選擇錢包類型",
        title12: "請選擇幣種",
        title13: "請輸入要劃轉的數量",
        title14: "提交成功",
        table1: "轉出帳戶",
        table2: "轉移貨幣",
        table3: "劃轉數量",
        table4: "轉入帳戶",
        table5: "時間",
        table6: "劃轉",
        table7: "資金劃轉",
        table8: "劃轉至",
        table9: "劃轉記錄",
        table10: "劃轉詳情",
        table11: "劃轉成功",
        table12: "劃轉失敗",
        table13: "目前帳戶不可劃轉"
    },
    withdrawal: {
        title1: "重要通知",
        title2: "1.為防止套現行為，交易量達到額度即可申請提幣。",
        title3: "2.提交提幣申請後24H內到賬，如預計提幣時間後未到賬請諮詢線上客服查詢。",
        title4: "3.提交提幣申請後，該筆資金屬於凍結狀態是因為提現正在進行中，該筆資金暫時由系統託管，並不代表您損失了這筆資產或資產出現異常。",
        title5: "幣種",
        title6: "錢包地址",
        title7: "提幣數量",
        title8: "提幣記錄",
        title9: "請選擇幣種",
        title10: "請選擇鏈類型",
        title101: "",
        title11: "請輸入",
        title12: "錢包地址",
        title13: "提交成功",
        title14: "審核中",
        title15: "審核成功",
        title16: "審核失敗",
        title17: "已出款",
        title18: "請輸入或貼上錢包地址",
        title19: "請輸入提幣數量",
        title20: "手續費",
        title21: "實際到帳",
        table1: "幣種",
        table2: "提幣數量",
        table3: "鏈名稱",
        table4: "錢包地址",
        table5: "狀態",
        table6: "原因",
        table7: "申請時間",
        table8: "提幣",
        table81: "提幣",
        table9: "提幣地址",
        table10: "請選擇您要提幣的幣種",
        table11: "請確保輸入的是",
        table12: "位址（通常以“0x”開頭）",
        table13: "可提幣（已扣除手續費）：",
        table14: "選擇幣種",
        table15: "遇到問題？ 立即回饋",
        table16: "餘額",
        table17: "提幣詳情",
        password: "密碼",
        passwordTips: "請輸入密碼",
        passwordAg: "確認密碼",
        passwordAgTips: "請再次輸入密碼",
        oldPassword: "舊密碼",
        oldPasswordTips: "請輸入舊密碼",
        newPassword: "新密碼",
        newPasswordTips: "請輸入新密碼",
        passwordNotSame: "密碼不一致",
        withdrawPasswordSet: "設定提現密碼",
        withdrawPasswordModify: "修改提現密碼",
        withdrawPassword: "提現密碼",
        withdrawPasswordTips: "請輸入提現密碼",
        setWithdrawPasswordFirst: "請先設定提現密碼",
        withdrawPasswordSetSuccess: "設定成功",
        withdrawPasswordModifySuccess: "修改成功",
        submit: "提交",
    },
    customer: {
        content1: "網路客服",
        content2: "客服",
        content3: "請輸入您的聯絡方式",
        content4: "請輸入內容",
        content5: "傳送",
        content6: "傳送失敗，網路連線失敗",
        content7: "圖片",
        content8: "訊息為空",
        content9: "我的分享",
        content10: "分享連結",
        content11: "保存圖片",
        content12: "我的收益",
        content13: "等級說明",
        content14: "團隊人數",
        content15: "邀請收益",
        content16: "规则说明",
        content17: "會員等級",
        content18: "儲值金額大於",
        content19: "團隊等級",
        content20: "團隊等級&收益",
        content21: "滿足條件",
        content22: "分銷收益",
        content23: "級團隊",
        content24: "級，邀請",
        content25: "個有效會員",
        content26: "一級收益",
        content27: "二級收益",
        content28: "三級收益",
    },
    standardContract: {
        takeProfitPriceWrong: "止盈價格錯誤",
        stoppriceerror: "停損價格錯誤",
        thetakeprofit1: "止盈價格必須高於市價",
        thetakeprofit2: "停損價格必須低於市價",
        thetakeprofit3: "止盈價格必須低於市價",
        thetakeprofit4: "停損價格必須高於市價",
        thetakeprofit5: "數量錯誤",
        estimatedprofitloss: "預計盈虧",
        positionAmount: "部位數量",
        closeAmount: "平倉數量",
        whenthelatest: "當最新價格觸達",
        whenthelatest2: "時，將會觸發市價止盈委託平倉目前部位。 預期盈虧",
        whenthelatest3: "時，將會觸發市價止損委託平倉目前倉位。 預期盈虧",
        contract: "合約",
        ordercanceledsuccessfully: "訂單撤銷成功",
        successfullyordered: "下單成功",
        positionopeningreminder: "開倉提醒",
        wrongquantity: "數量有誤",
        quantitycannot: "數量不可大於",
        modificationstoploss: "修改停損成功",
        positionclosedsuccessfully: "平倉成功",
        orderCancellation: "訂單撤銷",
        orderCancellation2: "確定撤銷訂單嗎",
        tobeexecuted: "待執行",
        expired: "已過期",
        failed: "已失敗",
        contractTransaction: "永續合約",
        increase: "漲幅",
        openPosition: "全倉",
        isolatedPosition: "逐倉",
        limitOrder: "限價",
        marketOrder: "市價",
        pleaseSelectLeverage: "請選擇槓桿",
        pleaseEnterQuantity: "請輸入數量",
        canOpenMore: "最大可開倉",
        contractAmount: "合約金額",
        openingFee: "手續費",
        currentPosition: "持倉",
        historicalPositions: "歷史成交",
        currentCommission: "當前委託",
        historicalCommission: "歷史委託",
        buyPrice: "買入價格",
        income: "獲利",
        quantity: "數量",
        marginRate: "保證金費率",
        initialMargin: "初始保證金",
        estimatedPriceParity: "強制平倉價",
        operate: "操作",
        finishQuantity: "已成交量",
        averagePrice: "成交均價",
        pleaseEnterLeverage: "請輸入槓桿",
        takeProfitAndStopLoss: "停損",
        takeProfit: "止盈",
        stopLoss: "停損",
        openLong: "開多",
        openShort: "開空",
        liquidation: "平倉",
        marketLquidation: "市價全平",
        cancel: "撤銷單",
        enterBuyPrice: "請輸入買入價格",
        areYouSureLquidation: "確定平倉嗎?",
        confirm: "確定",
        enterStopWinPrice: "請輸入止盈價格",
        enterStopLossPrice: "請輸入停損價格",
        commissionTime: "委託時間",
        commissionPrice: "委託價格",
        commissionQuantity: "委託總量",
        volumeAlreadyTraded: "已完成量",
        areYouSureToWinOrLoss: "確定要停盈停損嗎?",
        maxQuantity: "最大持倉量",
        saleQuantity: "已平倉量",
        salePrice: "平倉均價",
        saleTime: "時間",
        succeeded: "已成功",
        cancelled: "已取消",
        piece: "張",
        triggerPrice: "觸發價格",
        setWin: "設定止盈",
        setLoss: "設定停損",
        enterWinQuantity: "請輸入止盈數量",
        enterLossQuantity: "請輸入停損數量",
        planCommission: "計畫委託",
        enterWinTriggerPrice: "請輸入止盈觸發價格",
        enterLossTriggerPrice: "請輸入停損觸發價格",
        insufficientQuantity: "數量不足",
        enterTriggerPrice: "請輸入觸發價格",
        tips1: "您的委託價格比目前價格高，會以目前市價直接成交，是否確定買入？",
        tips2: "您的委託價格比目前價格低，將以目前市價直接成交，是否確定買入？",
        type: "類型",
        increaseQuantity: "增加持倉",
        reduceQuantity: "減少持倉",
        limitCommission: "限價委託",
        tips3: "停損的價格必須低於市價",
        tips4: "止盈的價格必須高於市價",
        tips5: "停損的價格必須高於市價",
        tips6: "止盈的價格必須低於市價",
        tips7: "停損價格要小於市價，小於委託價格",
        tips8: "止盈價格大於委託價格（可以比市價小）",
        tips9: "停損價格大於市價，大於委託價格",
        tips10: "止盈價格要小於委託價格（可以比市價大）",
        accountEquity: "帳戶餘額",
        unrealizedPL: "未實現盈虧",
        tips11: "你確定對",
        tips12: "按市價進行",
        tips13: "全部平倉",
        tips14: "不再提示",
        profitRate: "平倉收益率",
        openTime: "開倉時間",
        unwindTime: "平倉時間",
        dealTime: "成交時間",
        amountClose: "已平倉金額",
        long: "多",
        short: '空',
        orderQuantity: "委託數量",
        entrustedMargin: "委託保證金",
    },
    spotGoods: {
        spotGoods: "閃兌",
        sellPrice: "賣出價格",
        orderRecord: "閃兌記錄",
        transactionTime: "成交時間",
        maxBuy: "可買",
        maxSell: "可賣",
        purchaseQuantity: "購買數量",
        slippage: "滑點",
        convertTips: "我們將以最優惠的價格成交，滑點在0-5%。",
        changePrice: "當前兌換價格",
        consume: "消耗",
        get: "獲得",
        changeTips: "確定要兌換嗎？",
        confirm: "確認兌換"
    },
    news: {
        title: "新聞中心",
        redMore: "閱讀更多",
    },
    credit: {
        credit: "信用分",
        creditScore: "信用總分",
        latestEntry: "最新記錄",
        liveUpdate: "即時更新",
        systemEvaluation: "系統評估",
        time: "日期",
        name: "名稱",
        reason: "原因",
        scoreStory: "信用分記錄"
    },
    borrowing: {
        borrowing: "借貸",
        diversifiedMarket: "多樣化市場",
        totalSupply: "供應總數",
        totalBorrowings: "總借款",
        tips1: "這是您可以藉款的總金額，您可以根據您的抵押品借款，直到您達到借款上限。",
        tips2: "在您貸款期間，穩定的利率將保持不變。 推薦用於長期供應和喜歡可預測性的用戶。",
        totalSupplys: "供應總量",
        supplyAPR: "供應利率",
        totalBorrowings: "供應利率",
        borrowAPR: "借用利率",
        check: "查看",
        market: "市場",
        availableLiquidity: "可用流動性",
        currencyPrice: "當前貨幣價格",
        supplyAssets: "供應資產",
        tips3: "須知：下方利率為固定利率，供應週期結束後，本金加收益將回到您的帳戶。",
        supplyCycle: "供應週期",
        minimumSupply: "最低供應",
        supply: "供應",
        borrowedAssets: "借入資產",
        tips4: "須知：要藉款，您需要提供任何抵押資產或證明，",
        tips5: "請查看抵押資產說明。",
        repaymentCycle: "還款週期",
        BorrowableAmount: "可藉額度",
        borrow: "借款",
        accountInformation: "帳號資訊",
        walletBalance: "錢包餘額",
        canSupply: "可供應",
        AmountCanBeBorrowed: "可藉總額度",
        borrowedAmount: "已借額度",
        remainingAmount: "剩餘額度",
        getBorrowednAmount: "獲取借款額度",
        supplyOrder: "供應訂單",
        borrowedOrder: "借款訂單",
        supplyAmount: "供應金額",
        expectedProfits: "預期收益",
        supplyTime: "供應時間",
        expirationTime: "到期時間",
        borrowingAmount: "借款金額",
        dueRepayment: "還款金額",
        borrowingTime: "發放時間",
        repaymentTime: "還款時間",
        repay: "還款",
        supplyCurrency: "供應幣種",
        buyNow: "立即買入",
        supplyRecords: "供應記錄",
        borrowRecords: "借款記錄",
        completed: "已完成",
        borrowNow: "立即藉款",
        borrowCurrency: "借款幣種",
        borrowMin: "最小",
        borrowMax: "最大",
        borrowRange: "借款金額（最小/最大）",
        tips6: "請及時還款，若逾期，每日額外收取（本金+利息)金額中的",
        tips7: "為逾期費。",
        pleaseEnterBorrowAmount: "請輸入借款金額",
        interest: "利息",
        tips8: "確定要提前還款嗎？",
        borrowChannel: "借款頻道",
        tips9: "讓我們從認識你開始",
        name: "名",
        lastName: "姓",
        birthDate: "出生日期",
        tips10: "再多一點信息，你的地址是什麼？",
        familyAddress: "家庭住址（含單位編號）",
        city: "城市",
        country: "國家",
        postalCode: "郵遞區號",
        pleaseEnterYourHomeAddress: "請輸入家庭地址",
        tips11: "告訴我們您的財務狀況",
        tips12: "我們可能需要稍後確認此訊息，因此請盡量準確",
        yourMonthlyIncome: "我們可能需要稍後確認此訊息，因此請盡量準確",
        enterYourMonthlyIncome: "請輸入您的月收入",
        extraIncomeOptional: "額外收入（可選）",
        enterExtraIncome: "請輸入您的額外收入",
        monthlyRentOrMortgagePayment: "每月租金或按揭付款",
        enterMonthlyRent: "請輸入您的每月租金或按揭付款",
        tip13: "告訴我們您是否有抵押",
        tip14: "如未選擇抵押擔保，則審核通過機率較低",
        isThereMortgage: "是否有抵押",
        selectIsThereMortgage: "請選擇是否有抵押",
        selectMortgageType: "請選擇抵押類型",
        yes: "是",
        no: "否",
        uploadScreenshotOfTransaction: "請上傳交易截圖",
        tip15: "讓我們保持聯繫",
        tip16: "如果我們需要有關您的申請貸款的更多信息，我們可能會透過此號碼與您聯繫",
        tip17: "我已閱讀並以電子簽名方式同意",
        tip18: "使用條款和電子同意書",
        tip19: "，其中包括您同意我們向您發送有關我們的",
        tip20: "產品和服務使用條款",
        tip21: "資料填寫完成之後，請等待系統評估審核。",
        phoneNumber: "電話號碼",
        enterPhoneNumber: "請輸入您的電話號碼",
        tip22: "請勾選同意使用條款和電子同意書",
        inReview: "審核中",
        applicationAmount: "申請額度",
        reasonForRejection: "拒絕理由",
        reapply: "重新申請",
        applyStatus: "申請狀態",
        currentOrder: "目前訂單",
        interestRate: "利率"
    },
    yuebao: {
        filed1: "投入",
        filed2: "取出",
        filed3: "日收益",
        filed4: "團隊加速",
        filed5: "已存入",
        filed6: "昨日收益",
        filed7: "總收益",
        filed8: "可用資金",
        filed9: "請輸入金額",
        filed10: "流動性挖礦",
        filed11: "簡介",
        field12: "最低可買",
        field13: "最多可買",
        filed14: "申購",
        filed15: '定期',
        filed16: '年化利率',
    },
    tipsPopover: {
        title: "安裝此應用程式",
        press: "按",
        and: "和",
        scrren: "新增到主螢幕",
        hasInstall: "(如已安裝，請關閉視窗)",
    },
    follow: {
        title1: "跟單計劃",
        follow_text1: "發起跟單",
        follow_text2: "發布歷史",
        follow_text3: "標題",
        follow_text4: '交易對',
        follow_text5: '購買時長',
        follow_text6: '下單時間',
        follow_text7: '發佈時間',
        follow_text8: '跟單人數',
        follow_text9: '跟單金額',
        follow_text10: '操作',
        follow_text11: '邀請',
        follow_text12: '跟單方向',
        follow_text13: '設定跟單條件',
        follow_text14: '跟單條件限制',
        follow_text15: '固定金額',
        follow_text16: '百分比',
        follow_text17: '受邀人',
        follow_text18: '對方的id',
        follow_text19: "確定跟單",
        follow_text20: "您的跟單金額（選填）",
        follow_text21: "請輸入標題",
        follow_text22: "請選擇交易對",
        follow_text23: "請選擇跟單方向",
        follow_text24: "請選擇購買時長",
        follow_text25: "請選擇下單時間",
        follow_text26: "請選擇購買數量",
        follow_text27: "最大購買數量不可小於最小購買數量",
        follow_text28: "最低買入",
        follow_text29: "最多買入",
        follow_text30: "創建跟單成功",
        follow_text31: "交易對列表",
        follow_text32: "已跟單",
        follow_text33: "請輸入受邀人資訊",
        follow_text34: "已邀請",
        follow_text35: "邀請我的",
        follow_text36: "跟單歷史",
        follow_text37: "跟單金額範圍"
    },
    followHistory: {
        title1: '發布歷史',
        title2: "跟單歷史",
        follow_h_text1: "跟單總金額",
        follow_h_text2: "跟單總人數",
        follow_h_text3: "發布次數",
        follow_h_text4: "勝率",
        follow_h_text5: "跟單次數",
        follow_h_text6: "跟單獲利",
        follow_h_text7: "方向",
        follow_h_text8: "贏",
        follow_h_text9: "虧",
    },
    "asset.index.1579E8B41F8D5134": "我的資產",
    "asset.index.B3F6F05Dfbe431Cd": "模擬",
    "asset.index.B9Aeab4Ee8F33746": "資產估值",
    "asset.index.583Df8Bd541Eb7E8": "收益趨勢",
    "asset.index.B1254815D133Cc26": "7天",
    "asset.index.2F615F6C029F01B7": "30天",
    "asset.index.29439Fb58E4Bbe22": "提現",
    "asset.index.831Ab31568A78285": "儲值",
    "asset.index.39F7A0D48D12E8C9": "劃轉",
    "asset.index.B99063C5689Beaec": "閃兌",
    "asset.index.E1Dc620F2F038B2A": "領取資產",
    "asset.index.972A0B4C2B4393D0": "我的帳戶",
    "asset.index.9Dfb0Fb334620733": "可用餘額",
    "asset.index.Cdc5Fbd26790Cb47": "凍結金額",
    "asset.index.3D8132D72Ae965Cd": "估價",
    "asset.receive.825503Cc430559Cb": "獲得資產",
    "asset.receive.54154A0553A535B7": "演示資產",
    "asset.receive.F2E57B00A9C5D129": "1.該資產僅允許在模擬帳戶模式下使用",
    "asset.receive.Ea0D1D52989299Bd": "2.為防止濫用，1個月內僅允許領取一次",
    "asset.receive.090E0037Aa235D3D": "3.領取資產將在原基礎上新增領取資產金額，並不會改變持倉狀態",
    "asset.receive.7Fd4E1Abc805C0A6": "4.如您有其他疑問，請聯絡客服",
    "asset.receive.Df5B3A511D986575": "立即取得演示資產",
    "asset.records.1Fa02E8187A39592": "近三個月",
    "asset.records.7B291C37333Fe34D": "",
    "asset.records.A476Cd1Ec868Fc5F": "近三個月,",
    "asset.records.9D5755A842E55Bb7": "由近到遠,",
    "asset.records.E3Fa05E607203Cdc": "儲值",
    "asset.records.B4F206C6Bec08Dea": "提現",
    "asset.index.Df5B3A511D986576": "您已成功領取演示資產",
    "deposit.depositSpeedy.Efe81D0E0174239B": "支付方式",
    "deposit.depositSpeedy.B9293Ee96E435F85": "法幣",
    "deposit.depositSpeedy.16658A855F07D171": "金額",
    "deposit.depositSpeedy.204Ffab8A6E01870": "確定",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "不可為空",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "最小儲值",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "最大儲值",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "儲值USDT",
    "deposit.depositWallet.551640C76F4Ca4A1": "儲值幣種",
    "deposit.depositWallet.B404C543712B71D5": "儲值網絡",
    "deposit.depositWallet.A484B0F5E94B3F4F": "充幣地址",
    "deposit.depositWallet.D3980D54Acf242Db": "儲存二維碼",
    "deposit.depositWallet.A06E84801D6582Be": "儲值金額",
    "deposit.depositWallet.Daaaeb1B7B22B126": "提示",
    "deposit.depositWallet.D599E40E1Eeec1B3": "儲值成功",
    "deposit.depositWallet.4F48Abbafce532Bb": "提交成功，等待客服審核",
    "deposit.depositWallet.A88C481228811696": "您拒絕了切換網絡",
    "deposit.depositWallet.C6Ebf27895C21932": "請手動開啟波場錢包插件並登錄",
    "deposit.index.B5491C8Dd09D8183": "選擇您要儲值的貨幣",
    "deposit.index.2F784B2298D39987": "快速儲值",
    "deposit.index.0Daa03Dcdcabd837": "場外連結",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "提現確認",
    "withdraw.bottomSheet.A086Fa20082C849D": "請確保以下所填資訊正確無誤",
    "withdraw.bottomSheet.Bf8B184D32971501": "提幣網絡",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "地址",
    "withdraw.bottomSheet.F9Db93B87E08763B": "數量",
    "withdraw.bottomSheet.4Ad26475002878C0": "手續費",
    "withdraw.index.A96454041169Af6D": "選擇幣種",
    "withdraw.index.2A563B7081Dd5B83": "提幣地址",
    "withdraw.index.B189C2Ef713Cfff4": "提幣數量",
    "withdraw.index.558F35140E84B562": "可用：",
    "withdraw.index.F4E759087E12Cb77": "全部提現",
    "withdraw.index.5Cbd9A6B284D25Ae": "資金密碼",
    "withdraw.index.B89Cbe4Bfc33Bb41": "手續費:",
    "withdraw.index.C886De8D8A27026E": "重要通知",
    "withdraw.index.Eac7C5A4095F5606": "1.為防止套現行為，交易量達到額度即可申請提幣。",
    "withdraw.index.C3Cc7Fff24504Be9": "2.提交提幣申請後24H內到賬，如預計提幣時間後未到賬請諮詢線上客服查詢。",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3.提交提幣申請後，該筆資金屬於凍結狀態是因為提現正在進行中，該筆資金暫時由系統託管，並不代表您損失了這筆資產或資產出現異常。",
    "withdraw.index.4639F3A6E07C00B3": "下一步",
    "withdraw.index.81Fba0D65Cc5C3Aa": "請輸入或貼上錢包地址",
    "withdraw.index.25768141C8Bdea91": "請輸入提幣數量",
    "withdraw.index.97A85734Cd1B7Bf0": "請輸入資金密碼",
    "withdraw.index.39C7D74B33241C78": "提現數量有誤",
    "withdraw.index.86A5D17908197525": "提現確認，等待審核放幣)",
    "withdraw.index.48Fb603C6638C7F6": "請設定資金密碼",
    "withdraw.index.873Ade9A3Bacc2F7": "提示",
    "bill.index.5Bbb2A6Ac68D6B2E": "實際帳戶",
    "bill.index.75751E0Eb8261C40": "模擬帳戶",
    "bill.index.Cbc853B37Ecc2E97": "帳變類型",
    "bill.index.4A0Cacddeb2E38B1": "帳戶類型",
    "bill.index.F16217028E098B2F": "幣種",
    "bill.index.A620Dbe6F06B78Ba": "全部類型",
    "bill.index.2C3C148Bb94D9B3F": "全部帳戶",
    "c2C.index.Ab45695E4Bf1E382": "C2C交易",
    "c2C.index.2702Dc911Dfea762": "我要買",
    "c2C.index.D737D5B8367A53C4": "我要賣",
    "c2C.index.C0C540A3Ba9Abe01": "成交量",
    "c2C.index.D8644E8D60C8Bd8A": "單價",
    "c2C.index.Ebb8670B203D3604": "限額",
    "c2C.index.F8C6245A2Ebf1D27": "所有金融卡",
    "c2C.index.F8C6245A2Ebf1D28": "幣收款",
    "c2C.index.F8C6245A2Ebf1D29": "金融卡",
    "c2C.index.Cc3Aa8141Da9Ec9E": "購買",
    "c2C.index.Cc3Aa8141Da9Ec9F": "出售",
    "c2C.index.67A39834582Ad99A": "暫無數據",
    "c2C.order.04C23810B47F77F7": "按金額",
    "c2C.order.6905365902E0A73D": "按數量",
    "c2C.order.3F0Ecebbd9D2F23B": "可用",
    "c2C.order.16853Bda54120Bf1": "全部",
    "c2C.order.Ad3335796F2C95F7": "總額",
    "c2C.order.61092E27A6879318": "保護資產安全，請提高防範意識！",
    "c2C.order.0F51881F0Fb920B2": "交易資訊",
    "c2C.order.Dd74Ceca85E5Bc65": "付款時限",
    "c2C.order.De3341388A59073F": "30分鐘",
    "c2C.order.D334B597A352167F": "賣家暱稱",
    "c2C.order.D334B597A352167G": "買家暱稱",
    "c2C.order.9D5F89F99Ced890D": "交易方式",
    "c2C.order.8Adf755B14Bc6B7C": "請先閱讀以下內容",
    "c2C.order.686B16F454A7338B": "銀行卡轉帳切勿備註，不然不給予放幣及直接封其帳號。 付款後需提供打款後新的交易明細圖（如果P圖或隱藏交易明細上報平台凍結帳戶）",
    "c2C.order.9Bec8Fba867B739B": "選擇收款方式",
    "c2C.order.Cf2D892C419Fea36": "最小購買",
    "c2C.order.7290A6B7456A03E0": "最大購買",
    "c2C.orderConfirm.F7E99B8C203E2B07": "訂單詳情",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "訂單已生成",
    "c2C.orderConfirm.0F5433503F3845Ce": "請在倒數時間內付款給賣家。",
    "c2C.orderConfirm.24C3Bcacdb85A451": " 等待買家付款 ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "聯絡賣家",
    "c2C.orderConfirm.8E8140D7Ab607B25": "聯絡買家",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "賣家的數位貨幣現已存入託管帳戶，請放心付款  ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "買家實名：",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "7x24小時客服支持 ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "訂單編號",
    "c2C.orderConfirm.726C51702F70C486": "創建時間",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "聯絡客服",
    "c2C.orderConfirm.103408Fbbe30287F": "取消訂單",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "我已確認付款",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "已付款",
    "c2C.orderConfirm.387Ca3625B260E4E": "您已付款訂單",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "交易取消",
    "c2C.orderConfirm.76C86566Cc3751C0": "訂單已取消",
    "c2C.orderConfirm.205B349751D176B4": "支付成功",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "收款方式",
    "c2C.paymentAdd.C87E865Df6800304": "付款方式配置",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "支付方式類型",
    "c2C.paymentAdd.06910Bddf002695F": "真實姓名",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "PayPal帳號",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "銀行帳號/卡號",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "幣種名",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "支付二維碼",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "點擊上傳",
    "c2C.paymentAdd.6Daae340F2E9718A": "銀行名稱",
    "c2C.paymentAdd.347751415C3Da489": "鏈名稱",
    "c2C.paymentAdd.15B3627Faddccb1D": "備註",
    "c2C.paymentAdd.86Da96Cbd274058F": "小提醒：當您出售數位貨幣時，您選擇的收款方式將向買方展示，請確認資訊填寫準確無誤。",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "儲存",
    "c2C.paymentAdd.90D2A3A5C07179C0": "請輸入您的真實姓名",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "請輸入您的",
    "c2C.paymentAdd.Ec389E6190Eedb62": "請輸入您的銀行名稱",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "請輸入您的鏈名稱",
    "c2C.paymentAdd.94F034F0E01439E0": "請輸入您的地址",
    "c2C.paymentAdd.B12E035663A42B32": "所有金融卡",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "幣收款",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "請上傳收款碼",
    "c2C.paymentAdd.07C443212Ff04Bfb": "添加成功",
    "c2C.paymentDetail.86Da96Cbd274058F": "小提醒：當您出售數位貨幣時，您選擇的收款方式將向買方展示，請確認資訊填寫準確無誤。",
    "c2C.paymentDetail.C4C613Da60226De4": "修改成功",
    "c2C.paymentMethod.7609F7C8337C7A17": "C2C收款方式",
    "c2C.records.9E36893D5736Abb4": "訂單列表",
    "c2C.records.4B53F9A5B13B3235": "待付款",
    "c2C.records.4B53F9A5B13B3236": "交易取消",
    "c2C.records.4B53F9A5B13B3237": "已付款",
    "contract.index.9706Df877458F0Be": "全倉",
    "contract.index.623Fc7D3D40Aa479": "初始保證金",
    "contract.index.F92Dce1169330387": "市價全平",
    "contract.index.3598B97Dd28Dbf20": "請輸入數量(張)",
    "contract.index.6E1Fae821Dc0B797": "永續",
    "contract.index.Eb07Eb0C37C14575": "交割",
    "contract.index.Eb6492F19B1C1Fb6": "歷史成交詳情",
    "contract.index.873Ade9A3Bacc2F7": "提示",
    "contract.index.Ff63B0F93D39F50B": "你確定對",
    "contract.index.Ff63B0F93D39F50D": "做多",
    "contract.index.Ff63B0F93D39F50E": "做空",
    "contract.index.Ff63B0F93D39F50F": "進行",
    "contract.index.Ff63B0F93D39F50G": "全部平倉？",
    "customer.dialog.3D410C88E8A24Fe8": "確認暱稱",
    "customer.dialog.4B3340F7872B411F": "取消",
    "customer.dialog.549B1456Bb5F98B3": "請輸入您的暱稱",
    "customer.dialog.B773B3E45Ccfad00": "暱稱為空",
    "customer.index.7308C6B849D159E9": "圖片詳情",
    "delivery.callDialog.C74F562C3Fe91Fe3": "下單成功",
    "delivery.callDialog.B8C98F42Caa15Ca5": "方向",
    "delivery.callDialog.B79A581Dae543974": "做多買入",
    "delivery.callDialog.B79A581Dae543975": "做空買入",
    "delivery.callDialog.3B26F0F7E90773F3": "開倉價格",
    "delivery.callDialog.65Fb252Bd94E1039": "預計收益",
    "delivery.index.B483Ba4Fce633539": "交割",
    "delivery.index.224Dd6Cb58D58249": "交易期間",
    "delivery.index.28158F517E902788": "最低買入",
    "delivery.index.A1232162B14Bec76": "交割訂單",
    "delivery.index.445B1D3176D9C69D": "歷史訂單",
    "delivery.index.Ab6Cb86E28653C5C": "交割",
    "delivery.index.20A7A6F14D3698C0": "開倉時間",
    "delivery.index.Ec6821A49395Ddc2": "平倉時間",
    "delivery.index.254Fdc665C391743": "平倉價格",
    "delivery.index.2A0C5E68B44A472A": "盈虧",
    "delivery.index.E05B33309F6D236A": "請輸入數量",
    "delivery.index.B2899E073Cb355D9": "最少買入",
    "download.description.869467A93C7Dd4A1": "安裝說明",
    "download.description.C80C1Edd6Bee12Bc": "1.允許下載描述文件",
    "download.description.2F5Bc180F92642B4": "2.已下載描述文件",
    "download.description.3C3B849F6Ccf712F": "3.進入手機【設定】在帳號資訊的下方會顯示已下載說明文件",
    "download.description.86Affd862985E045": "4.點擊已下載描述文件",
    "download.description.32832492Bfc1D95E": "5.點選安裝",
    "download.index.245883C80F181C4A": "下載",
    "download.index.Ef3De8Cb2722937A": "隨時隨地，開啟交易",
    "download.index.F4A87Eaeed691638": "透過我們的APP，隨時了解最新資迅",
    "download.index.Ad3Aba52D0D39D01": "下載安裝包",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "溫馨提示",
    "download.safariGuide.2Cc68Aaadf623477": "請在Safari中訪問",
    "download.safariGuide.C80Fff2A6Ff4E768": "您正在訪問一個分散的網站，請在safari應用程式中運行",
    "download.safariGuide.06Da016F3E2F79Bd": "複製連結",
    "finance.index.B034B367545D5236": "全球優質項目",
    "finance.index.6Ebfac2C0A9228B0": "活躍人數160萬+，$1,224億",
    "finance.index.52Bdcaf19659363D": "流動性挖礦",
    "finance.index.D66924272B031Bf5": "超高年化收益",
    "finance.index.34789D88Afcefa64": "查看項目",
    "finance.index.E12676B95654D21E": "信用借貸",
    "finance.index.4C924Cfd89A11Ac7": "發現更多交易機會",
    "finance.index.C251D4B594Da2848": "AI量化",
    "finance.index.4E53Cf2E5F173Dd3": "一鍵租賃，AI智能交易",
    "finance.records.Cce168301447D1Ba": "進行中",
    "finance.records.4587B6Bd7Cfa4454": "已贖回",
    "finance.records.18685418E3A50147": "挖礦",
    "finance.records.Df9Fd438F97Cc908": "天",
    "finance.records.E23Cb03C5Ad359E5": "期限",
    "finance.records.Bd87Dac6Cba7Eba8": "購買數量",
    "finance.records.2D0Fff99265E8B1A": "已獲利",
    "finance.records.2D0Fff99265E8B1B": "到期獲利",
    "finance.records.024F5F0B851782De": "挖礦時間：",
    "finance.records.3143A26D3F983896": "贖回時間：",
    "finance.records.96574Aaf8Db85Ead": "提前贖回",
    "finance.records.531E1829203181Ac": "您確定要提前贖回嗎;",
    "finance.records.9205843B8923Dac3": "贖回成功;",
    "loan.applyCallback.B9258Ec8746A74D8": "借款頻道",
    "loan.applyForm.7B8Ed340400A6E2B": "名",
    "loan.applyForm.549A44Bae725F4A2": "姓",
    "loan.applyForm.2A27E61375E7Fea8": "出生日期",
    "loan.applyForm.89925B019D537738": "電子郵件地址",
    "loan.applyForm.Afaeb32Cb4D62E28": "家庭住址（含單位編號）",
    "loan.applyForm.E05B5D049B64B040": "城市",
    "loan.applyForm.B166E4E8Fe9513Fa": "國家",
    "loan.applyForm.83Aaede109E395F8": "郵遞區號",
    "loan.applyForm.647Ed21332A4968B": "您的月收入",
    "loan.applyForm.Cf0A9B845D34D4B4": "額外收入（可選）",
    "loan.applyForm.7048F618Acc6A54C": "每月租金或按揭付款",
    "loan.applyForm.15C1Bba18Ae18545": "告訴我們您是否有抵押",
    "loan.applyForm.3921E90625A84E36": "如未選擇抵押擔保，則審核通過機率較低",
    "loan.applyForm.262Eec4F8906B0F6": "是否有抵押",
    "loan.applyForm.8634Eb21B6910F21": "請選擇抵押類型",
    "loan.applyForm.A4897B3A331B5E61": "交易憑證",
    "loan.applyForm.6623384C683De457": "讓我們保持聯繫",
    "loan.applyForm.A8360Bdba86834Cb": "如果我們需要有關您的申請貸款的更多信息，我們可能會透過此號碼與您聯繫",
    "loan.applyForm.C1C373F385A4C7B3": "電話號碼",
    "loan.applyForm.1B9785Badb91191F": "資料填寫完成之後，請等待系統評估審核。",
    "loan.applyForm.8755B9C761D4080C": "我已閱讀並以電子簽名方式同意",
    "loan.applyForm.3E987B787468148C": "使用條款和電子同意書",
    "loan.applyForm.Bc54E76B61C50Fb9": "請閱讀並同意協議",
    "loan.applyForm.89E158384F634Eb8": "返回上一步",
    "loan.applyForm.Efa5A4548E2F3510": "家庭住址",
    "loan.applyForm.4Cadf1787541B35D": "是,",
    "loan.applyForm.Fab408D2F4100447": "否,",
    "loan.applyForm.3Bad7A307480Cc56": "郵箱格式有誤;",
    "loan.applyForm.306322F49B36855D": "已提交申請，請等待客服審核",
    "loan.applyForm.3C6E0E858C995Ff9": "請上傳交易憑證",
    "loan.applyForm.2Bf443476B271376": "最多上傳4張圖片",
    "loan.applyQuota.Ef1D8E23003B9A6F": "借款頻道",
    "loan.applyQuota.Ef1D8E23003B9A6G": "獲取借款額度",
    "loan.applyQuota.9E0780F9352C7273": "錢包餘額",
    "loan.applyQuota.Cbc1Eb9D639A6105": "可藉總額度",
    "loan.applyQuota.033A05462195B080": "已借額度",
    "loan.applyQuota.9Aa89B036Ef4821D": "剩餘額度",
    "loan.applyQuota.5656Fb52Ef17A557": "獲取借款額度",
    "loan.applyQuota.6Ff6702B4C917D40": "審核中",
    "loan.applyQuota.6Ff6702B4C917D41": "已拒絕",
    "loan.applyQuota.6Ff6702B4C917D42": "已成功",
    "loan.applyQuota.0Bad0E857Ea4A952": "",
    "loan.applyQuota.3E7E56E2De2Cc546": "申請時間",
    "loan.applyQuota.8F1D170D9E54Af5B": "申請進度",
    "loan.applyQuota.6167Ca035B93Caf3": "原因",
    "loan.applyQuota.410C7954F9A3981D": "重新申請",
    "loan.bottomSheet.61Cd62F861D8F5B2": "請選擇幣種",
    "loan.index.4C10307B22E885C3": "發現更多交易機會創造財富",
    "loan.index.Ae8D37Dea2B3717B": "利率",
    "loan.index.1Bb54625682E85Db": "規則介紹",
    "loan.index.5Bd31Fab681C6E9D": "看更多",
    "loan.index.111F95F404814327": "借貸幣種",
    "loan.index.6Bc2723B3Bec106D": "借貸",
    "loan.index.3200106280E6E370": "利率",
    "loan.index.049A6Ccb6C81A635": "借貸數量（最小/最大）",
    "loan.productDetails.5037Ad86Ab4B7E02": "借貸",
    "loan.productDetails.37181C74559Db182": "訂單 ",
    "loan.productDetails.5802Aaf06A1E4755": "可流動性",
    "loan.productDetails.89Aea9Cf9337C40F": "可藉數量",
    "loan.productDetails.9E419Ae2D06F3Ad9": "待還數量",
    "loan.productDetails.C65F329Ef14C4A3D": "借幣數量",
    "loan.productDetails.3A74667815Adc8Bf": "最大",
    "loan.productDetails.Ab851D8351573227": "天",
    "loan.productDetails.Ddf82D8A2C8F46A0": "還款週期",
    "loan.productDetails.C105E5562005Aeb1": "天",
    "loan.productDetails.3F346Be2137F0756": "借貸數量（最小/最大）",
    "loan.productDetails.2707F78Df864E194": "利息",
    "loan.productDetails.B9Dc9B81E726Bdf1": "請及時還款，若逾期，每日額外收取（本金+利息）金額中的",
    "loan.productDetails.B9Dc9B81E726Bdf2": "%為逾期費",
    "loan.productDetails.4D94Faa033Be7356": "我已同意",
    "loan.productDetails.62429A957A22780A": "借幣服務協議",
    "loan.productDetails.Bc54E76B61C50Fb9": "請閱讀並同意協議",
    "loan.productDetails.Ec8Ba071C53A67Cb": "立即藉款",
    "loan.productDetails.59246A0Bf081D398": "最少",
    "loan.productDetails.Ec3F1172B71Cd36C": "最小數量",
    "loan.productDetails.7Ccc4Cf1D81930B3": "最大數量",
    "loan.productDetails.071488598990Aa68": "請閱讀並同意協議;",
    "loan.productDetails.579Dd216D3339119": "借貸成功;",
    "loan.records.4951B36Fd0D2A831": "借貸記錄",
    "loan.records.Bb0Aaa30514B7Cba": "目前記錄",
    "loan.records.0F8B0A7D80769051": "還款記錄",
    "loan.records.D4B42E43D88De6F1": "歷史記錄",
    "loan.records.Cb1847D6281897E6": "借款金額",
    "loan.records.C7Bfcacfc2Adea58": "還款金額",
    "loan.records.8B369164D5Fea340": "借貸時間：",
    "loan.records.Ab2402Ad3Ffd35E7": "提前還貸",
    "loan.records.B7A0Ca0C195D0Cd1": "發放時間：",
    "loan.records.42014Bdcfd9C411B": "還款時間：",
    "loan.records.A0Acfaee54F06458": "已完成",
    "loan.records.67A39834582Ad99A": "暫無數據",
    "loan.records.6A687Ff5B8F1598F": "您確定要提前還款嗎",
    "loan.records.7C20D7008D0Eae86": "還款成功",
    "mining.bottomSheet.Ba91637D32Ccba04": "流動性挖礦",
    "mining.bottomSheet.7De3D636A165E008": "殖利率",
    "mining.bottomSheet.F3003345B189D683": "挖礦期限",
    "mining.bottomSheet.3E4C0A8C533F5848": "硬幣類型",
    "mining.bottomSheet.Adcba368993Ae65E": "認購金額（最小/最大）",
    "mining.bottomSheet.94743B24E4Ed4166": "無限制",
    "mining.bottomSheet.286944Fedd211257": "可用資金",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "預計收益:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "立即挖礦",
    "mining.bottomSheet.Bb1493C567C114Dd": "溫馨提示：",
    "mining.bottomSheet.D2315E87E34A3Bd3": "提前贖回手續費",
    "mining.index.8B2Bba1422Eed6F0": "創造百億財富，百分之百純收入",
    "mining.index.42997E300086Ee89": "最高收益",
    "mining.index.510F74F42052Ecce": "挖礦產品",
    "mining.index.B853F96626106912": "去挖礦",
    "mining.productList.37181C74559Db182": "訂單",
    "mining.productList.Aac5B810004C46C8": "定期",
    "mining.productList.D2Fb4F78C387D888": "您已成功挖礦",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "確認訂單",
    "quantify.bottomSheet.6915303B698303B1": "機器人",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "租借金額",
    "quantify.bottomSheet.Fbd931286Be387Aa": "週期",
    "quantify.bottomSheet.9Ad84Db481807844": "無限期",
    "quantify.bottomSheet.994D7226Ca083Ea5": "日收益率",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "起息日",
    "quantify.bottomSheet.88008603Ba7Bde64": "購買成功",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "即日起可獲得收益分成！",
    "quantify.bottomSheet.C778A674C4A054F9": "查看訂單",
    "quantify.bottomSheet.685Dad322B7029B0": "返回交易",
    "quantify.buyForm.Acf8560A04334E95": "購買金額",
    "quantify.buyForm.Cf9Cfbc2C554944F": "概覽",
    "quantify.buyForm.8788Df2B625E97B5": "機器人",
    "quantify.buyForm.50Ca63B45E881D50": "派息時間",
    "quantify.buyForm.Fcda03Adbc5Fca97": "每天",
    "quantify.buyForm.Afb89504Fc399189": "請輸入購買數量",
    "quantify.buyForm.1435Afdfb4Ec0596": "最少購買數量",
    "quantify.buyForm.1B97F1730Bacd839": "最大購買數量",
    "quantify.index.32F56C9B7D0E250A": "週期：無限期",
    "quantify.index.E34F028D00904630": "價格",
    "quantify.index.E34F028D00904631": ",日收益率",
    "quantify.productDetail.6Bbe178Df539A5Ca": "託管金額",
    "quantify.productDetail.53635B53Bca31Dfa": "預計每日收益",
    "quantify.productDetail.F9E46449A6269E09": "累計收益",
    "quantify.productDetail.98Fc46B1B8C993B2": "訂單託管",
    "quantify.productDetail.93624Bb045830966": "立即租用",
    "quantify.records.0C16A18C32B9Cf4A": "訂單記錄",
    "quantify.records.B191F5614B0E2312": "購買記錄",
    "quantify.records.6A06033B86A73F7C": "贖回紀錄",
    "quantify.records.45E0F7E71611618E": "累計收益",
    "quantify.records.45E0F7E71611618F": "到期獲利",
    "quantify.records.Aae1201E38C4Baaa": "運作中",
    "flash.index.C41Fd4F2Fdfa594B": "消耗",
    "flash.index.1Ac59Fa2218E3B00": "可用:",
    "flash.index.Bf7233A660821549": "獲得",
    "flash.index.548E775Dd67C7472": "確定兌換",
    "flash.index.066A2449Bd616F03": "請輸入兌換數量",
    "flash.index.Fca26Fcf9C06563F": "閃兌成功",
    "message.index.40031524233F4564": "訊息中心",
    "message.index.8E5586210Fd44D69": "永續倉位提醒",
    "message.index.Bcd3F470E88C197D": "暫無通知",
    "message.index.23A557Fbce545879": "永續強平通知",
    "message.index.B5E19B36B5125550": "提現提醒",
    "message.index.716A981465138334": "儲值提醒",
    "message.index.884474Ebff2977Ba": "系統訊息",
    "message.index.08Cc7D24752Ec42C": "網路客服",
    "message.index.1D820A6A19Ab025D": "7 X 24小時線上人工服務",
    "more.index.29881E0B5Df58Bf7": "更多功能",
    "more.index.C77Ee35911D10D9D": "常用功能",
    "more.index.3558F69B0260Be61": "交易功能",
    "more.index.7243Fe610Ed98Cec": "真實帳戶",
    "more.index.7243Fe610Ed98Ced": "模擬帳戶",
    "more.index.693C7B35Bdf3E3A3": "金融",
    "more.index.F855997Fac114554": "幫助中心",
    "more.index.Ae9A1252278Da742": "幫助",
    "more.index.Ace012504Bc529E0": "訊息",
    "more.index.05682Dbef5Eeb0A8": "客服",
    "more.index.C7Af227A5D5C7Bb9": "您已切換至實際帳戶",
    "more.index.D818A65592694786": "您已切換至模擬帳戶",
    "notice.details.B360807Dbe727E6E": "關於我們",
    "notice.details.92F2552F695E70E3": "關於我們",
    "notice.details.7Aaceaf69B67F3D1": "規則介紹",
    "notice.index.6128438Be34Bcb9F": "永續通知",
    "notice.index.71627F5Eb7128261": "提現通知",
    "notice.index.2Dce570Fda841705": "儲值通知",
    "notice.index.228A7C9C6448A9B1": "系統通知",
    "setting.dialog.8B4F5B74660Deabe": "色彩配置",
    "setting.dialog.Bfc7D25486Ebc1A9": "綠漲紅跌",
    "setting.dialog.796Ea0E1A82C320B": "紅漲綠跌",
    "setting.index.8Ddc5864E1739466": "偏好設定",
    "setting.index.F5205801D57D6727": "語言",
    "setting.index.2E2C12Dd0Cc1B63B": "計價貨幣",
    "setting.index.322D8496Aec3D918": "版本號",
    "setting.index.Aa7E6C204C751Cfa": "登出登入",
    "share.index.75Bc38Bbf7D2E766": "邀請好友",
    "share.index.45575Eb5D42213E6": "我的邀請碼",
    "share.index.29F67F0644A78Bf3": "複製",
    "share.index.19Cc65Bb6Bb98Fe7": "我的邀請連結",
    "share.index.5372712F162Fb78B": "直屬下級人數",
    "share.index.C88D6B95E3Ab7D6F": "總下級人數",
    "share.index.Bb5Fa5471Aeecc62": "直屬下級返傭",
    "share.index.426B6Bac9A5F3D4F": "總返傭",
    "share.index.197E4A428F0401C9": "查看返傭記錄",
    "share.records.88A6B2E2B74B13C4": "返傭記錄",
    "share.records.B671A35Ffdc93B89": "返傭",
    "transfer.index.B17617A869C03Ca5": "錢包",
    "transfer.index.E4116Dcc6F6C7270": "劃轉成功",
    "transfer.index.48Fb603C6638C7F6": "請設定資金密碼",
    "transfer.records.Eb0E39Eb5911D418": "獲得",
    "transfer.records.977554Be0Aa65052": "閃兌時間：",
    "transfer.records.9Aa33884Aa400B7C": "劃轉時間：",
    "form1.forget.76636Db8Fae5B124": "您的信箱",
    "form1.forget.35354B9Cca1Bb0E6": "信箱驗證碼",
    "form1.forget.604A0B11E916F387": "密碼",
    "form1.forget.9Cb1C9C51E7503D6": "確認密碼",
    "form1.forget.3A4F7E04B008453C": "您的手機號碼",
    "form1.forget.Cf11Dce5B4713B5A": "手機驗證碼",
    "form1.forget.3012Edba57D4F297": "請輸入您的信箱",
    "form1.forget.F1Ee1D97E869E447": "請輸入驗證碼",
    "form1.forget.3A5C4C9Ae68Fa0F6": "請輸入您的密碼",
    "form1.forget.C6Cb174Da411D5F7": "請輸入您的手機",
    "form1.forget.947B3B21Ee45E582": "傳送",
    "form1.forget.3E0580F1E8630Df6": "兩次密碼不一致",
    "form1.forget.C5A61836B8Cc74C6": "密碼修改成功跳轉中...",
    "form1.forget.B96D072Bc8962565": "重新發送",
    "form1.login.2B72C27Ff828Cfc4": "您的密碼",
    "form1.login.D7C6B872Af9Cb17F": "記住我的密碼",
    "form1.login.0Fff44C827A4F3B6": "登入",
    "form1.login.1B7F2C1Ede3990Cc": "還沒帳號？ 立即註冊",
    "form1.login.F657F7Be1Cc33Bd2": "忘記密碼",
    "form1.login.2074A1Eec4B088B0": "錢包登入",
    "form1.login.1B8938Fbcc3C9B56": "登入成功跳轉中...",
    "form1.login.07162A7Bb9310555": "請安裝ERC20錢包",
    "form1.login.766969966Ae151Dc": "請安裝TRC20錢包",
    "form1.login.B88171E1Ad4C50Bf": "您拒絕了切換網絡",
    "form1.register.Aaef0710Cb4Db554": "再次輸入密碼",
    "form1.register.54Dfea0415C708Ff": "邀請碼",
    "form1.register.6839940Dac08C794": "註冊",
    "form1.register.9901A3B5550Aa58E": "已有帳號？ 登入",
    "form1.register.2548Ca53785252Ea": "請輸入您的邀請碼",
    "form1.register.Dd71Fd65900Dd03E": "請閱讀註冊服務協議並同意,",
    "form1.register.7975613909490B77": "註冊成功跳轉中...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "選擇錢包",
    "userc.certification.2Af9511640153D2E": "初級認證",
    "userc.certification.177B191D8E0Fa84D": "未認證",
    "userc.certification.601218D4D572E98A": "已認證",
    "userc.certification.6E37C51Ba9012Fc8": "未通過",
    "userc.certification.1B9B563E05C96474": "高級認證",
    "userc.certification.1418B83803Ab56B4": "請先完成初級認證",
    "userc.creditScore.0B149112601Acc4D": "信用分",
    "userc.creditScore.88Ac7F84E106C5F6": "信用總分",
    "userc.creditScore.3E1D6E0E7F1E6155": "系統評估",
    "userc.index.62Eac58D04Bbca0F": "個人中心",
    "userc.index.F4Cac53F93Cb2Aff": "模擬交易",
    "userc.index.42F409Fdf3559773": "安全中心",
    "userc.index.1B8E211009807Db8": "身份認證",
    "userc.index.Bdfcf21Eb8F2Eb70": "帳變紀錄",
    "userc.index.6C18D67F6D19586B": "平台介紹",
    "userc.index.807A814Ed58A93Bc": "清除快取",
    "userc.index.E43Daad59759611C": "未認證",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "更改登入密碼",
    "userc.secureCenter.1F37Ba427Debf24A": "帳戶綁定",
    "userc.secureCenter.F9A5Ffa750664004": "綁定谷歌驗證器",
    "userb.certificationForm.7685Af7D30043Cd6": "初級認證",
    "userb.certificationForm.7685Af7D30043Cd7": "高級認證",
    "userb.certificationForm.70E37C35Abc3F462": "所在國家",
    "userb.certificationForm.F92C25A68E4D08C5": "所在城市",
    "userb.certificationForm.0Ccc6387B38E0318": "您的姓名",
    "userb.certificationForm.94C67Bba370Fdd46": "證件編號",
    "userb.certificationForm.B2D4C7Fffe79258E": "信箱",
    "userb.certificationForm.57554268761Fb47A": "上傳您的證件正面",
    "userb.certificationForm.D79E90201F5319Bd": "上傳您的證件背面",
    "userb.certificationForm.9C6Bad08Af29E20E": "上傳您的手持證件圖片",
    "userb.certificationForm.F96B62Dfa31Cde45": "確定提交",
    "userb.certificationForm.2125D78Ea8C6D287": "上傳要求",
    "userb.certificationForm.0D64864D3076A824": "查看範例",
    "userb.certificationForm.35287Deb72281E87": "1.必須能看清身分證訊息。",
    "userb.certificationForm.3007F2E01Efc7B57": "2.照片內容真實有效，不得做任何修改。",
    "userb.certificationForm.25A90660554319F9": "3.必須能看清證件號碼和姓名，支援 JPG / JPEG / PNG",
    "userb.certificationForm.5A9Aefbc03C778F7": "請輸入",
    "userb.certificationForm.3B7A1D8Ce27C937E": "提交成功，請等待審核",
    "userb.certificationForm.744Bd07D8Abd2513": "請上傳您的證件正面",
    "userb.certificationForm.6De1C319130F7Fef": "請上傳您的證件背面",
    "userb.certificationForm.79F8E8B172E30C8A": "請上傳您的手持證件照片",
    "userb.certificationForm.46412E16D28A4753": "照片上傳失敗",
    "userb.changeDialog.676B0Adca1F8Eea4": "更改密碼",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "手機號碼",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "驗證器開關",
    "userb.modifyInfoDialog.62149E566814154C": "選擇驗證器",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "開啟身份驗證應用，然後點擊+",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": "選擇'掃描二維碼'或'輸入密鑰'",
    "userb.modifyInfoDialog.93F094E2B4369472": "掃描二維碼或輸入金鑰",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "關閉/開啟",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "舊資金密碼",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "新資金密碼",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "確認新資金密碼",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "確認資金密碼",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "請輸入您的舊資金密碼",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "請輸入您的新資金密碼",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "請輸入您的資金密碼",
    "userb.modifyInfoDialog.258A534498Fe984F": "請確認您的資金密碼",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "帳戶綁定",
    "userb.modifyInfoDialog.D34746090C25E5Db": "資金密碼",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "確認",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "驗證器已關閉",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "谷歌驗證開啟成功",
    "userb.modifyInfoDialog.24Dba26593F43C74": "修改資金密碼成功",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "設定資金密碼成功",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "綁定成功",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "驗證碼錯誤",
    "userb.veBottomSheet.Eecc4683C725F6F2": "證件上傳範例",
    "userb.veBottomSheet.6F38A0D5858633Ee": "深色乾淨的背景",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "光線充足",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "對準輔助框",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "照片清晰",
    "userb.veBottomSheet.50B741382E71F103": "可能失敗的原因",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "身分證保護套未取下",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "背景有雜物影響",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "身分證有反光",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "光線過暗/被手機陰影遮擋",
    "userb.veBottomSheet.1A75D2Fc2614592B": "手持身分證",
    "home.home.C3525Eb110Db58Aa": "更多",
    "home.home.29761532Fc8Ebecc": "交易",
    "home.home.F15Ea99Bfa6E1848": "交易安全認證，放心購U",
    "home.home.46E1Ab39Bae7182A": "快速",
    "home.home.Fde2Eb7F6F3Dd800": "快速交易，操作簡單",
    "home.home.Ea4756Bc1642E0F1": "名稱",
    "home.home.B6B1127Ede1C97B4": "最新價格",
    "home.home.3587Ad870Eb0Ab86": "24H漲跌幅",
    "home.home.Cb1A4E14Ec2Cd2B5": "發現",
    "home.home.0F1B1Ac0E96A40D1": "區塊鏈",
    "home.home.8Eaefb1107A0E210": "外匯",
    "home.home.Ad65263D034Ea051": "貴金屬",
    "home.home.5Fc1474Dbf74Ac1A": "農產品",
    "home.home.135318F4664F7773": "能源",
    "kline.kLine.C96Ff250Cf6B8B84": "24h最高價",
    "kline.kLine.6E8Be64F19D74565": "24h成交量",
    "kline.kLine.08A4Bfbe26A0F262": "24h最低價",
    "kline.kLine.8701Deb075066331": "24h成交額(USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1分",
    "kline.kLine.B49Cca240E013124": "5分",
    "kline.kLine.F27A90Be97Ba3D6C": "15分",
    "kline.kLine.Ff3E62395B694384": "30分",
    "kline.kLine.C1993F9C6Db1A674": "1时",
    "kline.kLine.Fd2Cbbae405E284C": "1天",
    "kline.kLine.C9Cd71904395042C": "1月",
    "kline.kLine.7D8127Ec18C7B8C7": "買盤",
    "kline.kLine.E3D3Ffdb9B3E5636": "數量（張）",
    "kline.kLine.6834Fec1838D8029": "價格（USDT）",
    "kline.kLine.16C52Aae474Bc9Cd": "賣盤",
    "kline.kLine.B9Bea1A9A88D1419": "開多",
    "kline.kLine.D36Cfa96Ee2B343B": "開空",
    "other.languageRateSelect.6897784800834C1B": "設定語言",
    "other.languageRateSelect.6897784800834C1C": "請選擇計價貨幣",
    "other.navigationBar.F6E62F9642018Cbb": "首頁",
    "other.navigationBar.0Ec227161A47B49D": "行情",
    "other.navigationBar.29761532Fc8Ebecc": "交易",
    "other.navigationBar.693C7B35Bdf3E3A3": "金融",
    "other.navigationBar.9734C54620Eb09Af": "資產"
}
