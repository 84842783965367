export default {
    getIsLogin: (state) => state.isLogin,
    getConfigData: (state) => state.configData,
    getBaseQuotes: (state) => state.baseQuotes,
    getUpdateQuotes: (state) => state.updateQuotes,
    getInnerHeight: (state) => state.innerHeight,
    getSymbolList: (state) => state.symbolList,
    getIsShowBalance: (state) => state.showBalance,
    getInvestData: (state) => state.investData,
    getUserInfo: (state) => state.userInfo,
    getLanguage: (state) => state.language,
    getRateList: (state) => state.rateList,
    getCurrentRate: (state) => state.currentRate,
    getAuthInfo: (state) => state.authInfo,
    getThemeType: (state) => state.themeType,
    getSocket: (state) => state.socket,
    getQuoteData: (state) => state.quoteData,
    getLanguageList: (state) => state.languageList,
    getContractQuote: (state) => state.contractQuote,
    getMiningData: (state) => state.miningData,
    getFunds: (state) => state.funds,
    getLoanList: (state) => state.loanList,
    getDeliveryQuote: (state) => state.deliveryQuote,
    getSelectFiatCoin: (state) => state.selectFiatCoin,
    getSocketLastTime: (state) => state.socketLastTime,
    getFormFiles: (state) => state.formFiles,
    getFormImgs: (state) => state.formImgs
}