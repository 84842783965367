<template>
  <v-dialog
    class="flash-alert"
    v-model="dialog"
    @click:outside="clickOutside"
    width="260"
  >
    <v-card class="pa-4 alert-box text-center">
      <div class="close d-flex align-center">
        <p class="mb-0 close-time" v-if="showSec">{{ time }}S</p>
        <v-icon small @click="close">mdi-close</v-icon>
      </div>
      <div class="mt-4">
        <v-icon x-large color="bg_primary" v-if="type == 'success'"
          >mdi-check-decagram</v-icon
        >
        <v-icon x-large color="bg_primary" v-else>mdi-alert-circle</v-icon>
        <p class="mt-4 state-text">{{ text }}</p>
      </div>
    </v-card>
  </v-dialog>
</template>
  
  <script>
export default {
  data() {
    return {
      dialog: false,
      time: 3,
      timer: null,
    };
  },
  watch: {
    value(v) {
      this.dialog = v;
      if (v) {
        this.rtime();
      }
    },
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    text: {
      type: String,
      default: () => "",
    },
    type: {
      type: String,
      default: () => "success",
    },
    showSec: {
      type: Boolean,
      default: () => true,
    },
  },
  methods: {
    clickOutside() {
      this.close();
    },
    close() {
      this.$emit("update:value", false);
      this.time = 3;
      this.timer && clearInterval(this.timer);
      this.timer = null;
    },
    rtime() {
      this.timer = setInterval(() => {
        if (this.time <= 1) {
          clearInterval(this.timer);
          this.timer = null;
          this.close();
          setTimeout(() => {
            this.time = 3;
          }, 100);
        } else {
          this.time -= 1;
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.flash-alert {
  position: relative;
}
.alert-box {
  border-radius: 21px 21px 21px 21px;
}
.state-text {
  font-size: 1rem;
  font-weight: 600;
}
.close {
  position: absolute;
  right: 16px;
  top: 10px;
  .close-time {
    font-size: 0.75rem;
    margin-right: 4px;
  }
}
</style>