<template>
  <v-app class="light">
    <v-main>
      <div class="progress-box d-flex align-center pa-4" v-if="loadVal < 100">
        <div class="progress-content">
          <v-progress-linear
            color="light-green darken-4"
            height="10"
            :value="loadVal"
            striped
          ></v-progress-linear>
          <p class="loading-text text-center mt-2">Loading...</p>
        </div>
      </div>
      <router-view v-else />
    </v-main>
    <NavigationBar v-if="loadVal == 100 && showBar" />
    <Dialog
      :value.sync="showDialog"
      title=" "
      :text="dialogText"
      confirm-text=""
      :close-text="$vuetify.lang.t('$vuetify.public.confirm')"
    />
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getCookie, setCookie } from "./utils/cookie";
import Socket from "@/servers/_websocket.js";
import NavigationBar from "@/components/NavigationBar.vue";
import axios from "axios";
import Dialog from "./components/common/Dialog.vue";
export default {
  name: "App",
  components: {
    NavigationBar,
    Dialog,
  },

  data() {
    return {
      loadVal: 20,
      initServe: null,
      timer: null,
      socket: null,
      showDialog: false,
      dialogText: "",
    };
  },
  computed: {
    ...mapGetters(["getThemeType"]),
    showBar() {
      let url = [
        "/",
        "/market",
        "/assets",
        "/finance",
        "/contract",
        "/delivery",
      ];
      return url.indexOf(this.$route.path) > -1;
    },
  },
  mounted() {
    document.getElementById("app").style.height = window.innerHeight + "px";
    this.setInnerHeight(window.innerHeight);
    document.documentElement.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      false
    );

    var lastTouchEnd = 0;
    document.documentElement.addEventListener(
      "touchend",
      function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      false
    );

    document.addEventListener("gesturestart", function (event) {
      event.preventDefault();
    });
  },
  created() {
    this.initConfig();
    this.getConfig();
    this.getLanguagesList();
    this.initSocket();
    this.getFundsOverview();
    // if (getCookie("user-cookie")) {

    // }
    this.getFundsUSDTLegalTender();
    // this.getSymbolListFuc();
    // this.getSecondProduct();
    // this.$vuetify.lang.current = "en";
  },
  methods: {
    ...mapActions([
      "configFetch",
      "symbolListFetch",
      "userInfoFetch",
      "languageFetch",
      "fundsUSDTLegalTenderFetch",
      "fundsOverviewFetch",
    ]),
    ...mapMutations([
      "setConfigData",
      "setBaseQuotes",
      "setUpdateQuotes",
      "setInnerHeight",
      "setSymbolList",
      "setLanguage",
      "setCurrentRate",
      "setUserInfo",
      "setIsLogin",
      "setThemeType",
      "setSocket",
      "setQuoteData",
      "setLanguageList",
      "setContractQuote",
      "setDeliveryQuote",
      "setFunds",
    ]),

    initSocket() {
      this.socket = new Socket(this);
      this.setSocket(this.socket);
    },
    initConfig() {
      let lang = localStorage.getItem("lang") || "English";
      let rate = localStorage.getItem("rate");
      if (rate) {
        rate = JSON.parse(rate);
        this.setCurrentRate(rate);
      } else {
        this.setCurrentRate({
          code: "USD",
          name: "United States dollar",
          price: 1,
          symbol: "$",
        });
      }
      let accountType = localStorage.getItem("account-type") || "real";
      setCookie("account-mode", accountType == "real" ? false : true);
      this.setLanguage(lang);
      setCookie("lang", lang);
      this.reloadLangs();
    },

    async getFundsOverview() {
      let res = await this.fundsOverviewFetch();
      if (res.errCode == 0) {
        this.setIsLogin(true);
        this.setFunds(res.data);
        this.getUserCertification();
      }
    },
    async getFundsUSDTLegalTender() {
      await this.fundsUSDTLegalTenderFetch();
    },

    async getLanguagesList() {
      let res = await this.languageFetch();
      if (res.errCode == 0) {
        this.setLanguageList(res.data);
      }
    },
    async getSymbolListFuc() {
      let res = await this.symbolListFetch();
      if (res.errCode == 0) {
        this.setSymbolList(res.data);
      }
    },

    async getConfig() {
      setTimeout(() => {
        this.loadVal = 70;
      }, 200);
      let res = await this.configFetch();
      if (res.errCode == 0) {
        if (res.data.ipUrl) {
          axios
            .get(res.data.ipUrl)
            .then((r) => {})
            .catch((err) => {
              console.log("error", err);
            });
        }
        this.setConfigData(res.data);
        setTimeout(() => {
          this.loadVal = 98;
        }, 200);
        setTimeout(() => {
          this.loadVal = 100;
        }, 400);
        if (this.$route.path == "/" && res.data.globalNotifyV1) {
          this.dialogText = res.data.globalNotifyV1;
          this.showDialog = true;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  font-family: "PingFang"; // 这里的 DigitalThick 是引入时的自定义名字
  overflow-y: auto;
  background: var(--v-primary1-base);
  //   background: #fff;
  ::v-deep .v-application--wrap {
    height: 100%;
  }
  ::v-deep .v-main {
    height: 100%;
  }
  ::v-deep .v-main__wrap {
    height: 100%;
  }
  .progress-box {
    height: 100vh;
    .progress-content {
      width: 100%;
      .loading-text {
        font-size: 18px;
      }
    }
  }
}
</style>
