import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "الصينية المبسطة",
        dialogDetermine: "بالتأكيد",
        dreivatives: "توصيل",
        markets: "يقتبس",
        assets: "أصول",
        liquidity: "تعدين السيولة",
        helpCentre: "مركز المساعدة",
        announcementCenter: "مركز اعلانات",
        about: "عن",
        bulletinCentre: "مركز اعلانات",
        serviceAgreement: "اتفاقية خدمات",
        support: "يدعم",
        onlineSupportCustomerService: "خدمة الإنترنت",
        suggestionsAndFeedback: "الاقتراحات والملاحظات",
        service: "يخدم",
        download: "تحميل",
        coinApplication: "تطبيق قائمة العملة",
        privacyStatement: "بيان الخصوصية",
        trade: "تداول العقود",
        standardConteact: "مستمر",
        signOut: "خروج",
        assetWallets: "محفظة الأصول",
        financeRecords: "سجلات تغيير الحساب",
        identification: "المصادقة",
        personalCenter: "مركز شخصي",
        chooseLanguage: "اختر لغة",
        tips: "تَلمِيح",
        dark: "الوضع المظلم",
        light: "وضع اليوم",
        languageTitle: "لغة",
        convertCurrency: "تحويل العملة",
        cancel: "يلغي",
        aboutUs: "معلومات عنا",
        standard: "استمرارية المعيار",
        share: "يشارك",
        introduce: "مقدمة المنصة",
        set: "يثبت",
        changePassword: "تغيير كلمة المرور لتسجيل الدخول",
        denomination: "عملة الاقتباس",
        color: "تكوين اللون",
        code: "رقم الإصدار",
        color1: "يرتفع الأحمر ويسقط الأخضر",
        color2: "صعود أخضر وسقوط أحمر",
        setLangue: "إعدادات اللغة",
        vip: "فوائد كبار الشخصيات",
        vip2: "مستوى VIP الحالي",
        safeMenu: "مركز أمني",
        conventional: "عادي",
        other: "آخر",
        quickTips: "أضف ما يصل إلى 3 أزرار وظائف الاختصار",
        quickTransaction: "معاملة سريعة",
        safeConvenient: "آمنة ومريحة",
        timeSharing: "وقت المشاركة",
        currentLevel: "المستوى الحالي",
        levelBenefits: "%{المستوى} مستوى الدخل",
        teamLevel: "مستوى الفريق",
        levelLower: "%{المستوى} تابع",
        rules: "وصف القاعدة",
        bindWithdrawalAddress: "ربط عنوان السحب",
        tipsBindWithdrawalAddress: "لم تقم بربط عنوان السحب الخاص بك بعد، يرجى ربطه أولاً",
        walletAddressFormatIncorrect: "تنسيق عنوان المحفظة غير صحيح",
        tradeRules1: "توقع الارتفاع والانخفاض في الفترة المقبلة. السعر وقت الأمر يؤخذ كسعر البداية، والسعر في الثانية الأخيرة من فترة التنبؤ يؤخذ كسعر التسوية، إذا كان سعر التسوية أكبر من سعر البداية، فهذا يعني زيادة. إذا سعر التسوية أقل من سعر البداية، فهذا يعني انخفاضا.。",
        tradeRules2: "1.ما هو العقد",
        tradeRules3: "توفر هذه المنصة عقودًا دائمة تعتمد على USDT، ويمكن للمستخدمين الحكم على الارتفاع أو الانخفاض واختيار شراء عقود طويلة أو بيع عقود قصيرة للحصول على أرباح من ارتفاع/انخفاض أسعار الأصول الرقمية. العقود الدائمة ليس لها تاريخ تسليم ويمكن للمستخدمين الاحتفاظ بها طوال الوقت.。",
        tradeRules4: "2.ما هو الهامش",
        tradeRules5: "في سوق العقود الافتراضية، يحتاج المستخدمون فقط إلى دفع مبلغ صغير من الأموال بنسبة معينة وفقًا لسعر العقد كضمان مالي لتنفيذ العقد، ومن ثم يمكنهم المشاركة في شراء وبيع العقد. هذا النوع من الأموال هو إيداع العقد الافتراضي. \nتعتمد العقود على هذا النظام الأساسي نموذج الهامش المنفصل للحسابات المنفصلة. تتوافق كل عملة مع حساب عقد، أصول الحساب ومراكز عملات العقد المختلفة مستقلة عن بعضها البعض، ولا تؤثر التحويلات والمعاملات بين حسابات العقود المختلفة على بعضها البعض. بعد فتح حساب العقد الخاص بالمستخدم، سيتم حساب المخاطر والفوائد لجميع المراكز الموجودة في حساب العقد معًا.。",
        tradeRules6: "3.كيفية حساب الهامش",
        tradeRules7: "هامش المركز = القيمة الاسمية للعقد * عدد العقود المفتوحة / مضاعف الرافعة المالية\nالهامش المجمد = مجموع هوامش جميع الأوامر الموضوعة بنجاح والأوامر المفتوحة في الطلب الحالي\nالهامش المتاح = رصيد الحساب - هامش المركز - الهامش المجمد",
        tradeRules8: "4.كيفية حساب معدل الهامش",
        tradeRules9: "معدل الهامش هو مقياس لمخاطر أصول المستخدم. \nمعدل الهامش = (الأرباح والخسائر غير المحققة + هامش المركز + الهامش المتاح + هامش التجميد - رسوم التصفية) / هامش المركز \nكلما كان معدل الهامش أصغر، زادت مخاطر الحساب. عندما يكون معدل الهامش أقل من أو يساوي 0%، سيتم تفعيل التصفية القسرية.。",
        tradeRules10: "5.ما هو سعر التكافؤ القسري المقدر؟",
        tradeRules11: "سعر التصفية القسرية المقدر، أي السعر المحسوب بواسطة النظام الذي يؤدي نظريًا إلى التصفية القسرية، هو للإشارة فقط وقد ينحرف قليلاً عن القيمة الفعلية.。",
        tradeRules12: "6.ما هو إجمالي حقوق الملكية في الحساب والأرباح والخسائر غير المحققة؟",
        tradeRules13: "إجمالي رصيد الحساب = رصيد الحساب + الأرباح والخسائر غير المحققة\nالأرباح والخسائر غير المحققة هي أرباح وخسائر المركز الذي يحتفظ به المستخدم حاليًا في عقد العملة. سوف تتغير الأرباح والخسائر غير المحققة مع تغيرات أسعار المعاملة الأخيرة. \nالأرباح والخسائر غير المحققة لمراكز الشراء = (1/متوسط ​​سعر المركز - 1/سعر آخر صفقة) * عدد العقود لمراكز الشراء * القيمة الاسمية للعقد * سعر آخر صفقة \n الأرباح والخسائر غير المحققة لمراكز البيع القصيرة = (1/ أحدث سعر للمعاملة - 1/ متوسط ​​سعر المركز) * عدد العقود القصيرة * القيمة الاسمية للعقد * أحدث سعر للمعاملة",
        text1: "أمن الحساب",
        text2: "وضع النهار والليل",
        text3: "مسح ذاكرة التخزين المؤقت",
        text4: "هل أنت متأكد أنك تريد تسجيل الخروج؟？",
        text5: "هل أنت متأكد أنك تريد مسح ذاكرة التخزين المؤقت؟？",
        text6: "متعدد اللغات",
        text7: "لحماية أمانك، نوصي بتشغيل مصادقة ثنائية واحدة على الأقل"
    },
    login: {
        login: "تسجيل الدخول",
        register: "يسجل",
        title1: "تداول في أي وقت وفي أي مكان واطلع على ظروف السوق في الوقت الفعلي",
        title2: "تداول في أي وقت وفي أي مكان واطلع على ظروف السوق في الوقت الفعلي",
        title3: "منصة تداول العملات المشفرة الأكثر ثقة",
        formTitle1: "تسجل الدخول",
        formTitle2: "مرحبًا بعودتك! تسجيل الدخول باستخدام البريد الإلكتروني الخاص بك، رقم الهاتف",
        btn1: "الهاتف الخلوي",
        btn2: "بريد",
        btn3: "تسجيل الدخول",
        btn4: "يسجل",
        btn5: "ننسى كلمة المرور？",
        field1: "رقم التليفون",
        field2: "كلمة المرور",
        field3: "بريد",
        placeholder1: "أمة",
        placeholder2: "الرجاء إدخال رقم الهاتف",
        placeholder3: "الرجاء إدخال كلمة المرور",
        placeholder4: "يرجى إدخال البريد الإلكتروني الخاص بك",
        content12: "الرجاء إدخال رقم الهاتف",
        content13: "الرجاء إدخال كلمة المرور",
        content14: "يرجى إدخال البريد الإلكتروني الخاص بك",
        content16: "الخدمات المالية للأصول الرقمية",
        loginSuccess: "تم تسجيل الدخول بنجاح ",
        chooseArea: "الرجاء تحديد رمز المنطقة",
        placeholder5: "الرجاء تحديد رمز البلد",
        placeholder6: "تذكر كلمة المرور الخاصة بي",
        placeholder7: "تحميل التطبيق",
        placeholder8: "اتصل بخدمة العملاء",
        placeholder9: "اختر البلد أو المنطقة",
        placeholder10: "رمز المنطقة",
        placeholder11: "تسجيل الدخول إلى المحفظة",
        placeholder12: "فشلت العملية، يرجى التبديل إلى",
        placeholder13: "محفظة"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "عن",
        aboutCoinbeadCenterDetails: "لديها منصة تداول أصول blockchain الرائدة في العالم وتدير النظام البيئي بأكمله.。",
        footDetails1: "حجم التداول اليومي",
        footDetails2: "المعاملات/الثانية",
        footDetails3: "مركز المساعدة",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "هو موقع عالمي مشهور عالميًا للأصول الرقمية، يقدم بشكل أساسي العملات ومشتقات الأصول الرقمية مثل Bitcoin (BTC)، وLitecoin (LTC)، وEthereum (ETH) للمستخدمين العالميين.",
        coinbeadCenterDetailsm: "من خلال اعتماد تقنيات متقدمة مثل GSLB، وخوادم الشبكة، ومعاملات الشبكة، والشبكات، ومحركات تداول الذاكرة عالية السرعة متعددة الأجهزة، والمحافظ الباردة، والمحافظ الساخنة الذكية غير المتصلة بالإنترنت، وما إلى ذلك، فإننا نخدم عملائنا بمحطات طرفية متعددة مثل هواتف الويب المحمولة وأجهزة الكمبيوتر توفير خدمات معاملات التعرفة الرقمية الآمنة والمستقرة والموثوقة. وفي الوقت نفسه، نقوم بتحسين وترقية المنتجات والخدمات بناءً على اقتراحات المستخدمين واحتياجاتهم لتقديم خدمات أفضل لكل عميل، وليس هناك نهاية لتحسين تجربة المستخدم المبتكرة.。",
        infrastructureCenterTitle: "بنية تحتية",
        infrastructureCenterDetails: "مهمتنا هي توفير خدمات البنية التحتية للنظام البيئي blockchain بأكمله",
        freeCenterTitle: "حر",
        freeCenterDetails: "رؤيتنا هي جعل الأموال تتدفق بحرية حول العالم. نحن نؤمن بأن نشر هذه الحرية يمكن أن يجعل العالم مكانًا أفضل",
        futureCenterTitle: "الريادة في مستقبل التكنولوجيا",
        futureCenterDetails: "افتح مستقبل التكنولوجيا",
    },
    applicationForListing: {
        title: "تطبيق قائمة العملة",
        projectName: "اسم المشروع",
        tokenName: "اسم الرمز المميز",
        projectDescription: "وصف المشروع",
        contractAddress: "عنوان العقد",
        officialWebsite: "الموقع الرسمي",
        ContactAndEmail: "تواصل بالبريد الاكتروني",
        ContactAndName: "اسم جهة الاتصال",
        file: "تحميل المرفق",
        pleaseEnter: "تفضل",
        enterFile: "يرجى تحميل الملفات",
        upload1: "يقتصر حاليًا على اختيار ملف واحد，",
        upload2: "هذه المرة اخترت",
        upload3: "ملفات，",
        upload4: "الإجمالي المحدد",
        upload5: "ملفات",
    },
    public: {
        submit: "يُقدِّم",
        copySuccessfully: "تم النسخ بنجاح",
        copyFailed: "فشل النسخ",
        noDate: "لايوجد بيانات",
        submitSuccessfully: "تم الإرسال بنجاح",
        goBack: "العودة إلى الصفحة الأخيرة",
        cancel: "يلغي",
        home: "الصفحة الأمامية",
        loading: "تحميل...",
        confirm: "يتأكد",
        loadingFailed: "شذوذ الشبكة",
        nomore: "لا أكثر",
        tryAgain: "يرجى تحديث الصفحة/الخروج من البرنامج والتحميل مرة أخرى!",
        hot: "المناطق الشعبية",
    },
    inviteFriends: {
        inviteFriends: "شارك مع الاصدقاء",
        freedomOfWealth: "دع الثروة تكون حرة معًا",
        myInvitationCode: "رمز الدعوة الخاص بي",
        clickCopy: "انقر للنسخ",
        myInvitationLink: "رابط دعوتي",
        clicktoSaveTheQRCode: "انقر لحفظ رمز الاستجابة السريعة",
        saveQRCode: "دعوة رمز الاستجابة السريعة",
        numberOfSubordinates: "عدد من المرؤوسين",
        rebateIncome: "دخل الخصم",
        people: "الناس",
        myPromotionRevenue: "دخلي الترويجي",
        numberOfDirectSubordinates: "عدد المرؤوسين المباشرين",
        totalNumberOfSubordinates: "إجمالي عدد المرؤوسين",
        directSubordinateReturnsCommission: "خصم العمولة للمرؤوسين المباشرين",
        theTotalCommission: "الخصم الإجمالي",
        checkHistory: "عرض سجلات الخصم",
        history: "سجل اللجنة",
        theLowerAccount: "حساب تابع",
        type: "يكتب：",
        serviceCharge: "رسوم المعالجة：",
        percentageofCommissionReturned: "نسبة الخصم：",
        commissionrebateAmount: "مبلغ الخصم：",
        time: "وقت：",
        typeName1: "إعادة الشحن والعمولة",
        typeName2: "رسوم مناولة أوامر MT على ثلاثة مستويات",
        typeName3: "توزيع MT المربح على ثلاثة مستويات",
        typeName4: "مكافآت الإحالة",
        typeName30: "إعادة الشحن والعمولة",
        typeName31: "خصم أرباح التداول الدوري",
        typeName32: "خصم أرباح تعدين السيولة",
        typeName45: "خصم رسوم المعاملات",
        typeName46: "خصم العمولة على رسوم المناولة الخاصة",
        typeName50: "خصم على رسوم التعامل مع عقد التسليم",
        typeName51: "خصم دائم على رسوم التعامل مع العقود",
        typeName52: "خصم إضافي على العمولة مقابل رسوم التعامل مع عقد التسليم",
        typeName53: "خصم إضافي على العمولة مقابل رسوم التعامل مع العقود الدائمة",
        typeName54: "حسومات إضافية على رسوم التعامل مع العقود الدائمة",
        shareRules1: "تعليمات الدعوة",
        shareRules2: "أدخل إلى مركز الدعوة وانسخ رابط أو رمز الدعوة وشاركه مع أصدقائك. يمكن للأصدقاء أن يصبحوا مرؤوسين لك عن طريق التسجيل باستخدام رمز الدعوة الخاص بك。",
        shareRules3: "كسب الحسومات",
        shareRules4: 'عندما يقوم المرؤوسون بإجراء المعاملات، يمكنك الحصول على العمولات المقابلة، ويتم دعم ما يصل إلى ثلاثة مستويات من المرؤوسين. على سبيل المثال، إذا قمت بدعوة الصديق "أ"، و"أ" يدعو "ب"، و"ب" يدعو "ج"، فسيقوم "أ" و"ب" و"ج" بإجراء العقود والمعاملات الأخرى على المنصة، ويمكنك جميعًا الحصول على العمولات المقابلة.。',
        shareRules5: "مستوى الفريق",
        shareRules6: 'كلما زاد عدد المرؤوسين من المستوى الأول الذين قمت بترقيتهم، ارتفع مستوى الفريق، وزاد الحسم الذي يمكنك الاستمتاع به. يتم تقسيم مستويات الفريق إلى LV1 - LV6. تظهر قواعد الترقية في الجدول أدناه، حيث يشير "N" إلى عدد مرؤوسي المستوى الأول الذين قاموا بإعادة الشحن وإكمال مصادقة الاسم الحقيقي.。',
        shareRules7: "عندما يراهن المرؤوسون على عقود التسليم، يمكنك الحصول على عمولات تتوافق مع رهاناتهم.。",
        upgradeConditions: "يتطلب",
        recommendedNumberOfPeople: "العدد الموصى به من الناس"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "الاقتراحات والملاحظات",
        text: "إذا واجهت أخطاء أو كانت لديك اقتراحات بشأن المنتج أثناء الاستخدام، فيرجى تزويدنا بتعليقاتك.",
        email: "عنوان البريد الإلكتروني",
        enterEmail: "الرجاء إدخال عنوان البريد الإلكتروني",
        enterCorrectEmail: "الرجاء إدخال عنوان البريد الإلكتروني الصحيح",
        title: "عنوان",
        enterTitle: "الرجاء إدخال عنوان السؤال",
        describeTheProblem: "وصف المشكلة",
        enterDescribeTheProblem: "الرجاء إدخال المشكلة التي تصفها",
        submit: "يُقدِّم",
    },
    help: {
        enterSearch: "يبحث",
        title: "مركز المساعدة",
        search: "الرجاء إدخال سؤالك",
        text1: "افهم احتياجاتك واستمر في تقديم خدمات أكثر ملاءمة لك"
    },
    announcement: {
        enterSearch: "يبحث",
        title: "مركز اعلانات",
        search: "الرجاء إدخال سؤالك"
    },
    privacy: {
        title: "بيان الخصوصية",
        content1: "1. ما هي المعلومات الشخصية للمستخدمين التي يتم جمعها？",
        content2: "يتم جمع المعلومات الشخصية لتوفير المنتجات والخدمات وتحسينها بشكل مستمر。",
        content3: "فيما يلي أنواع المعلومات الشخصية التي نجمعها：",
        content4: 'المعلومات التي تقدمها لنا: نتلقى ونخزن أي معلومات تقدمها فيما يتعلق بالمنصة. يمكنك اختيار عدم تقديم معلومات معينة، ولكن قد لا تتمكن من استخدام بعض "خدمات هذا النظام الأساسي" نتيجة لذلك. تتضمن أمثلة المعلومات التي تقدمها لنا：',
        content5: "اسم",
        content6: "عنوان",
        content7: ".بلد المواطنة",
        content8: "صور الهوية",
        content9: "رقم الهاتف المحمول/رقم البريد الإلكتروني",
        content10: "معلومات أخرى تساعدنا في التعرف عليك",
        content11: "المعلومات المجمعة تلقائيًا: نقوم تلقائيًا بجمع وتخزين أنواع معينة من المعلومات حول استخدامك لخدمات النظام الأساسي، مثل العديد من مواقع الويب، نستخدم ملفات تعريف الارتباط والمعرفات الفريدة الأخرى. نحصل على أنواع معينة من المعلومات عندما يصل متصفح الويب الخاص بك أو جهازك إلى النظام الأساسي. تتضمن أمثلة المعلومات التي نجمعها ونحللها：",
        content12: "عنوان بروتوكول الإنترنت (IP) الذي يربط جهاز الكمبيوتر الشخصي بالإنترنت；",
        content13: "معلومات تسجيل الدخول للجهاز الشخصي أو الكمبيوتر وعنوان البريد الإلكتروني وكلمة المرور والموقع；",
        content14: "إعدادات الإصدار والمنطقة الزمنية；",
        content15: "تاريخ المعاملة。",
        content16: "معلومات من مصادر أخرى: قد نقوم بجمع معلومات عنك من مصادر أخرى، مثل التاريخ الائتماني من مكاتب الائتمان. سوف نستخدم هذه المعلومات لمنع وكشف الاحتيال。",
        content17: "2. هل يمكن استخدامه من قبل الأطفال؟",
        content18: 'لا تسمح هذه المنصة لأي فرد يقل عمره عن 18 عامًا باستخدام "الخدمات"”。',
        content19: "3. ما هي ملفات تعريف الارتباط والمعرفات الأخرى المستخدمة؟",
        content20: "نحن نستخدم ملفات تعريف الارتباط والأدوات المشابهة لتحسين تجربة المستخدم وتقديم خدماتنا وفهم كيفية استخدام العملاء لخدماتنا حتى نتمكن من إجراء تحسينات مستهدفة. نحن نستخدم ملفات تعريف الارتباط للتأكد من أن نظامنا يمكنه التعرف على متصفحك أو جهازك وتزويدك بالخدمات。",
        content21: 'نحن نستخدم ملفات تعريف الارتباط التشغيلية والأدوات المشابهة (يُشار إليها إجمالاً باسم "ملفات تعريف الارتباط") لتقديم الخدمات، مثل：',
        content22: "التعرف عليك عند تسجيل الدخول واستخدام خدماتنا。",
        content23: "توفير ميزات وخدمات مخصصة。",
        content24: "الحماية من النشاط الاحتيالي。",
        content25: "تحسين الأمن。",
        content26: "تتبع تفضيلاتك (مثل العملة واللغة)。",
        content27: "نستخدم أيضًا ملفات تعريف الارتباط لفهم كيفية استخدام المستخدمين لخدماتنا حتى نتمكن من إجراء تحسينات مستهدفة.。",
        content28: "4. هل سيتم مشاركة معلوماتي الشخصية؟",
        content29: "تعد معلومات المستخدم جزءًا مهمًا من أعمالنا، ولن نبيع المعلومات الشخصية للمستخدمين للآخرين. لن تشارك هذه المنصة المعلومات الشخصية للمستخدمين إلا مع الشركات التابعة لها أو الشركات التابعة لها في ظل الظروف التالية: يُطلب من هذه الشركات التابعة أو الشركات التابعة الالتزام ببيان الخصوصية هذا أو على الأقل اتباع الممارسات الوقائية مثل تدابير الحماية الموضحة في بيان الخصوصية هذا.。",
        content30: "مقدمو خدمات الطرف الثالث: نحن نوظف شركات وأفراد آخرين لأداء المهام نيابة عنا. ومن أمثلة هذه الوظائف ما يلي: تحليل البيانات، وتقديم المساعدة التسويقية، ومعالجة المدفوعات، ونقل المحتوى، وتقييم مخاطر الائتمان وإدارتها. يتمتع مقدمو الخدمات الخارجيون هؤلاء بإمكانية الوصول إلى المعلومات الشخصية اللازمة لأداء وظائفهم، ولكن لا يجوز لهم استخدامها لأي أغراض أخرى. بالإضافة إلى ذلك، يجب عليهم معالجة المعلومات الشخصية بما يتوافق مع بيان الخصوصية هذا وقوانين حماية البيانات المعمول بها。",
        content31: "تحويلات الأعمال: مع استمرار نمو أعمالنا، قد نبيع أو نشتري أعمالًا أو خدمات أخرى. في مثل هذه المعاملات، تكون معلومات المستخدم عمومًا من بين الأصول التجارية المنقولة ولكنها تظل خاضعة للوعود الواردة في أي بيان خصوصية موجود مسبقًا (ما لم يوافق المستخدم على خلاف ذلك بالطبع). بالإضافة إلى ذلك، إذا تم الاستحواذ على شركتنا أو معظم أصولها، فسيتم أيضًا نقل معلومات المستخدم. حماية شركتنا والآخرين: عندما نعتقد أن الكشف عن الحساب والمعلومات الشخصية الأخرى أمر مناسب للامتثال للقانون أو التزاماتنا التنظيمية، أو فرض أو تطبيق شروط الاستخدام الخاصة بنا والاتفاقيات الأخرى، أو الحقوق أو الملكية أو سلامة منتجاتنا. المستخدمين أو غيرهم عندما نقوم بإصدار الحساب والمعلومات الشخصية الأخرى. يتضمن ذلك تبادل المعلومات مع الشركات والمؤسسات الأخرى للحماية من الاحتيال وتقليل مخاطر الائتمان。",
        content32: "5. النقل الدولي للبيانات الشخصية",
        content33: 'يجوز لنا نقل بياناتك خارج المنطقة الاقتصادية الأوروبية ("EEA"). سنضع ضمانات مناسبة لضمان امتثال عمليات النقل هذه لقواعد حماية البيانات المعمول بها، ما لم تؤكد المفوضية الأوروبية أن الدولة التي يتم نقل البيانات إليها توفر مستوى مناسبًا من الحماية.。',
        content34: "6. هل معلوماتي الشخصية آمنة؟",
        content35: "لقد صممنا أنظمتنا مع أخذ أمانك وخصوصيتك في الاعتبار. نحن نستخدم بروتوكولات وبرامج التشفير سعيًا لحماية أمن المعلومات الشخصية أثناء النقل.。",
        content36: "نحن نعتمد دائمًا ضمانات مادية وإلكترونية وإجرائية عند جمع المعلومات الشخصية وتخزينها والكشف عنها. تعني إجراءاتنا الأمنية أننا قد نحتاج إلى التحقق من هويتك قبل الكشف عن المعلومات الشخصية لك。",
        content37: "أهم شيء يمكنك القيام به هو حماية كلمات مرور حسابك الشخصي من الوصول غير المصرح به. نوصي بتعيين كلمة مرور فريدة لحسابك تكون مختلفة عن الحسابات الأخرى عبر الإنترنت. وتأكد من تسجيل الخروج بعد استخدام جهاز كمبيوتر مشترك。",
        content38: "7. كيف يجب أن أحمي معلوماتي الشخصية؟",
        content39: "إذا كان لديك أي أسئلة أو اعتراضات حول الطريقة التي نجمع بها المعلومات الشخصية ونعالجها، فيرجى الاتصال بموظفي خدمة العملاء",
        content40: "بعد موافقتك على معالجة معلوماتك الشخصية لغرض محدد، يمكنك سحب نيتك في أي وقت، وسنتوقف عن معالجة بياناتك لهذا الغرض.。",
        content41: "بالإضافة إلى ذلك، لديك الحق في طلب الوصول إلى البيانات الشخصية وتصحيحها وحذفها وطلب إمكانية نقل البيانات، وفقًا للقوانين المعمول بها. يمكنك أيضًا الاعتراض على معالجتنا لبياناتك الشخصية، أو طلب تقييد معالجتنا لبياناتك الشخصية في ظروف معينة.。",
        content42: "8. القانون العام لحماية البيانات في الاتحاد الأوروبي وقانون حماية البيانات في المملكة المتحدة – الأساس القانوني",
        content43: "يتطلب منا القانون العام لحماية البيانات في الاتحاد الأوروبي وقانون حماية البيانات في المملكة المتحدة الالتزام بالأساس القانوني عند استخدام المعلومات الشخصية. يعتمد أساسنا على الأغراض المحددة التي تُستخدم من أجلها المعلومات الشخصية. وتشمل هذه القواعد：",
        content44: "تنفيذ العقد – لتزويدك بالمنتجات أو الخدمات أو التواصل معك بشأنها، بما في ذلك استخدامنا لمعلوماتك الشخصية لقبول الطلبات ومعالجتها ومعالجة المدفوعات.。",
        content45: "مصالحنا التجارية المشروعة ومصالح المستخدمين - نحن نكتشف ونمنع الاحتيال وإساءة الاستخدام ونحمي سلامة مستخدمينا أو أنفسنا أو الآخرين。",
        content46: "موافقتك – نحن نطلب موافقتك لمعالجة معلوماتك الشخصية للأغراض المحددة التي تم إبلاغك بها. بمجرد منحنا موافقتك على معالجة معلوماتك الشخصية لغرض محدد، يمكنك سحب موافقتك في أي وقت وسنتوقف عن معالجة بياناتك لهذا الغرض.。",
        content47: "الامتثال للالتزامات القانونية - نستخدم معلوماتك الشخصية وفقًا لما يقتضيه القانون. على سبيل المثال، نقوم بجمع معلومات الحساب المصرفي لأغراض التحقق من الهوية。",
        content48: "يعتمد المحتوى أعلاه والأسس القانونية الأخرى على الأغراض المحددة التي نستخدم المعلومات الشخصية من أجلها.。",
        content49: "9. شروط الاستخدام والإشعارات والمراجعات",
        content50: "يخضع استخدامك وأي نزاع حول الخصوصية لهذا البيان وشروط الاستخدام الخاصة بنا. إذا كانت لديك أية مخاوف بشأن خصوصية هذه المنصة، فيرجى الاتصال بنا وتزويدنا بشرح تفصيلي وسنكون سعداء بحل المشكلة لك. لديك أيضًا الحق في الاتصال بهيئة حماية البيانات المحلية لديك。",
        content51: "يتغير عملنا باستمرار، وكذلك بيان الخصوصية الخاص بنا. يجب عليك مراجعة موقعنا بشكل متكرر لمعرفة أحدث التغييرات. إذا كنت لا توافق على المحتوى المعدل، فيجب عليك التوقف عن الوصول إليه على الفور. بعد إصدار الإصدار المحدث من سياسة الخصوصية، يعني استمرار وصولك أنك توافق على المحتوى المحدث وتوافق على الالتزام بسياسة الخصوصية المحدثة. ما لم ينص على خلاف ذلك، ينطبق بيان الخصوصية الحالي على جميع المعلومات التي نحتفظ بها عنك وعن حسابك. ",
    },
    serviceAgreement: {
        title: "اتفاقية خدمات",
        content1: 'هذا الموقع عبارة عن منصة مخصصة للمستخدمين لإجراء معاملات الأصول الرقمية وتقديم الخدمات ذات الصلة (يشار إليها فيما يلي باسم "الخدمة" أو "الخدمة"). ولتسهيل وصف هذه الاتفاقية، يشار إلى هذا الموقع بشكل جماعي باسم " "نحن" في هذه الاتفاقية أو ضمائر المخاطب الأخرى. طالما أن الشخص الطبيعي أو الشخص الآخر الذي يقوم بتسجيل الدخول إلى موقع الويب هو مستخدم لهذا الموقع، لتسهيل التعبير عن هذه الاتفاقية، "أنت" أو شخص آخر ثانٍ سيتم استخدام ضمائر المخاطب أدناه. لتسهيل التعبير عن هذه الاتفاقية، يُشار إلينا نحن وأنت بموجب هذه الاتفاقية بشكل جماعي باسم "الأطراف"، ويشار إلينا أو أنت بشكل فردي باسم "الطرف"',
        content2: "تلميح مهم：",
        content3: "ونود أن نذكرك هنا：",
        content4: "1 لا يتم إصدار الأصول الرقمية نفسها من قبل أي مؤسسة مالية أو شركة أو هذا الموقع",
        content5: "2 أسواق الأصول الرقمية جديدة وغير مؤكدة وقد لا تنمو；",
        content6: "3 تُستخدم الأصول الرقمية بشكل أساسي على نطاق واسع من قبل المضاربين، مع استخدام قليل نسبيًا في أسواق التجزئة والأسواق التجارية، كما أن معاملات الأصول الرقمية محفوفة بالمخاطر للغاية، حيث يتم تداولها بشكل مستمر على مدار اليوم، مع عدم وجود حدود للارتفاع والانخفاض، وتتأثر الأسعار بسهولة بالسوق. صناع وسياسات الحكومة العالمية；",
        content7: "4  تحتفظ الشركة بالحق في تعليق حسابك أو إنهائه في أي وقت إذا قررت الشركة، وفقًا لتقديرها الخاص، أنك انتهكت هذه الاتفاقية، أو أن الخدمات التي يقدمها هذا الموقع أو استخدامك للخدمات التي يقدمها هذا الموقع غير قانونية. غير قانوني بموجب قوانين ولايتك القضائية، أو تعليق أو إنهاء استخدامك للخدمات أو معاملات الأصول الرقمية التي يقدمها هذا الموقع. يُحظر على أي شخص من [البر الرئيسي للصين وتايوان وإسرائيل والعراق وبنغلاديش وبوليفيا والإكوادور وقيرغيزستان وسيفاستوبول والمملكة المتحدة (مستخدمي التجزئة)] استخدام خدمات المعاملات التعاقدية التي يقدمها هذا الموقع. ستتغير قائمة البلدان أو المناطق المذكورة أعلاه اعتمادًا على السياسات وأنواع المنتجات الخاصة بالبلدان أو المناطق المختلفة. وقد لا نخطرك على وجه التحديد في ذلك الوقت. يرجى الانتباه إلى تحديثات هذه الاتفاقية في الوقت المناسب.。",
        content8: "ينطوي تداول الأصول الرقمية على مخاطر عالية للغاية ولا يناسب معظم الأشخاص. أنت تدرك وتفهم أن هذه المعاملة قد تؤدي إلى خسارة جزئية أو كلية، لذا يجب عليك تحديد مبلغ المعاملة بناءً على درجة الخسارة التي يمكنك تحملها. أنت تعلم وتفهم أن الأصول الرقمية ستولد مخاطر مشتقة، لذلك إذا كانت لديك أي أسئلة، فمن المستحسن طلب المساعدة من المستشارين المحترفين أولاً. بالإضافة إلى ذلك، بالإضافة إلى المخاطر المذكورة أعلاه، هناك أيضًا مخاطر لا يمكن التنبؤ بها. يجب عليك التفكير بعناية واستخدام الحكم الواضح لتقييم وضعك المالي والمخاطر المذكورة أعلاه قبل اتخاذ أي قرار بشراء أو بيع الأصول الرقمية، وتحمل كافة الخسائر الناتجة، ولا نتحمل أي مسؤولية عن ذلك.。",
        content9: "تحذير لك：",
        content10: "1 أنت تدرك أن هذا الموقع يستخدم فقط كمكان لك للحصول على معلومات الأصول الرقمية، والعثور على أطراف المعاملات، والتفاوض وإجراء المعاملات على الأصول الرقمية، ولا يشارك هذا الموقع في أي من معاملاتك، لذا يجب عليك استخدام حكمك الحكيم لتحديد الأصول و/أو المعاملات الرقمية ذات الصلة أو صحة وقانونية وصحة المعلومات، ويتحمل المسؤوليات والخسائر الناتجة من تلقاء نفسه。",
        content11: "2 أي آراء وأخبار ومناقشات وتحليلات وأسعار وتوصيات ومعلومات أخرى على هذا الموقع هي تعليقات عامة للسوق ولا تشكل نصيحة استثمارية. نحن لسنا مسؤولين عن أي خسائر تنشأ بشكل مباشر أو غير مباشر من الاعتماد على هذه المعلومات، بما في ذلك على سبيل المثال لا الحصر، أي خسارة في الأرباح.。",
        content12: "3 يحتفظ هذا الموقع بالحق في اتخاذ قراره الخاص أو تعديل أو تغيير المحتوى في أي وقت، وقد اتخذنا تدابير معقولة لضمان دقة المعلومات الموجودة على الموقع، ولكن لا يمكننا ضمان دقتها، ولن نكون مسؤولين عن أي خسارة أو ضرر ناجم عن المعلومات الموجودة في هذا الموقع أو نقص المعلومات الخسائر المباشرة أو غير المباشرة الناشئة عن التأخير أو الفشل في الارتباط بالإنترنت أو إرسال أو تلقي أي إشعارات ومعلومات。",
        content13: "4 هناك أيضًا مخاطر في استخدام أنظمة التداول القائمة على الإنترنت، بما في ذلك على سبيل المثال لا الحصر فشل البرامج والأجهزة والاتصال بالإنترنت. وبما أننا لا نملك أي سيطرة على موثوقية وتوافر الإنترنت، فإننا لا نتحمل أي مسؤولية عن التشويه والتأخير وفشل الاتصال.。",
        content14: "5 يُحظر استخدام هذا الموقع للانخراط في جميع أنشطة التجارة غير القانونية أو الأنشطة غير القانونية مثل غسيل الأموال والتهريب والرشوة التجارية وما إلى ذلك. وفي حالة العثور على أي معاملات غير قانونية أو أنشطة غير قانونية مشتبه بها، فسوف يتخذ هذا الموقع مختلف الوسائل المتاحة، بما في ذلك ولكن لا يقتصر الأمر على تجميد الحسابات وإخطار الأطراف ذات الصلة، والسلطات وغيرها، ولا نتحمل جميع المسؤوليات الناشئة عن ذلك ونحتفظ بالحق في متابعة المسؤولية من الأشخاص المعنيين.",
        content15: "6يحظر استخدام هذا الموقع للتلاعب بالسوق بشكل ضار والمعاملات غير السليمة وغيرها من أنشطة التداول غير الأخلاقية.إذا تم اكتشاف مثل هذه الحوادث، فسيقوم هذا الموقع بتحذير وتقييد المعاملات وإغلاق جميع السلوكيات غير الأخلاقية مثل التلاعب الضار بالأسعار والتأثير الضار على نظام التداول نحن لا نتحمل جميع المسؤوليات الناشئة عن تدابير الحماية الوقائية مثل تعليق الحساب ونحتفظ بالحق في متابعة المسؤولية من الأشخاص المعنيين.",
        content16: "1. المبادئ العامة",
        content17: '1.1 تتكون "اتفاقية المستخدم" (المشار إليها فيما يلي بـ "هذه الاتفاقية" أو "هذه الشروط والأحكام") من النص الرئيسي، "شروط الخصوصية"، و"اعرف عميلك وسياسة مكافحة غسيل الأموال" والعديد من السياسات الأخرى التي لها تم نشرها أو قد يتم نشرها مستقبلاً على هذا الموقع قواعد الفصل والبيانات والأوصاف وما إلى ذلك.',
        content18: "1.2 يجب عليك قراءة هذه الاتفاقية بعناية قبل استخدام الخدمات التي يقدمها هذا الموقع، إذا لم تفهم أي شيء أو إذا كان ذلك ضروريًا، فيرجى استشارة محامٍ محترف. إذا كنت لا توافق على هذه الاتفاقية و/أو تعديلها في أي وقت، فيرجى التوقف فورًا عن استخدام الخدمات التي يقدمها هذا الموقع أو التوقف عن تسجيل الدخول إلى هذا الموقع. بمجرد تسجيل الدخول إلى هذا الموقع، واستخدام أي خدمات من هذا الموقع أو أي سلوك آخر مماثل، فهذا يعني أنك قد فهمت ووافقت بشكل كامل على محتويات هذه الاتفاقية، بما في ذلك أي تعديلات يجريها هذا الموقع على هذه الاتفاقية في أي وقت.",
        content19: '1.3 يمكنك أن تصبح عضوا في هذا الموقع (المشار إليه فيما بعد باسم "العضو") عن طريق ملء المعلومات ذات الصلة وفقا لمتطلبات هذا الموقع والتسجيل بنجاح بعد الإجراءات الأخرى ذات الصلة. وذلك عن طريق النقر على زر "أوافق" أثناء التسجيل العملية، تقوم بالتوقيع إلكترونيًا على نموذج الاتفاقية مع الشركة، أو عند النقر فوق أي زر يحمل علامة "أوافق" أو معنى مشابه أثناء استخدام هذا الموقع، أو استخدام الخدمات التي يقدمها هذا الموقع فعليًا بطرق أخرى يسمح بها هذا الموقع. فهذا يعني أنك تفهم تمامًا وتوافق وتقبل جميع الشروط الواردة في هذه الاتفاقية، ولن يؤثر غياب توقيعك المكتوب بخط اليد على القوة القانونية الملزمة لهذه الاتفاقية عليك.',
        content20: "1.4 بعد أن تصبح عضوًا في هذا الموقع، ستتلقى حساب عضو وكلمة المرور المقابلة، والتي تحتفظ بها، وستكون مسؤولاً قانونيًا عن جميع الأنشطة والأحداث التي تتم ضمن حسابك。",
        content21: "1.5 يمكن فقط للأعضاء الذين أصبحوا أعضاء في هذا الموقع استخدام منصة تداول الأصول الرقمية التي يوفرها هذا الموقع للمعاملات والاستمتاع بالخدمات الأخرى المنصوص عليها في هذا الموقع والتي تكون متاحة فقط للأعضاء؛ ويمكن لغير الأعضاء فقط تسجيل الدخول إلى الموقع وتصفح الموقع. وغيرها من الخدمات المنصوص عليها في هذا الموقع。",
        content22: "1.6 من خلال التسجيل واستخدام أي خدمات ووظائف يقدمها هذا الموقع، سيتم اعتبارك قد قرأت وفهمت و：",
        content23: "1.6.1 قبول جميع شروط وأحكام هذه الاتفاقية。",
        content24: "1.6.2 أنت تؤكد أن عمرك يزيد عن 18 عامًا أو أن لديك السن القانوني لإبرام العقود وفقًا للقوانين المختلفة المعمول بها، ويجب أن يكون تسجيلك في هذا الموقع والمبيعات أو المشتريات ونشر المعلومات وما إلى ذلك وقبولك لخدمات هذا الموقع متوافقًا مع مع متطلباتك، تتمتع بالولاية القضائية على القوانين واللوائح ذات الصلة لدولة أو منطقة ذات سيادة، ولديها القدرة الكاملة على قبول هذه الشروط، والدخول في المعاملات، واستخدام هذا الموقع لإجراء معاملات الأصول الرقمية.",
        content25: "1.6.3 أنت تضمن أن جميع الأصول الرقمية المشاركة في المعاملة والتي تخصك قد تم الحصول عليها بشكل قانوني ولها ملكية。",
        content26: "1.6.4 أنت توافق على أنك المسؤول الوحيد عن الأنشطة التجارية أو غير التجارية الخاصة بك وعن أي مكاسب أو خسائر。",
        content27: "1.6.5 أنت تؤكد أن المعلومات المقدمة عند التسجيل صحيحة ودقيقة。",
        content28: "1.6.6 أنت توافق على الالتزام بمتطلبات أي قانون ذي صلة للأغراض الضريبية، بما في ذلك الإبلاغ عن أي أرباح تجارية。",
        content29: "1.6.7 أنت توافق على عدم الانخراط أو المشاركة في أي سلوك أو أنشطة تضر بمصالح هذا الموقع أو الشركة في أي وقت، سواء كان ذلك متعلقًا بالخدمات التي يقدمها هذا الموقع أم لا.。",
        content30: "1.6.8 تحكم هذه الاتفاقية فقط الحقوق والالتزامات بينك وبيننا، ولا تشمل العلاقات القانونية والنزاعات القانونية الناشئة عن معاملات الأصول الرقمية بين مستخدمي هذا الموقع والمواقع الأخرى وبينك.。",
        content31: "2. تعديلات الاتفاقية",
        content32: "نحن نحتفظ بالحق في مراجعة هذه الاتفاقية من وقت لآخر والإعلان عنها على الموقع الإلكتروني دون إخطارك بشكل منفصل، وبعد المراجعة، سيتم تحديد وقت المراجعة في [آخر وقت تحديث] المعروض في هذه الاتفاقية، وسيستغرق الأمر تلقائيًا يسري مفعوله بمجرد نشره على الموقع. يجب عليك التصفح والانتباه إلى وقت التحديث والمحتوى المحدث لهذه الاتفاقية من وقت لآخر، وإذا كنت لا توافق على التغييرات ذات الصلة، فيجب عليك التوقف فورًا عن استخدام خدمات هذا الموقع، واستمرارك في استخدام خدمات هذا الموقع. موقع الويب يعني أنك تقبل وتوافق على الالتزام بالاتفاقية المعدلة.",
        content33: "3. التسجيل",
        content34: "3.1 مؤهلات التسجيل",
        content35: 'أنت تؤكد وتتعهد بأنه عند إكمال عملية التسجيل أو الاستخدام الفعلي للخدمات التي يقدمها هذا الموقع بطرق أخرى يسمح بها هذا الموقع، يجب أن يكون لديك القدرة على توقيع هذه الاتفاقية واستخدام خدمات هذا الموقع على النحو المنصوص عليه في القوانين المعمول بها. شخص طبيعي أو شخص اعتباري أو منظمة أخرى. بمجرد النقر فوق زر "الموافقة على التسجيل"، فهذا يعني أنك أو وكيلك المعتمد قد وافقت على محتوى الاتفاقية وتم تسجيلك واستخدام خدمات هذا الموقع من قبل وكيله. إذا لم تكن لديك مؤهلات الموضوع المذكورة أعلاه، فسوف تتحمل أنت ووكيلك المعتمد جميع التبعات الناتجة عن ذلك، وتحتفظ الشركة بالحق في إلغاء حسابك أو تجميده بشكل دائم، ومحاسبتك أنت ووكيلك المعتمد. .',
        content36: "3.2 غرض التسجيل",
        content37: "أنت تؤكد وتتعهد بأن تسجيلك في هذا الموقع ليس بغرض انتهاك القوانين واللوائح أو تعطيل ترتيب معاملات الأصول الرقمية على هذا الموقع.",
        content38: "3.3 عملية التسجيل",
        content39: "3.3.1 أنت توافق على تقديم عنوان بريد إلكتروني صالح ورقم هاتف محمول ومعلومات أخرى كما هو مطلوب في صفحة تسجيل المستخدم في هذا الموقع، ويمكنك استخدام عنوان البريد الإلكتروني ورقم الهاتف المحمول الذي قدمته أو أكدته أو الطرق الأخرى التي يسمح بها هذا الموقع لتسجيل الدخول طريقة الدخول إلى هذا الموقع. إذا لزم الأمر، ووفقًا للقوانين واللوائح ذات الصلة في الولايات القضائية المختلفة، يجب عليك تقديم اسمك الحقيقي ووثيقة الهوية والمعلومات الأخرى ذات الصلة المنصوص عليها في القوانين واللوائح وبنود الخصوصية وبنود مكافحة غسيل الأموال، وتحديث معلومات التسجيل باستمرار في في الوقت المناسب وبطريقة مفصلة ودقيقة. سيتم الإشارة إلى جميع المعلومات المكتوبة في الأصل كمعلومات تسجيل. أنت مسؤول عن صحة هذه المعلومات واكتمالها ودقتها، وتتحمل أي خسائر مباشرة أو غير مباشرة وعواقب سلبية تنشأ عن ذلك.。",
        content40: "3.3.2 إذا كانت القوانين واللوائح والقواعد والأوامر وغيرها من اللوائح الخاصة بالدولة ذات السيادة أو المنطقة التي تتواجد فيها تتضمن متطلبات الاسم الحقيقي لأرقام الهاتف المحمول، فإنك توافق على أن رقم الهاتف المحمول المسجل المقدم قد تم تسجيله بالاسم الحقيقي. إذا لم تقم بتقديمها وفقًا للوائح، فإن أي عواقب تلحق بك ستكون خسائر مباشرة أو غير مباشرة وستتحمل عواقب سلبية。",
        content41: "3.3.3 إذا قمت بتقديم المعلومات المطلوبة للتسجيل بشكل قانوني وكامل وفعال وقمت بالتحقق منها، فيحق لك الحصول على رقم الحساب وكلمة المرور الخاصة بهذا الموقع، وعندما تحصل على رقم الحساب وكلمة المرور الخاصة بهذا الموقع، يعتبر تسجيلك ناجح ويمكنك تسجيل الدخول كعضو على هذا الموقع.。",
        content42: "3.3.4 أنت توافق على تلقي رسائل البريد الإلكتروني و/أو الرسائل القصيرة التي يرسلها هذا الموقع والمتعلقة بإدارة وتشغيل هذا الموقع.。",
        content43: "4. الخدمة",
        content44: "يوفر هذا الموقع فقط خدمات منصة التداول عبر الإنترنت لأنشطة تداول الأصول الرقمية الخاصة بك (بما في ذلك على سبيل المثال لا الحصر، تداول الأصول الرقمية والخدمات الأخرى) من خلال هذا الموقع.",
        content45: "4.1 محتوى الخدمة",
        content46: "4.1.1 لديك الحق في تصفح ظروف السوق في الوقت الفعلي ومعلومات المعاملات لمختلف منتجات الأصول الرقمية على هذا الموقع، كما يحق لك إرسال تعليمات معاملات الأصول الرقمية وإكمال معاملات الأصول الرقمية من خلال هذا الموقع.",
        content47: "4.1.2 لديك الحق في عرض المعلومات الموجودة ضمن حساب عضويتك على هذا الموقع وتطبيق الوظائف التي يوفرها هذا الموقع للتشغيل.",
        content48: "4.1.3 لديك الحق في المشاركة في أنشطة الموقع التي ينظمها هذا الموقع وفقًا لقواعد النشاط المنشورة على هذا الموقع.",
        content49: "4.1.4 الخدمات الأخرى التي يعدك هذا الموقع بتقديمها لك。",
        content50: "4.2 قواعد الخدمة أنت تتعهد بالالتزام بقواعد الخدمة التالية لهذا الموقع:",
        content51: "4.2.1 يجب عليك الالتزام بالقوانين واللوائح ومتطلبات السياسة، والتأكد من شرعية مصادر جميع الأصول الرقمية في حسابك، وعدم الانخراط في أنشطة غير قانونية أو غيرها من الأنشطة التي تضر بحقوق ومصالح هذا الموقع أو الأطراف الثالثة على هذا الموقع. أو باستخدام خدماتها، بما في ذلك على سبيل المثال لا الحصر: لا يقتصر الأمر على إرسال أو تلقي أي معلومات غير قانونية أو غير قانونية أو تنتهك حقوق الآخرين، أو إرسال أو استقبال المخططات الهرمية أو غيرها من المعلومات أو الملاحظات الضارة، أو استخدام البريد الإلكتروني أو تزويره. معلومات الرأس على هذا الموقع دون الحصول على إذن من هذا الموقع، وما إلى ذلك.",
        content52: "4.2.2 يجب عليك الالتزام بالقوانين واللوائح والاستخدام الصحيح والاحتفاظ برقم حسابك وكلمة مرور تسجيل الدخول وكلمة مرور الصندوق ورقم الهاتف المحمول المرتبط بك عند التسجيل ورمز التحقق من الهاتف المحمول الذي يتلقاه هاتفك المحمول. أنت مسؤول مسؤولية كاملة عن أي عمليات وعواقب لاستخدام حسابك وكلمة مرور تسجيل الدخول وكلمة مرور الصندوق ورمز التحقق من الهاتف المحمول. عندما تجد أن رقم حساب موقع الويب هذا أو كلمة مرور تسجيل الدخول أو كلمة مرور الصندوق أو رمز التحقق يتم استخدامها من قبل طرف ثالث دون إذن منك، أو أن هناك مشكلات أخرى تتعلق بأمان الحساب، يجب عليك إخطار موقع الويب هذا على الفور وبشكل فعال ومطالبة موقع الويب هذا بتعليقه خدمة حساب هذا الموقع . يحق لهذا الموقع اتخاذ إجراء بشأن طلبك خلال فترة زمنية معقولة، ولكن هذا الموقع لا يتحمل أي مسؤولية عن العواقب (بما في ذلك على سبيل المثال لا الحصر أي خسائر تتكبدها) التي حدثت قبل اتخاذ الإجراء. لا يجوز لك التبرع بحسابك على هذا الموقع أو استعارته أو تأجيره أو نقله أو التصرف فيه للآخرين دون موافقة هذا الموقع.",
        content53: "4.2.3 أنت توافق على أن تكون مسؤولاً عن جميع الأنشطة التي تحدث من خلال حسابك وكلمة المرور الخاصة بك على هذا الموقع (بما في ذلك على سبيل المثال لا الحصر الكشف عن المعلومات أو نشر المعلومات أو النقرات عبر الإنترنت للموافقة أو تقديم القواعد والاتفاقيات المختلفة أو تجديد الاتفاقيات عبر الإنترنت أو شراء الخدمات، الخ).",
        content54: "4.2.4 عند إجراء معاملات الأصول الرقمية على هذا الموقع، يجب ألا تتدخل بشكل ضار في التقدم الطبيعي لمعاملات الأصول الرقمية أو تعطيل ترتيب المعاملات؛ يجب ألا تتدخل في التشغيل العادي لهذا الموقع أو تتداخل مع استخدام المستخدمين الآخرين لموقع الويب هذا. الخدمات بأي وسيلة تقنية أو وسيلة أخرى؛ يجب ألا تستخدم مواد وهمية لتشويه سمعة هذا الموقع بشكل ضار من خلال الحقائق وغيرها من الوسائل.",
        content55: "4.2.5 إذا كان لديك نزاع مع مستخدمين آخرين بسبب المعاملات عبر الإنترنت، فلا يجوز لك أن تطلب من هذا الموقع تقديم المعلومات ذات الصلة من خلال القنوات غير القضائية أو الإدارية.",
        content56: "4.2.6 يجب عليك وحدك الحكم وتحمل أي ضريبة مستحقة، بالإضافة إلى جميع الأجهزة والبرامج والخدمات والنفقات الأخرى المتكبدة أثناء استخدامك للخدمات التي يقدمها هذا الموقع.",
        content57: "4.2.7 يجب عليك الالتزام بهذه الاتفاقية وشروط الخدمة وقواعد التشغيل الأخرى التي ينشرها ويحدثها هذا الموقع من وقت لآخر، ويكون لك الحق في إنهاء استخدام الخدمات التي يقدمها هذا الموقع في أي وقت.",
        content58: "4.3 قواعد المنتج",
        content59: "4.3.1 تصفح معلومات المعاملة",
        content60: "عند تصفح معلومات المعاملة على هذا الموقع، يجب عليك قراءة جميع المحتويات الواردة في معلومات المعاملة بعناية، بما في ذلك على سبيل المثال لا الحصر السعر وحجم العمولة ورسوم المناولة واتجاه الشراء أو البيع، وذلك فقط بعد قبول جميع المحتويات الواردة في هذه المعاملة بشكل كامل. معلومات الصفقة يمكن إجراء الصفقات بنقرة زر واحدة.",
        content61: "4.3.2 عرض تفاصيل المعاملة",
        content62: "يمكنك عرض سجلات المعاملات المقابلة من خلال حسابك.",
        content63: "5. حقوق والتزامات هذا الموقع",
        content64: "5.1 إذا لم تكن لديك مؤهلات التسجيل المنصوص عليها في هذه الاتفاقية، يحق لهذا الموقع رفض تسجيلك، وبالنسبة لأولئك الذين قاموا بالتسجيل بالفعل، يحق لهذا الموقع إلغاء حساب عضويتك، ويحتفظ هذا الموقع بالحق في احتجازك أو وكيلك المعتمد مسؤول. وفي الوقت نفسه، يحتفظ هذا الموقع بالحق في تقرير ما إذا كان سيقبل تسجيلك تحت أي ظروف أخرى.",
        content65: "5.2 بناءً على حكم موقع الويب هذا، إذا وجد موقع الويب هذا أنك أو مستخدمي حسابك المرتبطين غير مناسبين للاستثمارات عالية المخاطر، فيحق لنا تعليق حسابك أو إنهاؤه واستخدام جميع الحسابات المرتبطة به.",
        content66: "5.3 عندما يكتشف هذا الموقع أن مستخدم الحساب ليس المسجل الأولي للحساب، فإنه يحق له تعليق أو إنهاء استخدام الحساب.",
        content67: "5.4 عندما يشتبه هذا الموقع بشكل معقول في أن المعلومات التي قدمتها خاطئة أو غير صحيحة أو غير صالحة أو غير كاملة من خلال الاختبارات الفنية وأخذ العينات اليدوية وطرق الاختبار الأخرى، فإنه يحق له إخطارك لتصحيح أو تحديث المعلومات أو تعليق أو إنهاء توفير هذا الموقع يخدمك.",
        content68: "5.5 يحتفظ هذا الموقع بالحق في تصحيح أي معلومات معروضة على هذا الموقع إذا تم اكتشاف وجود خطأ واضح.",
        content69: "5.6 يحتفظ هذا الموقع بالحق في تعديل أو تعليق أو إنهاء خدمات هذا الموقع في أي وقت.لا يحتاج هذا الموقع إلى إخطارك مسبقًا لممارسة الحق في تعديل أو إنهاء الخدمات.إذا أنهى هذا الموقع خدمة أو أكثر من هذه الخدمات الموقع، سيكون الإنهاء من هذا الموقع على الموقع، ويسري من تاريخ نشر إعلان الإنهاء.",
        content70: "5.7 سيتخذ هذا الموقع الوسائل التقنية والتدابير الإدارية اللازمة لضمان التشغيل الطبيعي لهذا الموقع، وتوفير بيئة تداول وخدمات تداول ضرورية وموثوقة، والحفاظ على ترتيب معاملات الأصول الرقمية.",
        content71: "5.8 إذا لم تقم بتسجيل الدخول إلى هذا الموقع باستخدام حساب عضويتك وكلمة المرور الخاصة بك لمدة سنة متتالية، يحق لهذا الموقع إلغاء حسابك. بعد إلغاء الحساب، يحق لهذا الموقع فتح اسم العضو المقابل للمستخدمين الآخرين للتسجيل والاستخدام.",
        content72: "5.9 يضمن موقع الويب هذا أمان أصولك الرقمية من خلال تعزيز الاستثمار التكنولوجي، وتحسين الاحتياطات الأمنية وغيرها من التدابير، وسيُعلمك مسبقًا عند حدوث مخاطر أمنية متوقعة في حسابك.",
        content73: "5.10 يحق لهذا الموقع حذف جميع أنواع معلومات المحتوى الموجودة على هذا الموقع والتي لا تتوافق مع القوانين واللوائح أو لوائح هذا الموقع في أي وقت، ولا يحتاج هذا الموقع إلى إخطارك مسبقًا لممارسة هذه الحقوق.",
        content74: "5.11 يحق لهذا الموقع أن يطلب منك تقديم المزيد من المعلومات أو المواد وفقًا للقوانين واللوائح والقواعد والأوامر والمتطلبات التنظيمية الأخرى لبلدك أو منطقتك ذات السيادة، واتخاذ التدابير المعقولة للامتثال للمتطلبات التنظيمية المحلية. ملزمون بالتعاون؛ يحق لهذا الموقع تعليق أو إيقاف فتح بعض أو كل خدمات هذا الموقع لك بشكل دائم وفقًا لمتطلبات القوانين واللوائح والقواعد والأوامر والمواصفات الأخرى الخاصة ببلدك أو منطقتك ذات السيادة.",
        content75: "5.12 يحتفظ هذا الموقع بالحق في إغلاق حسابك بعد إشعار مدته سبعة أيام عمل وفقًا لتقديره الخاص. لذلك، لديك سبعة أيام عمل لإلغاء الأوامر وإغلاق المراكز. إذا لم تقم بإلغاء الأمر وإغلاق المركز عند انتهاء الفترة، فسنقوم بإلغاء الأمر بالقوة وإغلاق المركز، ونعيد الأصول الرقمية المتبقية في حسابك إليك.",
        content76: "5.13من أجل حماية حقوق ومصالح التجار، يحق لهذا الموقع إجراء تعديلات على المنتجات عبر الإنترنت في ظل ظروف خاصة (مثل فشل النظام، فشل الشبكة، ظروف السوق القاسية، وما إلى ذلك)، مثل: عقود التسليم المبكر و التسوية وأنواع العقود وعقود التسليم والتسوية المبكرة، وتخضع المدة وسعر التسوية والتسليم للإعلان على هذا الموقع.",
        content77: "6. التعويض",
        content78: "6.1 لن تتجاوز مسؤوليتنا تجاهك عن الأضرار المباشرة بأي حال من الأحوال إجمالي رسوم الخدمة التي فرضناها عليك مقابل استخدامك لهذا الموقع لمدة ثلاثة (3) أشهر.",
        content79: "6.2 إذا قمت بانتهاك هذه الاتفاقية أو غيرها من القوانين واللوائح، فيجب عليك تعويضنا بما لا يقل عن 2 مليون دولار أمريكي وتحمل جميع التكاليف المتكبدة (بما في ذلك أتعاب المحاماة، وما إلى ذلك). إذا لم يكن ذلك كافيًا للتعويض عن الخسائر الفعلية، فيجب عليك تقديم لذلك.",
        content80: "7. الحق في طلب الانتصاف الزجري",
        content81: "نقر نحن وأنت بأن سبل الانتصاف بموجب القانون العام لخرقك للعقد أو الخرق المحتمل للعقد قد لا تكون كافية للتعويض عن جميع الخسائر التي نتكبدها، لذلك لدينا الحق في طلب تعويض زجري على النحو الذي يسمح به القانون العام أو العدالة في في حالة خرقك للعقد أو خرق محتمل للعقد، وجميع سبل الانتصاف الأخرى.",
        content82: "8. حدود المسؤولية وإخلاء المسؤولية",
        content83: "8.1 أنت تفهم وتوافق على أنه، تحت أي ظرف من الظروف، لن نكون مسؤولين عن الأمور التالية:",
        content84: "8.1.1 انخفاض في الربح؛",
        content85: "8.1.2 أرباح التداول أو خسائر العقود؛",
        content86: "8.1.3 الخسائر الناجمة عن انقطاع الأعمال؛",
        content87: "8.1.4 خسارة المدخرات النقدية المتوقعة؛",
        content88: "8.1.5 الخسائر الناجمة عن مشاكل المعلومات؛",
        content89: "8.1.6 خسارة الفرص أو حسن النية أو السمعة؛",
        content90: "8.1.7 الفساد أو فقدان البيانات؛",
        content91: "8.1.8 تكلفة شراء المنتجات أو الخدمات البديلة؛",
        content92: "8.1.9 أي خسارة أو ضرر غير مباشر أو خاص أو عرضي ينشأ عن الضرر (بما في ذلك الإهمال) أو خرق العقد أو أي سبب آخر، سواء كنا نتوقع هذه الخسارة أو الضرر بشكل معقول أم لا؛ سواء تم إبلاغنا بوجود مثل هذه الخسارة أو الضرر أم لا مقدما احتمال الخسارة أو الضرر.",
        content93: "8.1.1 المواد إلى 8.1.9 مستقلة عن بعضها البعض.",
        content94: "8.2 أنت تفهم وتوافق على أننا لسنا مسؤولين عن أي أضرار تلحق بك نتيجة لأي من الظروف التالية:",
        content95: "8.2.1 قد تنطوي معاملاتك المحددة على انتهاكات جسيمة للقوانين أو انتهاكات للعقد.",
        content96: "8.2.2 قد يكون سلوكك على هذا الموقع غير قانوني أو غير أخلاقي.",
        content97: "8.2.3 التكاليف والخسائر المتكبدة عن طريق شراء أو الحصول على أي بيانات أو معلومات أو إجراء المعاملات من خلال خدمات هذا الموقع أو عن طريق الإجراءات البديلة.",
        content98: "8.2.4 سوء فهمك لخدمات هذا الموقع.",
        content99: "8.2.5 أي خسائر أخرى تتعلق بالخدمات التي يقدمها هذا الموقع والتي لا نتسبب فيها.",
        content100: "8.3 نحن مسؤولون عن صيانة معدات شبكة المعلومات، أو فشل الاتصال بشبكة المعلومات، أو فشل الكمبيوتر أو الاتصالات أو أي نظام آخر، أو انقطاع التيار الكهربائي، أو الظروف الجوية، أو الحوادث، أو الإضرابات، أو النزاعات العمالية، أو أعمال الشغب، أو الانتفاضات، أو أعمال الشغب، أو عدم كفاية الإنتاجية أو مواد الإنتاج، أو الحرائق، أو الفيضانات. أو العواصف أو الانفجارات أو الحروب أو أسباب البنك أو الشركاء الآخرين، أو انهيار سوق الأصول الرقمية، أو الإجراءات الحكومية، أو أوامر الهيئات القضائية أو الإدارية، أو الإجراءات الأخرى الخارجة عن سيطرتنا أو التي ليس لدينا القدرة على السيطرة عليها، أو أسباب خارجية ولا تتحمل أي مسؤولية عن عدم القدرة أو التأخير في الخدمة، أو خسائرك.",
        content101: "8.4 لا يمكننا أن نضمن أن جميع المعلومات والبرامج والنصوص وما إلى ذلك الواردة في هذا الموقع آمنة تمامًا ولن يتم التدخل فيها وتدميرها بواسطة أي فيروسات وأحصنة طروادة وغيرها من البرامج الضارة، لذلك، إذا قمت بتسجيل الدخول واستخدام أي خدمات على هذا الموقع أو تنزيل واستخدام أي برامج ومعلومات وبيانات وما إلى ذلك كلها قراراتك الخاصة وأنت تتحمل المخاطر والخسائر المحتملة على مسؤوليتك الخاصة.",
        content102: "8.5 نحن لا نقدم أي ضمانات أو التزامات بشأن أي معلومات ومنتجات وخدمات خاصة بمواقع الطرف الثالث المرتبطة بهذا الموقع أو أي شكل آخر من أشكال المحتوى الذي لا ينتمي إلينا.إذا كنت تستخدم أي خدمات ومعلومات وخدمات مقدمة من طرف ثالث إن مواقع الويب والمنتجات وما إلى ذلك هي جميعها قراراتك الخاصة وتتحمل جميع المسؤوليات الناشئة عنها.",
        content103: "8.6 نحن لا نقدم أي ضمانات صريحة أو ضمنية لاستخدامك للخدمات الموجودة على هذا الموقع، بما في ذلك على سبيل المثال لا الحصر، مدى ملاءمة الخدمات التي يقدمها هذا الموقع، وعدم وجود أخطاء أو سهو، والاستمرارية، والدقة، والموثوقية، وملاءمة خدمة معينة. غاية. وفي الوقت نفسه، نحن لا نقدم أي التزام أو ضمان فيما يتعلق بصحة ودقة وصحة وموثوقية وجودة واستقرار واكتمال وتوقيت التكنولوجيا والمعلومات المشاركة في الخدمات التي يقدمها هذا الموقع. إن تسجيل الدخول أو استخدام الخدمات التي يقدمها هذا الموقع هو قرارك الشخصي وتتحمل المخاطر والخسائر المحتملة الخاصة بك. نحن لا نقدم أي ضمانات صريحة أو ضمنية فيما يتعلق بسوق وقيمة وسعر الأصول الرقمية. أنت تدرك وتفهم أن سوق الأصول الرقمية غير مستقر. وقد تتقلب الأسعار والقيم أو تنهار بشكل كبير في أي وقت. تداول الأصول الرقمية هو حريتك الشخصية أنت تختار وتقرر على مسؤوليتك الخاصة والخسائر المحتملة.",
        content104: "8.7 ضماناتنا والتزاماتنا المنصوص عليها في هذه الاتفاقية هي الضمانات والإقرارات الوحيدة التي قدمناها فيما يتعلق بهذه الاتفاقية والخدمات المقدمة على هذا الموقع وتحل محل أي ضمانات والتزامات تنشأ بأي طريقة وطريقة أخرى، سواء كانت مكتوبة أو شفهية، صريحة من أو ضمنا. كل هذه الضمانات والإقرارات لا تمثل سوى التزاماتنا وضماناتنا ولا تضمن التزام أي طرف ثالث بالضمانات والالتزامات الواردة في هذه الاتفاقية.",
        content105: "8.8 نحن لا نتنازل عن أي حقوق غير مذكورة في هذه الاتفاقية للحد من مسؤوليتنا عن الأضرار أو استبعادها أو تعويضها إلى أقصى حد القانون المعمول به.",
        content106: "8.9 بعد قيامك بالتسجيل، فإنك توافق على أية عمليات نقوم بها وفقاً للقواعد المنصوص عليها في هذه الاتفاقية، وأي مخاطر تتكبدها تتحملها أنت.",
        content107: "9. إنهاء الاتفاقية",
        content108: "9.1 يحق لهذا الموقع إنهاء جميع خدمات هذا الموقع وفقًا لهذه الاتفاقية، وسيتم إنهاء هذه الاتفاقية في التاريخ الذي يتم فيه إنهاء جميع خدمات هذا الموقع.",
        content109: "9.2 بعد إنهاء هذه الاتفاقية، لا يحق لك مطالبة هذا الموقع بمواصلة تقديم أي خدمات له أو أداء أي التزامات أخرى، بما في ذلك على سبيل المثال لا الحصر، مطالبة هذا الموقع بالاحتفاظ بأي معلومات أو الكشف عنها لك في حساب موقعه الأصلي. لك أو لطرف ثالث، حيث يقوم الطرف الثالث بإعادة توجيه أي معلومات لم يقرأها أو يرسلها.",
        content110: "9.3 لن يؤثر إنهاء هذه الاتفاقية على المسؤوليات الأخرى للطرف غير المخالف تجاه الطرف المخالف.",
        content111: "10. حقوق الملكية الفكرية",
        content112: "10.1 تشمل جميع الإنجازات الفكرية الواردة في هذا الموقع، على سبيل المثال لا الحصر، شعارات موقع الويب وقواعد البيانات وتصميمات مواقع الويب والنصوص والرسومات والبرمجيات والصور ومقاطع الفيديو والموسيقى والأصوات ومجموعات منها ومجموعات البرامج ورموز المصدر والبرامج ذات الصلة (بما في ذلك التطبيقات الصغيرة). ) حقوق الملكية الفكرية للبرامج والنصوص) مملوكة لهذا الموقع. لا يجوز لك إعادة إنتاج أو تغيير أو نسخ أو إرسال أو استخدام أي من المواد أو المحتوى المذكور أعلاه لأغراض تجارية.",
        content113: "10.2 جميع الحقوق الواردة في اسم هذا الموقع (بما في ذلك على سبيل المثال لا الحصر السمعة الطيبة والعلامات التجارية والشعارات) مملوكة للشركة.",
        content114: "10.3 تعتبر موافقتك على هذه الاتفاقية بمثابة مبادرتك لنقل حقوق الطبع والنشر لأي شكل من أشكال المعلومات التي تنشرها على هذا الموقع، بما في ذلك على سبيل المثال لا الحصر: حقوق الاستنساخ، حقوق التوزيع، حقوق التأجير، حقوق العرض، حقوق الأداء، حقوق العرض، حقوق البث، وحقوق نقل المعلومات عبر الإنترنت، وحقوق التصوير، وحقوق التكيف، وحقوق الترجمة، وحقوق التجميع وغيرها من الحقوق القابلة للتحويل التي يجب أن يتمتع بها صاحب حقوق الطبع والنشر، يتم نقلها حصريًا إلى هذا الموقع مجانًا، ويحق لهذا الموقع تقديم دعوى مستقلة رفع دعوى قضائية ضد أي موضوع بسبب التعدي والحصول على التعويض الكامل. تسري هذه الاتفاقية على أي محتوى عمل محمي بموجب قانون حقوق الطبع والنشر تقوم بنشره على هذا الموقع، سواء تم تكوين المحتوى قبل توقيع هذه الاتفاقية أو بعد توقيع هذه الاتفاقية.",
        content115: "10.4 لا يجوز لك استخدام أو التصرف في حقوق الملكية الفكرية لهذا الموقع أو غيره بشكل غير قانوني عند استخدام خدمات هذا الموقع. لا يجوز لك نشر أو السماح لمواقع (ووسائط) أخرى باستخدام المعلومات المنشورة على هذا الموقع بأي شكل من الأشكال.",
        content116: "11. حماية المعلومات",
        content117: 'تسري أحكام "شروط الخصوصية" المنشورة بشكل منفصل على هذا الموقع.',
        content118: "12. الحساب",
        content119: "لقد قمنا بالتحقق من جميع نتائج حساب المعاملات، وتم نشر جميع طرق الحساب على الموقع الإلكتروني، ولكن لا يمكننا ضمان عدم انقطاع استخدام الموقع أو خلوه من الأخطاء.",
        content120: "13. مراقبة الصادرات",
        content121: "أنت تفهم وتقر أنه وفقًا للقوانين ذات الصلة، لا يُسمح لك بتصدير أو إعادة تصدير أو استيراد أو نقل أي مواد (بما في ذلك البرامج) على هذا الموقع، لذا فإنك تضمن أنك لن تنفذ أو تساعد أو تشارك بشكل فعال في أي من الصادرات المذكورة أعلاه أو الأنشطة ذات الصلة التي تنتهك القوانين واللوائح والنقل أو غيرها من الانتهاكات للقوانين واللوائح المعمول بها؛ إذا تم اكتشاف مثل هذا الموقف، فيجب عليك إبلاغنا به في الوقت المناسب ومساعدتنا في التعامل معه.",
        content122: "14. نقل",
        content123: "الحقوق والالتزامات المنصوص عليها في هذه الاتفاقية تلزم أيضًا المتنازل لهم والورثة والمنفذين ومديري الأطراف الذين يستفيدون من هذه الحقوق والالتزامات. لا يجوز لك نقلها إلى أي طرف ثالث دون موافقتنا، ولكن يجوز لنا نقل حقوقنا والتزاماتنا في هذه الاتفاقية إلى أي طرف ثالث في أي وقت ونعطيك إشعارًا بذلك.",
        content124: "15. قابلية القسمة",
        content125: "إذا اعتبر أي حكم من أحكام هذه الاتفاقية غير قابل للتنفيذ أو غير صالح أو غير قانوني من قبل أي محكمة ذات اختصاص قضائي، فلن يؤثر ذلك على صحة الأحكام المتبقية من هذه الاتفاقية.",
        content126: "16. العلاقة غير المتعلقة بالوكالة",
        content127: "لا يوجد في هذه الاتفاقية ما يمكن اعتباره بمثابة إنشاء أو ضمني أو يشكلنا كوكيل أو وصي أو ممثل آخر لك، باستثناء ما هو منصوص عليه خلاف ذلك في هذه الاتفاقية.",
        content128: "17. الامتناع عن التصويت",
        content129: "لا يجوز اعتبار أو تفسير تنازلنا أو تنازلك عن مسؤولية أي طرف عن خرق العقد أو أي مسؤولية أخرى منصوص عليها في هذه الاتفاقية على أنه تنازل عن مسؤولية أخرى عن خرق العقد؛ ولا يجوز بأي حال من الأحوال عدم ممارسة أي حق أو تعويض يمكن تفسير الطريقة على أنها تنازل عن هذه الحقوق أو سبل الانتصاف الخاصة بالتخلي.",
        content130: "18. العنوان",
        content131: "جميع العناوين هي لتسهيل التعبير عن الاتفاقية فقط ولا تُستخدم لتوسيع أو تقييد محتوى أو نطاق شروط الاتفاقية.",
        content132: "19. القانون المعمول به",
        content133: "19.1 قبل إثارة نزاع أو مطالبة، فإنك تقر وتوافق على الاتصال بهذا الموقع أولاً عبر البريد الإلكتروني لحل النزاع بشكل غير رسمي. سنحاول حل نزاعك داخليًا في أقرب وقت ممكن؛ ويوافق الطرفان على حل النزاع من خلال المفاوضات بحسن نية (المناقشات) يجب أن تكون سرية ومحمية بموجب القواعد المعمول بها ولا تستخدم كدليل في أي إجراءات قانونية).",
        content134: '19.2 أنت تقر وتوافق على أنه في حالة وجود أي نزاع أو خلاف أو خلاف أو مطالبة، بما في ذلك وجود أو صحة أو تفسير أو أداء أو خرق أو إنهاء هذه الشروط أو أي نزاع ينشأ عن أو فيما يتعلق بهذه الشروط ("النزاع" ) ، يتعين على الأطراف المذكورة أعلاه تقديم النزاع إلى محكمة التحكيم الدولية التابعة لغرفة التجارة الدولية ("محكمة التحكيم الدولية لغرفة التجارة الدولية") وفقًا لـ "قواعد التحكيم لغرفة التجارة الدولية" السارية آنذاك. يجب أن يكون مكان التحكيم هو بليز ويتم تحديد قانون التحكيم المعمول به وفقًا لقواعد التحكيم لغرفة التجارة الدولية. ما لم يتفق الطرفان على خلاف ذلك، سيتم تعيين محكم واحد فقط بموجب قواعد التحكيم الخاصة بغرفة التجارة الدولية. يتمتع المحكم بسلطة حصرية للحكم في نطاق ولايته القضائية، بما في ذلك، على سبيل المثال لا الحصر، أي اعتراض على وجود أو نطاق أو صحة اتفاق التحكيم أو قابلية أي مطالبة أو دعوى مضادة للتحكيم. سيتم إجراء أي تحكيم باللغة الإنجليزية. وبغض النظر عن كيفية إجراء التحكيم، يجب على المحكم إصدار قرار كتابي معقول وكافي لشرح القرار والاستنتاجات الأساسية، إن وجدت، التي يستند إليها القرار. يكون قرار التحكيم نهائيًا وملزمًا للأطراف ويمكن تنفيذه في أي محكمة مختصة.',
        content135: "20. نفاذ الاتفاقية وتفسيرها",
        content136: "20.1 تدخل هذه الاتفاقية حيز التنفيذ عندما تقوم بالنقر فوق صفحة التسجيل في هذا الموقع للموافقة على التسجيل وإكمال عملية التسجيل والحصول على حساب وكلمة مرور لهذا الموقع، وهي ملزمة لكل من هذا الموقع ولك.",
        content137: "20.2 يعود حق التفسير النهائي لهذه الاتفاقية إلى هذا الموقع.",
        content138: "اعرف عميلك وسياسة مكافحة غسيل الأموال",
        content139: "1 المقدمة",
        content140: '1.1 نحن نعد بالامتثال الدقيق للقوانين واللوائح المتعلقة بـ "اعرف عميلك" ومكافحة غسيل الأموال ولن ننتهك عمدًا "سياسة اعرف عميلك ومكافحة غسيل الأموال". وفي نطاق سيطرتنا المعقولة، سنتخذ التدابير والتقنيات اللازمة لتزويدك بخدمات آمنة وحمايتك من الخسائر الناجمة عن سلوك غسيل الأموال للمشتبه بهم جنائيًا قدر الإمكان.',
        content141: '1.2 إن سياسة "اعرف عميلك" و"مكافحة غسيل الأموال" الخاصة بنا هي نظام سياسة دولي شامل يتضمن سياسات "اعرف عميلك" و"مكافحة غسيل الأموال" لمختلف الولايات القضائية التي تنتمي إليها.',
        content142: "2. تعرف على سياسات عملائك ومكافحة غسيل الأموال كما يلي：",
        content143: "2.1 نشر سياسات اعرف عميلك ومكافحة غسيل الأموال وتحديثها من وقت لآخر للوفاء بالمعايير التي تحددها القوانين واللوائح ذات الصلة؛",
        content144: "2.2 نشر وتحديث بعض المبادئ والقواعد التوجيهية لتشغيل هذا الموقع، وسيقوم موظفونا بتقديم الخدمات وفقًا لتوجيهات هذه المبادئ والقواعد؛",
        content145: "2.3 تصميم واستكمال إجراءات المراقبة الداخلية والرقابة على المعاملات، مثل التحقق من الهويات من خلال وسائل صارمة والترتيب لتشكيل فريق محترف ليكون مسؤولاً عن أعمال مكافحة غسيل الأموال؛",
        content146: "2.4 استخدام أساليب الوقاية من المخاطر لإجراء العناية الواجبة والإشراف المستمر على العملاء؛",
        content147: "2.5 مراجعة المعاملات التي تمت والتحقق منها بانتظام؛",
        content148: "2.6 الإبلاغ عن المعاملات المشبوهة إلى الجهات المختصة؛",
        content149: "2.7 سيتم الاحتفاظ بإثبات الهوية والعنوان وسجلات المعاملات لمدة ست سنوات على الأقل ويمكن تقديمها إلى السلطات التنظيمية دون إشعار آخر لك.",
        content150: "3. معلومات الهوية والتحقق منها",
        content151: "3.1 معلومات الهوية",
        content152: "3.1.1 وفقًا للوائح المختلفة في الولايات القضائية المختلفة وأنواع الكيانات المختلفة، قد يكون محتوى معلوماتك التي نجمعها غير متسق، ومن حيث المبدأ، سيتم جمع المعلومات التالية من الأفراد المسجلين:",
        content153: "المعلومات الشخصية الأساسية: اسمك وعنوانك (والعنوان الدائم، إذا كان مختلفًا)، وتاريخ الميلاد والجنسية وغيرها من المعلومات المتاحة. يجب أن يستند التحقق من الهوية إلى المستندات الصادرة عن السلطات الرسمية أو غيرها من السلطات المماثلة، مثل جوازات السفر أو بطاقات الهوية أو وثائق الهوية الأخرى المطلوبة والتي تطلبها ولايات قضائية مختلفة. سيتم التحقق من العنوان الذي تقدمه باستخدام الطرق المناسبة، مثل التحقق من تذكرة نقل الركاب أو مذكرة سعر الفائدة أو التحقق من السجل الانتخابي.",
        content154: "صورة صالحة: قبل التسجيل، يجب عليك تقديم صورة لنفسك وأنت تحمل بطاقة هويتك على صدرك؛",
        content155: "تفاصيل الاتصال: رقم الهاتف/الجوال و/أو عنوان بريد إلكتروني صالح.",
        content156: "3.1.2 إذا كنت شركة أو كيانًا قانونيًا آخر، فسنقوم بجمع المعلومات التالية للتعرف عليك أو على المستفيد النهائي من حساب الثقة الخاص بك.",
        content157: "شهادة تسجيل وتسجيل الشركة؛ نسخ من النظام الأساسي للشركة ومذكرتها؛ مواد تصديق مفصلة لهيكل أسهم الشركة ووصف الملكية، مما يثبت قرار مجلس الإدارة للمدير المفوض الذي يحدد فتح الحساب وتنفيذه على هذا الموقع؛ الشركة أعضاء مجلس الإدارة الذين يجب تقديمهم كما هو مطلوب، وثائق هوية المساهم الرئيسي والشخص المخول بالتوقيع على الحساب على هذا الموقع؛ عنوان العمل الرئيسي للشركة، إذا كان مختلفًا عن العنوان البريدي للشركة، قم بتوفير العنوان البريدي. إذا كان العنوان المحلي للشركة غير متوافق مع عنوان عملها الرئيسي، فإنها تعتبر عميلاً عالي المخاطر وستحتاج إلى تقديم وثائق إضافية إضافية.",
        content158: "اعتمادًا على اللوائح المختلفة في الولايات القضائية المختلفة وأنواع الكيانات المختلفة، نطلب شهادات ومستندات أخرى صادرة عن السلطات بالإضافة إلى المستندات التي نراها ضرورية.",
        content159: "3.1.3 نحن نقبل فقط الإصدارات الإنجليزية أو الصينية من معلومات الهوية. إذا لم يكن الأمر كذلك، فيرجى ترجمة معلومات هويتك إلى اللغة الإنجليزية وتوثيقها.",
        content160: "3.2 تأكيد التحقق",
        content161: "3.2.1 نطلب منك تقديم محتوى الصفحة الكاملة لوثيقة الهوية الخاصة بك.",
        content162: "3.2.2 نطلب صورة لك وأنت تحمل وثيقة هويتك على صدرك.",
        content163: "3.2.3 يجب بشكل عام التحقق من نسخ المستندات الداعمة ومقارنتها بالقسائم الأصلية. ومع ذلك، تكون النسخة مقبولة إذا كان بإمكان جهة تصديق مناسبة وجديرة بالثقة أن تشهد أن النسخة هي نسخة دقيقة وكاملة من المستند الأصلي. ويشمل هؤلاء المصدقون السفراء والمفوضين القضائيين والقضاة المحليين، وما إلى ذلك.",
        content164: "3.2.4 إن شرط تحديد المستفيد النهائي والسيطرة على الحساب هو تحديد الأفراد الذين يمتلكون العميل المباشر أو يتحكمون فيه في نهاية المطاف، و/أو إثبات أن المعاملات الجارية يتم تنفيذها نيابة عن الآخرين. إذا كان الأمر يتعلق بعمل تجاري، فيجب التحقق من هوية المساهمين الرئيسيين (مثل أولئك الذين يملكون 10% أو أكثر من حقوق التصويت). بشكل عام، يعتبر الاحتفاظ بنسبة 25% من الأسهم ضمن المخاطر العادية، ويجب التحقق من هوية المساهم، ويعتبر الاحتفاظ بنسبة 10% من الأسهم أو الاحتفاظ بمزيد من حقوق التصويت أو الأسهم حالة عالية المخاطر، والهوية يجب التحقق من صحة المساهمين.",
        content165: "4. مراقبة المعاملات",
        content166: "4.1 نقوم بتعيين وتعديل الحدود القصوى للمعاملات اليومية وسحب العملات من وقت لآخر بناءً على شروط الأمان والمعاملات الفعلية؛",
        content167: "4.2 إذا حدثت المعاملات بشكل متكرر لدى مستخدم مسجل أو كانت هناك ظروف غير معقولة، فسيقوم فريقنا المحترف بتقييم وتحديد ما إذا كانت هذه المعاملات مشبوهة؛",
        content168: "4.3 إذا قررنا أن المعاملة مشبوهة بناءً على حكمنا الخاص، فقد نتخذ إجراءات تقييدية مثل تعليق المعاملة، أو رفض المعاملة، أو حتى عكس المعاملة في أقرب وقت ممكن إن أمكن، والإبلاغ عنها إلى السلطة المختصة، ولكن لن يخطرك؛",
        content169: "4.4 نحن نحتفظ بالحق في رفض طلبات التسجيل المقدمة من الأشخاص من الولايات القضائية التي لا تلتزم بالمعايير الدولية لمكافحة غسيل الأموال أو الأشخاص الذين يمكن اعتبارهم أشخاصًا مكشوفين سياسيًا. ونحتفظ بالحق في تعليق أو إنهاء المعاملات المشبوهة وفقًا لحكمنا الوحيد في في أي وقت، ولكننا لا نقوم بذلك، ولا نخرق أي واجبات أو مسؤوليات مستحقة لك.",
    },
    register: {
        formTitle1: "يخلق",
        formTitle2: "قم بالتسجيل باستخدام بريدك الإلكتروني أو رقم هاتفك المحمول",
        formTitle3: "حساب",
        field1: "رمز التحقق",
        field2: "يحصل على",
        field3: "تأكيد كلمة المرور",
        field4: "شفرة الدعوة",
        field41: "（خياري）",
        field5: "لقد قرأت وأوافق",
        field6: "شروط الخدمة",
        placeholder1: "الرجاء إدخال رمز التحقق",
        content7: "الرجاء إدخال رمز التحقق",
        content12: "الرجاء إدخال رقم الهاتف",
        content13: "الرجاء إدخال كلمة المرور",
        content14: "يرجى إدخال البريد الإلكتروني الخاص بك",
        content18: "الرجاء إدخال كلمة المرور مرة أخرى",
        content19: "يرجى توافق على شروط الخدمة",
        content20: "الرجاء إدخال رمز التحقق الصحيح",
        content23: "هل ما زلت لم تتلق رمز التحقق الخاص بك؟",
        content24: "الرجاء إدخال رمز التحقق أدناه",
        registerSuccess: "نجاح التسجيل",
        content25: "كلمات المرور غير متناسقة",
        content26: "الرجاء إدخال عنوان البريد الإلكتروني الصحيح",
        content27: "تمت إعادة تعيين كلمة المرور بنجاح"
    },
    download: {
        content1: "تداول في أي وقت وفي أي مكان",
        content2: "متوافقة تمامًا مع محطات متعددة، مما يلبي احتياجات المعاملات لمختلف السيناريوهات في أي وقت",
        content3: "تحقق من ظروف السوق في الوقت الحقيقي في أي وقت",
        content4: "قم بشراء وبيع BTC وETH وXRP والأصول الرقمية الأخرى بسهولة",
        content5: "احصل على تحذيرات مبكرة بشأن التغيرات في أسعار العملات المشفرة التي تهمك",
        content6: "عرض قرض البيتكوين الفوري، والقروض الآجلة، وأسعار الخيارات",
        content7: "قارن أسعار العملات المشفرة في البورصات المختلفة",
        title: "تحميل",
        install: "ملاحظات التركيب",
        one: "1、السماح بتنزيل ملفات الوصف",
        two: "2、تم تنزيل ملف الوصف",
        three: "3、انتقل إلى [الإعدادات] على هاتفك وابحث عن [عام]",
        four: "4、الملفات الشخصية وإدارة الأجهزة",
        five: "5、انقر على الملف الشخصي",
    },
    identity: {
        noAuthentication: {
            title: "شهادة جونيور",
            pleaseSelectYourCountry: "الرجاء تحديد بلدك",
            name: "اسم",
            enterName: "من فضلك أدخل إسمك",
            city: "مدينة",
            enterCity: "الرجاء إدخال مدينتك",
            IDNumber: "رقم الهوية",
            enterIDNumber: "الرجاء إدخال رقم الهوية الخاص بك",
        },
        noAdvancedAuthentication: {
            title: "شهادة متقدمة",
            infoCountry: "البلد / المنطقة",
            infoName: "اسم",
            infoNumber: "رقم الهوية",
            infoAddress: "عنوان",
        },
        text: "غير معتمد",
        recertification: "إعادة الاعتماد",
        certification: "شهادة",
        authenticationInProgress: "تم التحقق",
        inAuthentication: "جاري التصديق",
        uploadTheFrontOfTheDocument: "قم بتحميل الجزء الأمامي من بطاقة الهوية",
        uploadTheReverseSideOfTheDocument: "قم بتحميل الجانب الخلفي من الشهادة",
        uploadAPhotoOfAHandHeldID: "قم بتحميل صورة لبطاقة الهوية الخاصة بك في متناول اليد",
        pleaseUpload: "لو سمحت ",
        pleaseCompleteOrdinaryCertificationFirst: "بحاجة إلى الانتظار لنجاح مراجعة الشهادات الأولية",
        refused: "مرفوض",
        reason: "سبب",
        succeeded: "نجح",
        uploading: "تحميل",
        newTitle: "اختر البلد الذي تم إصدار هويتك فيه",
        pleaseOrdinaryCertificationFirst: "يرجى أداء الشهادة الأساسية أولا",
        text1: "تعديل الصورة الرمزية",
        text2: "كنية",
        text3: "حساب",
        text4: "تعديل اللقب",
        text5: "لقب جديد",
        text6: "أدخل لقبك الجديد",
        text7: "كلمة المرور القديمة",
        text8: "أدخل كلمة المرور القديمة الخاصة بك",
        text9: "كلمة المرور الجديدة",
        text10: "أدخل كلمة المرور الجديدة",
        text11: "تأكيد كلمة المرور",

        text12: "أدخل كلمة المرور الجديدة مرة أخرى",
        text13: "صندوق بريد جديد",
        text14: "رمز التحقق",
        text15: "الرجاء إدخال رمز التحقق",
        text16: "رقم الهاتف المحمول",
        text17: "رقم الهاتف المحمول الجديد",
        text18: "يرجى إدخال رقم الهاتف الخاص بك",
        text19: "رمز التحقق من الهاتف المحمول",
        text20: "شهادة جونيور",
        text21: "يرجى التحقق من معلومات هويتك",
        text22: "دولة",
        text23: "مدينة",
        text24: "أدخل مدينتك",
        text25: "أدخل أسمك",
        text26: "رقم الهوية الخاص بك",
        text27: "أدخل رقم الهوية الخاص بك",
        text28: "صورة أمامية لبطاقة الهوية",
        text29: "انقر لتحميل المستندات الخاصة بك",
        text30: "إعادة التحميل",
        text31: "رفع",
        text32: "يحمل صورة الهوية",
        text33: "انقر لتحميل صورة هويتك القابضة",
        text34: "لقد قمت بربط Google Authenticator",
        text35: "مفتاح",
        text36: "الرجاء إدخال رمز التحقق الخاص بجوجل",
        text37: "يرسل",
        text38: "لايمكن ان يكون فارغا",
        text39: "تنسيق البريد الإلكتروني خاطئ",
        text40: "كلمتا مرور غير متناسقتين",
        text41: "تم النسخ بنجاح",
        text42: "فشل النسخ",
        text43: "تعديل الصورة الرمزية",
        text44: "تغيير كلمة المرور",
        text45: "ربط البريد الإلكتروني",
        text46: "تعديل البريد الإلكتروني",
        text47: "ربط الهاتف المحمول",
        text48: "تعديل الهاتف المحمول",
        text49: "التحقق من جوجل",
        text50: "ربط أداة مصادقة Google",
        text51: "أدخل رمز التحقق الخاص بجوجل",
        text52: "يحفظ",
        text53: "تأكيد التقديم",
        text54: "يراجع",
        text55: "ربط",
        text56: "قم بإيقاف تشغيل التحقق من جوجل",
        text57: "الخطوة التالية",
        text58: "ينهي",
        text59: "التحقق من جوجل ملزم",
        text60: "لقد تم تفكيك التحقق من Google",
        text61: "تم تقديم الشهادة الابتدائية",
        text62: "تم تقديم الشهادة المتقدمة",
        text63: "لم يتم تحميل أي صورة",
        text64: "إعادة إرسال",
        text65: "يرجى إدخال اسم مستعار",
        text66: "تم حفظ اللقب بنجاح",
        text67: "لم يتم تحميل أي صور حتى الآن",
        text68: "اكتملت إعادة تعيين كلمة المرور",
        text69: "تم تعديل البريد الإلكتروني بنجاح",
        text70: "تم حفظ الصورة الرمزية بنجاح",
        text71: "تحميل الصور",
        text72: "صندوق البريد الخاص بك",
        text73: "أدخل بريدك الإلكتروني الجديد",
        text74: "اسمك",
        text75: "انتقل إلى المصادقة",
        text76: "يرجى التحقق من صورة بطاقة الهوية الخاصة بك"
    },
    retrievePassword: {
        formTitle1: "إعادة تعيين كلمة المرور",
        formTitle2: "من أجل سلامة أصولك، يُحظر إجراء المعاملات خلال 24 ساعة بعد تغيير كلمة المرور.",
        formTitle3: "استرداد كلمة المرور",
        formTitle4: "تأكيد التغييرات",
        formTitle5: "الرجاء إدخال كلمة المرور القديمة",
        formTitle6: "الرجاء إدخال كلمة مرور جديدة"
    },
    home: {
        title1: "منصة تداول العملات المشفرة الأكثر شهرة",
        title3: "لماذا ",
        title4: " إنها منصة blockchain مفتوحة المصدر عالية الأداء مصممة لدعم وتشغيل بنية تحتية رقمية آمنة ومتوافقة ويمكن التنبؤ بها.",
        field1: "التبادل القائم",
        field2: "الخدمات المالية للأصول الرقمية",
        field3: "عشرات الملايين من المستخدمين",
        field4: "إنشاء صناديق متعددة لحماية المستثمرين",
        field5: "التداول السريع",
        field6: "تستطيع CME Gruop مطابقة الطلبات بزمن وصول منخفض للغاية للحصول على تجربة تداول مثالية.",
        title5: "اتجاه السوق",
        title6: "شاهد المزيد",
        table1: "عملة",
        table2: "أحدث السعر",
        table3: "24H صعودا وهبوطا",
        table4: "حجم التداول على مدار 24 ساعة",
        table5: "حجم المعاملات على مدار 24 ساعة",
        table6: "يقتبس",
        title7: "عن ",
        title8: " نحن ملتزمون بتزويد المستخدمين بأدوات التداول الأكثر تقدمًا والتكنولوجيا المبتكرة وخدمة العملاء ذات الجودة التي لا مثيل لها.",
        title9: "إن مجال الأصول الرقمية يتغير مع مرور كل يوم، ولا يمكننا مواكبة وتيرة الابتكار التكنولوجي إلا من خلال بعد النظر وإحراز تقدم سريع.",
        title901: " توفير خدمات تداول الأصول الرقمية الأكثر تقدمًا لمستخدمينا ومزودي السيولة العالميين.",
        title10: "نحن نؤمن إيمانًا راسخًا بأن كل مستخدم يستحق أفضل تجربة تداول.",
        title11: "الأمان الذي يمكنك الوثوق به",
        title12: "إن إجراءاتنا الأمنية المتطورة وأموال SAFU تحمي أصولك الرقمية من جميع المخاطر. ",
        title13: "أفضل أسعار المعاملات",
        title14: "أسعار معاملات تفضيلية، وحقوق VIP تنافسية، والتمتع بأفضل الخدمات جودة.",
        title15: "دعم خدمة العملاء 24*7 ساعات",
        title16: "24/7 في جميع الأحوال الجوية، وضع التشغيل بدوام كامل، وسوف نقوم بالرد على استفساراتك ذات الصلة في أقرب وقت ممكن.",
        title17: "0 رسوم التداول",
        title18: "استخدم طرق دفع متعددة لتداول العملات الرقمية بدون رسوم معالجة، بشكل آمن وسريع.",
        title19: "تداول في أي وقت وفي أي مكان",
        title20: "متوافقة تمامًا مع محطات متعددة، مما يلبي احتياجات المعاملات لمختلف السيناريوهات في أي وقت",
        title21: "تحقق من ظروف السوق في الوقت الحقيقي في أي وقت",
        title22: "قم بشراء وبيع BTC وETH وXRP والأصول الرقمية الأخرى بسهولة",
        title23: "احصل على تحذيرات مبكرة بشأن التغيرات في أسعار العملات المشفرة التي تهمك",
        title24: "عرض قرض البيتكوين الفوري، والقروض الآجلة، وأسعار الخيارات",
        title25: "قارن أسعار العملات المشفرة في البورصات المختلفة",
        title26: "أفتح حساب الأن",
        title27: "دولار",
        title28: "حجم التداول على مدار 24 ساعة",
        title29: "مزود السيولة المتكامل",
        title30: "مستخدم",
        title31: "أكثر",
        title32: "يضيف Binance Loan CTSI وKSM كأصول ضمان، ويطلق خصومات على تخفيض أسعار الفائدة BUSD وUSDT!",
        title33: "منفعتنا",
        title34: "آمنة ومستقرة",
        title35: "لا يزال بإمكان الفريق الفني الأعلى، والحماية الأمنية الشاملة، والبحث المستقل والتطوير لمحرك توليف المعاملات عالي السرعة، العمل بشكل مستقر وموثوق في ظل المعاملات المتزامنة الضخمة.",
        title36: "الامتثال المهني",
        title37: "يحمل فريق التشغيل المحترف، الذي يتمتع بسنوات عديدة من الخبرة في مجال blockchain والتمويل، رخصة تداول الأصول الرقمية المتوافقة، ولديه ضمان احتياطي بنسبة 100٪.",
        title38: "المستخدم أولا",
        title39: "مواجهة السوق العالمية، ودعم لغات متعددة، والعمل على مدار الساعة طوال أيام الأسبوع، ودعم المجتمع القوي، وخدمة العملاء الاحترافية.",
        title40: "أداء عالي",
        title41: "300000 معاملة في الثانية وزمن استجابة الطلب أقل من 1 مللي ثانية.",
        title42: "معلومات عنا",
        title43: "تداول في أي وقت وفي أي مكان",
        title44: "متوافقة تمامًا مع محطات متعددة، مما يلبي احتياجات المعاملات لمختلف السيناريوهات في أي وقت",
        title45: "（مسح الرمز للتحميل）",
        title46: "أخبار العملات الرقمية",
        title47: "شريكنا التجاري",
        title48: "قائمة الخاسرين",
        title49: "دوران 24 ساعة",
        title50: "24 ساعة زيادة ونقصان"
    },
    quotation: {
        marketTrading: "يقتبس",
        turnover: "مقدار",
        optional: "خياري",
        contractMarket: "سوق العقود",
        currencyPair: "زوج العملات",
        latestPrice: "سعر نهائي",
        upsAndDowns: "تغيير الاقتباس",
        highest: "الأعلى",
        lowest: "أدنى",
        transactionAmount: "حجم الصفقة",
        operate: "العمل",
        buy: "تجارة",
        topTurnovers: "قائمة المعاملات",
        topGainers: "قائمة الرابح",
        tradingPair: "زوج التداول",
        buyOrder: "يشتري",
        sellOrder: "أمر بيع"
    },
    accountChange: {
        demo: "محاكاة",
        realAccount: "الحساب الفعلي",
        demoAccount: "حساب تجريبي",
        accountRole: "دور الحساب",
        pleaseSelect: "اختر من فضلك",
        AccountChangeType: "نوع تغيير الحساب",
        AccountType: "نوع الحساب",
        currencyType: "نوع العملة",
        allType: "كل الانواع",
        allAccount: "جميع الحسابات",
        allCoin: "جميع العملات",
        coin: "عملة",
        amount: "تغيير المبلغ",
        balance: "رصيد الحساب",
        time: "وقت",
        title: "سجلات تغيير الحساب",
        filtrar: "منقي",
        check: "التبديل إلى",
        showOnly: "عرض مقتنيات العملة فقط",
        holdAssets: "رصيد العملة",
        accountList: "قائمة الحساب"
    },
    contractTransaction: {
        chart: "جدول",
        basicEdition: "النسخة الأساسية",
        depthMap: "خريطة العمق",
        second: "ثانية",
        minute: "نقطة",
        day: "سماء",
        week: "أسبوع",
        month: "قمر",
        open: "يفتح",
        trade: "تجارة",
        buyLong: "شراء طويل",
        buyShort: "شراء قصير",
        cannotBuy: "لا تدعم هذه العملة معاملات عقود التسليم",
        cannotBuy1: "هذه العملة لا تدعم التداول الدائم",
        tradingAssets: "أصول التداول",
        amountAvailable: "الكمية متاحة",
        timeStr: "وقت",
        transactionPeriod: "فترة التداول",
        quantity: "كمية",
        enterQuantity: "الرجاء إدخال الكمية",
        buyIn: "ذهاب لفتره طويلة",
        latestTransaction: "أحدث الصفقة",
        time: "وقت",
        direction: '方اتجاه: "اتجاه"،向',
        price: "سعر",
        sell: "قصير",
        positionOrder: "أمر التسليم",
        historicalOrders: "أوامر تاريخية",
        product: "منتج",
        realAccount: "(حساب حقيقي)",
        demoAccount: "(حساب تجريبي)",
        openingTime: "وقت الطلب",
        openingPrice: "سعر الافتتاح",
        curretPrice: "السعر الحالي",
        liquidationPrice: "سعر نهائي",
        profitOrLoss: "الربح والخسارة",
        endTime: "وقت الإغلاق",
        areYouSureToBuy: "هل أنت متأكد أنك تريد الشراء؟",
        areYouSureToLongBuy: "هل أنت متأكد أنك تريد أن تذهب لفترة طويلة؟",
        areYouSureToShortBuy: "هل أنت متأكد أنك تريد الشراء على المكشوف؟",
        successfullyBought: "شراء بنجاح",
        minBuy: "الحد الأدنى للشراء",
        assets: "أصول",
        withdraw: "ينسحب",
        entrustedOrder: "طلب",
        total: "المجموع",
        continueToPlaceOrder: "الاستمرار في تقديم الطلب",
        orderConfirmation: "تأكيد الطلب",
        buyRu: "يشتري",
        sellChu: "يبيع",
        areYouSureToSell: "هل أنت متأكد أنك تريد البيع؟",
        operation: "العمل",
        buyCover: "موقف الغطاء",
        commitSuccess: "عملية ناجحة",
        onlyEnterIntegers: "يمكن إدخال الأعداد الصحيحة فقط",
        trend: "اتجاه",
        lotteryTime: "الموعد النهائي للطلب",
        countdown: "العد التنازلي",
        period: "مشكلة",
        cycle: "فترة زمنية",
        unlimited: "غير محدود",
        put: "شراء وضع",
        call: "شراء اتصل",
        pending: "في لجنة",
        unopened: "لم يتم رسمها بعد",
        opened: "تم سحب الجائزة",
        settlementPrice: "سعر التسوية",
        rateOfReturn: "معدل العائد",
        currentPositions: "الموقف الحالى",
        positionQuantity: "كمية الموقف",
        guarantee: "هامِش",
        text1: "يعلو",
        text2: "يسقط",
        text3: "في انتظار الشراء",
        text4: "كمية الطلب",
        text5: "عدد المعاملات",
        text6: "يلغي",
        text7: "موعد التسليم",
        text8: "تذكير بالإلغاء",
        text9: "هل أنت متأكد أنك تريد إلغاء الطلب؟",
        text10: "كمية غير قانونية",
        text11: "تذكير الطلب",
        text12: "هل أنت متأكد من أنك تشتري صعودي؟",
        text13: "هل أنت متأكد من شراء المكان؟",
        text14: "تم الإلغاء بنجاح"
    },
    mining: {
        title1: "منصة تعدين الأصول الرقمية الرائدة في العالم بتقنية blockchain",
        title2: "مفتوحة وشفافة · آمنة ومستقرة",
        title3: "الرجاء تحديد العملة",
        title4: "التعدين العائد السنوي",
        title5: "سماء",
        title6: "فترة التعدين",
        title7: "تفاصيل المنتج",
        btn: "الألغام الآن",
        btn2: "إنهاء",
        tabs1: "في تَقَدم",
        tabs2: "مستردة",
        table1: "اسم المنتج",
        table2: "فترة التعدين",
        table3: "وقت التعدين",
        table4: "كمية التعدين",
        table5: "تاريخ انتهاء الصلاحية",
        table6: "يصل إلى مرحلة النضج",
        table7: "حالة الطلب",
        egularMining: "التعدين بانتظام",
        inProgress: "في تَقَدم",
        redeemed: "مستردة",
        redemption: "الخلاص",
        regularMining: "التعدين بانتظام",
        earlyRedemption: "الفداء المبكر",
        need: "يحتاج",
        handlingFee: "رسوم المعالجة",
        redemptionConfirmationMessage: "يرجى تأكيد ما إذا كنت تريد الاسترداد مقدمًا？",
        form1: "التعدين العائد السنوي",
        form2: "فترة التعدين",
        form3: "عملة التعدين",
        form4: "مبلغ الاشتراك (الحد الأدنى/الحد الأقصى)",
        form5: "رسوم الاسترداد المبكر: ",
        form6: "سعر الشراء",
        form7: "الأموال المتاحة",
        form8: "سعر الشراء",
        form9: "الأموال المتاحة",
        form10: "الرجاء إدخال مبلغ الشراء",
        form11: "الجميع",
        form12: "الإيرادات المقدرة",
        form13: "عدم كفاية الأموال المتاحة",
        form14: "الرجاء إدخال مبلغ الشراء",
        form15: "طلبي",
        form16: "دخل",
        form17: "تفاصيل الطلب",
        form18: "تعليمات الاسترداد المبكر:",
        form19: "مبلغ الاشتراك (الحد الأدنى)",
        form20: "مبلغ الاشتراك (الحد الأقصى)",
        form21: "الكمية المحتفظ بها",
        form22: "شراء الوقت",
        form23: "وقت الفداء",
        form24: "زيادة",
        form25: "تم الاشتراك بنجاح",
        form26: "تم الفداء بنجاح",
        form27: "إدارة مالية سهلة مع عوائد تزيد عن 25%، متفوقة على السوق، ويمكنك أيضًا الحصول على مكافآت بعد الشراء",
        form28: "قم بدعوة الأصدقاء للحصول على مكافأة قدرها 50 دولارًا أمريكيًا، ويمكن أيضًا أن تعود قسائم العقد بعمولة بنسبة 20٪."
    },
    assets: {
        content1: "أصول الحساب",
        content2: "أرباح اليوم",
        content3: "الحساب الحالي",
        content4: "الحساب الفعلي هو أصول حسابك الفعلي، والتي يمكن استخدامها للمعاملات والسحوبات.",
        content5: "سحب العملات المعدنية",
        content6: "إيداع العملات المعدنية",
        content7: "تبادل",
        content8: "تحويل",
        content9: "اختر من فضلك",
        content10: "حساب تجريبي",
        content11: "الحساب الفعلي",
        content12: "الحصول على الأصول",
        content13: "هل أنت متأكد أنك تريد التبديل إلى حساب حقيقي؟",
        content14: "هل أنت متأكد أنك تريد التبديل إلى حساب تجريبي؟",
        content15: "أحصل على النجاح",
        table1: "عملة",
        table2: "كمية",
        table3: "تجميد",
        table4: "متاح",
        dialog1: "تفاصيل المنتج",
        dialog2: "1、يُسمح باستخدام هذا الأصل فقط في وضع الحساب التجريبي",
        dialog3: "2، لمنع إساءة الاستخدام، يُسمح لك باستلامها مرة واحدة فقط خلال الشهر.",
        dialog4: "3、سيؤدي استلام الأصول إلى إضافة مبلغ الأصول المستلمة على الأساس الأصلي ولن يغير حالة المركز.",
        dialog5: "4、إذا كان لديك أي أسئلة أخرى، يرجى الاتصال بخدمة العملاء",
        dialog6: "إنهاء",
        dialog7: "احصل على الأصول التجريبية الآن",
        dialog8: "نقل في",
        dialog9: "انقله خارجا",
        dialog10: "يُحوّل",
        dialog11: "تقييم",
        dialog12: "الأصول التجريبية",
        dialog13: "حسابي",
        dialog14: "إجمالي أصول الحساب",
        dialog15: "نظرة عامة على الأصول",
        dialog16: "إجمالي تقييم الأصول",
        dialog17: "تقييم الأصول",
        dialog18: "أرباح اليوم",
        dialog19: "تجميد الأموال",
        dialog20: "يمكنك الحصول على حصة الأصول المحاكاة كل شهر",
        dialog21: "يستلم",
        dialog22: "لقد تلقيت بنجاح",
        dialog23: '30 يوما الدخل التراكمي',
        dialog24: "المنتج المالي",
        dialog25: "إدارتي المالية",
        dialog26: "الإدارة المالية التاريخية",
        dialog27: "سجل التاريخ",
        dialog28: "سجلات التمويل"
    },
    validator: {
        dialog14: "مدقق",
        dialog15: "ربط المدقق",
        dialog16: "تبديل المدقق",
        dialog17: "رمز التحقق",
        dialog18: "رمز الاستجابة السريعة المدقق",
        dialog19: "مفتاح التحقق من الصحة",
        dialog20: "يحفظ",
        dialog21: "الرجاء اختيار المدقق",
        dialog22: "مفتاح النسخ",
        dialog23: "حفظ بنجاح",
        dialog24: "الرجاء إدخال رمز التحقق",
        dialog25: "تذكير ودود: قبل تأكيد الحفظ، يرجى فتح التطبيق المقابل ومسح رمز الاستجابة السريعة أدناه أو إضافة المفتاح يدويًا!",
        dialog26: "يؤدي تمكين أداة المصادقة إلى توفير الحد الأقصى من الأمان لحسابك.",
        dialog27: "ستحتاج إلى إدخال رمز تحقق فريد يمكن الوصول إليه عبر هاتفك المحمول في كل مرة تقوم فيها بتسجيل الدخول أو سحب الأموال.",
        dialog28: "إعداد أداة التحقق من الصحة",
        dialog29: "يساعد",
        dialog30: "لم تقم بربط رمز المصادقة. احصل على حماية أقوى مع الموثق.",
        dialog31: "لم يتم إعداد أداة التحقق من الصحة بعد، يرجى إعدادها أولاً",
        dialog32: "اذهب للاعدادات"
    },
    recharge: {
        title1: "لست متأكدًا من كيفية الشراء باستخدام العملة الرقمية؟",
        title2: "فقط ثلاث خطوات سهلة!",
        title3: "حدد العملة الرقمية التي ترغب في شرائها من القائمة المنسدلة وأدخل كمية الشراء أو المبلغ.",
        title4: "اختر طريقة الدفع المفضلة لديك",
        title5: "بعد تأكيد الدفع، سيتم تحويل العملة الرقمية التي اشتريتها تلقائيًا إلى حسابك.",
        title6: "الرجاء تحديد العملة التي تريد إعادة شحنها",
        title7: "سجل الودائع",
        table1: "عملة",
        table2: "كمية",
        table3: "اسم السلسلة",
        table4: "ولاية",
        table5: "سبب",
        table6: "وقت",
        table7: "عرض القسيمة",
        dialog1: "الرجاء إدخال رمز التحقق أدناه",
        dialog2: "رمز الدفع",
        dialog3: "ينسخ",
        dialog4: "عنوان",
        dialog5: "قيمة الايداع",
        dialog6: "لقطة شاشة للمعاملة",
        dialog7: "ملاحظة هامة",
        dialog8: "يرجى ملء مبلغ الوديعة",
        dialog9: "تحميل الصور",
        dialog10: "الرجاء إدخال المحتوى",
        dialog11: "يرجى تحميل لقطات من إعادة الشحن",
        dialog12: "لم تستعرض",
        dialog13: "مرفوض",
        dialog14: "المودعة",
        tip1: "تم الإرسال بنجاح",
        tip2: "تم النسخ بنجاح",
        tip3: "فشل النسخ",
        tip4: "الرجاء تحديد العملة التي تريد إعادة شحنها",
        tip5: "1.عنوان الإيداع أعلاه هو عنوان الدفع الرسمي للمنصة، يرجى البحث عن عنوان الإيداع الرسمي للمنصة، وستكون مسؤولاً عن فقدان الأموال بسبب الإيداع الخاطئ؛",
        tip6: "2.يرجى التأكد من أمان جهاز الكمبيوتر والمتصفح الخاص بك لمنع التلاعب بالمعلومات أو تسريبها؛",
        tip7: "3.بعد إعادة شحن العنوان أعلاه، يجب تأكيد عقدة الشبكة بالكامل قبل إضافة الحساب؛",
        tip8: "4.يرجى تحديد نظام الرمز المميز والعملة أعلاه وتحويل المبلغ المقابل للإيداع. يرجى عدم تحويل أي أصول أخرى غير ذات صلة، وإلا فلن يتم استردادها.",
        tip9: "حفظ رمز الاستجابة السريعة",
        tip10: "فوق حتى",
        tip11: "ملاحظة هامة",
        tip12: "روابط خارج الموقع",
        tip13: "إعادة شحن رمز الاستجابة السريعة",
        tip14: "الخطوة التالية",
        tip15: "سجل إعادة الشحن",
        tip16: "تفاصيل إعادة الشحن",
        tip17: "عنوان الإيداع",
        tip18: "الدفع السريع",
        tip20: "رصيد غير كاف",
        tip22: "تم الإرسال، في انتظار التأكيد",
        tip23: "في انتظار الدفع",
        tip24: "كمية",
        tip25: "تم إرسال الدفعة، في انتظار تأكيد الحظر",
        tip26: "تم رفض الدفع",
        tip27: "فشلت عملية الدفع",
        tip28: "تم الدفع بنجاح",
        bankChannel: "قناة البطاقة المصرفية",
        tip29: "عمليات البحث التاريخية",
        tip30: "قائمة العملات",
        tip31: "اختر شبكة",
        tip32: "عند إعادة شحن هذه العملة، يرجى إعادة الشحن فقط من خلال الشبكات المدعومة أدناه. إذا قمت بإعادة الشحن من خلال شبكات أخرى، فسيتم فقدان الأموال.",
        tip33: "عند سحب هذه العملة، يرجى السحب فقط من خلال الشبكات المدعومة أدناه. إذا قمت بالسحب من خلال شبكات أخرى، سيتم فقدان الأموال.",
        tip34: "شحن سريع",
        tip35: "مسح الرمز لإعادة الشحن",
        tip36: "إعادة الشحن اليدوي",
        tip37: "شبكة إعادة الشحن",

        tip38: "عنوان إعادة الشحن",
        tip39: "مبلغ إعادة الشحن",
        tip40: "تحميل إيصال المعاملة",
        tip41: "تحميل الصور",
        tip42: "تأكيد التقديم",
        tip43: "تأكيد الدفع",
        tip44: "ملحوظة: يرجى تأكيد العنوان الذي تحتاجه لإعادة الشحن، فنحن لسنا مسؤولين عن فقدان الأموال إذا قمت بملئه بشكل غير صحيح.",
        tip45: "يتذكر",
        tip46: "تم نسخ العنوان بنجاح!",
        tip47: "فشل نسخ العنوان!",
        tip48: "الرصيد المتاح غير كافي",
        tip49: "تم إرسال عملية إعادة الشحن بنجاح وهي في انتظار التأكيد على السلسلة.",
        tip50: "فشلت عملية إعادة الشحن",
        tip51: "مبلغ إعادة الشحن خاطئ",
        tip52: "فشل الاتصال",
        tip53: "لم يتم تسجيل الدخول إلى المحفظة",
        tip54: "رفض اتصال",
        tip55: "تم الإرسال بنجاح، في انتظار مراجعة خدمة العملاء والموافقة عليها",
        tip56: "يرجى تحميل لقطة شاشة لإيصال المعاملة",
        tip57: "فشل التقديم",
        tip58: "سجل إعادة الشحن",
        tip59: "سجل عمليات السحب",
        tip60: "إعادة شحن العملة",
        tip61: "عملة السحب",
        tip62: "عنوان إعادة الشحن",
        tip63: "تظهر لقطة الشاشة",
        tip64: "انقر لتكبير الصورة",
        tip65: "الوقت إعادة تعبئة الرصيد",
        tip66: "سبب الرفض",
        tip67: "كمية السحب",
        tip68: "تم استلام المبلغ",
        tip69: "شبكة السحب النقدي",
        tip70: "عنوان السحب",
        tip71: "وقت الانسحاب",
        tip72: "إنهاء",
        tip73: "لقطة شاشة للمعاملة",
        tip74: "في انتظار المراجعة",
        tip75: "مرفوض",
        tip76: "مكتمل",
        tip77: "مرفوض",
        tip78: "مدققة",
        tip79: "صرف",
        tip80: "يستخرج",
        tip81: "شبكة النقل",
        tip82: "العنوان/الفاتورة",
        tip83: "سحب الكل",
        tip84: "يرجى عدم سحب الأموال مباشرة إلى عنوان التمويل الجماعي أو ICO، وإلا فلن تتمكن من استلام الرموز الصادرة عن التمويل الجماعي أو ICO.",
        tip85: "وصول الكمية",
        tip86: "تأكيد الانسحاب",
        tip87: "عنوان السحب خاطئ",
        tip88: "مبلغ السحب خاطئ",
        tip89: "المبلغ المراد سحبه على الأقل",
        tip90: "الحد الأقصى لمبلغ السحب هو",
        tip91: "تم تقديم طلب السحب",
        tip92: "قائمة العناوين",
        tip93: "لم تقم بإضافة عنوان بعد",
        tip94: "أضفه الآن",
        tip95: "اضف عنوان",
        tip96: "إضافة عنوان جديد",
        tip97: "اختر من فضلك",
        tip98: "عنوان",
        tip99: "الرجاء تحديد شبكة النقل",
        tip100: "الرجاء إدخال العنوان",
        tip101: "تمت إضافة العنوان بنجاح",
        tip102: "في الصفقة",
    },
    fundExchange: {
        title1: "كيفية تبادل الأموال؟",
        title2: "حدد العملة المناسبة ليتم تحويلها.",
        title3: "حدد العملة المناسبة للتحويل.",
        title4: 'أدخل المبلغ المراد استبداله وانقر فوق "إرسال" لإكمال عملية تبادل الأموال.',
        title5: "من",
        title6: "يصل",
        title7: "كمية التبادل",
        title8: "سعر الصرف",
        title9: "متاح",
        title10: "الرجاء إدخال كمية الاسترداد",
        title11: "لا يوجد زوج تداول مطابق للتبادل.",
        title12: "الرجاء إدخال الكمية المراد استبدالها",
        title13: "تم الفداء بنجاح",
        title14: "سجل الصرف",
        table1: "استخدم العملة",
        table2: "كمية",
        table3: "سعر الصرف",
        table4: "صرف العملة",
        table5: "إيصال",
        table6: "وقت",
        title15: "من",
        title16: "لا توجد أصول متاحة حاليا، يرجى اختيار آخر"
    },
    fundTransfer: {
        title1: "كيفية تحويل الأموال؟",
        title2: "اختر الحساب الجاري المراد تحويله.",
        title3: "حدد الحساب الذي تريد نقله.",
        title4: 'أدخل مبلغ التحويل ثم انقر فوق "إرسال" لإكمال التحويل.',
        title5: "عملة",
        title6: "نقل الكمية",
        title7: "الرجاء إدخال المبلغ المراد تحويله",
        title8: "سجل النقل",
        title9: "الرجاء تحديد نوع المحفظة",
        title10: "الرجاء تحديد العملة",
        title11: "الرجاء تحديد نوع المحفظة",
        title12: "الرجاء تحديد العملة",
        title13: "الرجاء إدخال المبلغ المراد تحويله",
        title14: "تم الإرسال بنجاح",
        table1: "نقل خارج الحساب",
        table2: "تحويل العملة",
        table3: "نقل الكمية",
        table4: "حساب النقل",
        table5: "وقت",
        table6: "تحويل",
        table7: "تحويل الأموال",
        table8: "حول إلى",
        table9: "سجل النقل",
        table10: "تفاصيل النقل",
        table11: "تم النقل بنجاح",
        table12: "فشل النقل",
        table13: "لا يمكن تحويل الحساب الجاري"
    },
    withdrawal: {
        title1: "ملاحظة هامة",
        title2: "1.من أجل منع المراجحة، يمكنك التقدم بطلب لسحب العملة عندما يصل حجم المعاملة إلى الحصة النسبية.",
        title3: "2.سيتم اعتماد طلب السحب خلال 24 ساعة بعد تقديمه، إذا لم يتم استلام السحب بعد وقت السحب المقدر، فيرجى استشارة خدمة العملاء عبر الإنترنت.",
        title4: "3.بعد تقديم طلب السحب، تكون الأموال في حالة تجميد لأن عملية السحب جارية ويتم الاحتفاظ بالأموال بشكل مؤقت من قبل النظام، وهذا لا يعني أنك فقدت الأصل أو أن هناك خللًا في الأصل.",
        title5: "عملة",
        title6: "عنوان المحفظة",
        title7: "كمية العملات المسحوبة",
        title8: "سجل سحب العملة",
        title9: "الرجاء تحديد العملة",
        title10: "الرجاء تحديد نوع السلسلة",
        title101: "",
        title11: "تفضل",
        title12: "عنوان المحفظة",
        title13: "تم الإرسال بنجاح",
        title14: "قيد المراجعة",
        title15: "تمت المراجعة بنجاح",
        title16: "فشل التدقيق",
        title17: "صرف",
        title18: "الرجاء إدخال أو لصق عنوان محفظتك",
        title19: "الرجاء إدخال مبلغ السحب",
        title20: "رسوم المعالجة",
        title21: "الوصول الفعلي",
        table1: "عملة",
        table2: "كمية العملات المسحوبة",
        table3: "اسم السلسلة",
        table4: "عنوان المحفظة",
        table5: "ولاية",
        table6: "سبب",
        table7: "وقت التطبيق",
        table8: "سحب العملات المعدنية",
        table81: "سحب العملات المعدنية",
        table9: "عنوان السحب",
        table10: "يرجى تحديد العملة التي تريد سحبها",
        table11: "من فضلك تأكد من إدخالك",
        table12: 'العنوان (يبدأ عادةً بـ "0x")',
        table13: "العملات المعدنية التي يمكن سحبها (يتم خصم رسوم المناولة):",
        table14: "اختر العملة",
        table15: "تواجه مشاكل؟ ردود فعل فورية",
        table16: "توازن",
        table17: "تفاصيل الانسحاب",
        password: "كلمة المرور",
        passwordTips: "الرجاء إدخال كلمة المرور",
        passwordAg: "تأكيد كلمة المرور",
        passwordAgTips: "الرجاء إدخال كلمة المرور مرة أخرى",
        oldPassword: "كلمة المرور القديمة",
        oldPasswordTips: "الرجاء إدخال كلمة المرور القديمة",
        newPassword: "كلمة المرور الجديدة",
        newPasswordTips: "الرجاء إدخال كلمة مرور جديدة",
        passwordNotSame: "كلمات المرور غير متناسقة",
        withdrawPasswordSet: "تعيين كلمة مرور السحب",
        withdrawPasswordModify: "تغيير كلمة مرور السحب",
        withdrawPassword: "سحب كلمة المرور",
        withdrawPasswordTips: "الرجاء إدخال كلمة المرور للسحب",
        setWithdrawPasswordFirst: "يرجى تعيين كلمة مرور السحب أولا",
        withdrawPasswordSetSuccess: "تم الإعداد بنجاح",
        withdrawPasswordModifySuccess: "تم التعديل بنجاح",
        submit: "يُقدِّم",
    },
    customer: {
        content1: "خدمة الإنترنت",
        content2: "خدمة الزبائن",
        content3: "يرجى إدخال معلومات الاتصال الخاصة بك",
        content4: "الرجاء إدخال المحتوى",
        content5: "يرسل",
        content6: "فشل الإرسال، فشل الاتصال بالشبكة",
        content7: "صورة",
        content8: "الرسالة فارغة",
        content9: "حصتي",
        content10: "رابط المشاركة",
        content11: "احفظ الصورة",
        content12: "أجوري",
        content13: "وصف المستوى",
        content14: "حجم الفريق",
        content15: "دخل الدعوة",
        content16: "وصف القاعدة",
        content17: "مستوى العضوية",
        content18: "مبلغ إعادة الشحن أكبر من",
        content19: "مستوى الفريق",
        content20: "مستوى الفريق والدخل",
        content21: "لتلبية الشروط",
        content22: "دخل التوزيع",
        content23: "فريق المستوى",
        content24: "المستوى، الدعوة",
        content25: "أعضاء صالحين",
        content26: "الدخل الأساسي",
        content27: "الدخل الثانوي",
        content28: "دخل المستوى 3",
    },
    standardContract: {
        takeProfitPriceWrong: "سعر جني الأرباح خاطئ",
        stoppriceerror: "توقف عن خطأ السعر",
        thetakeprofit1: "يجب أن يكون سعر أخذ الربح أعلى من سعر السوق",
        thetakeprofit2: "يجب أن يكون سعر وقف الخسارة أقل من سعر السوق",
        thetakeprofit3: "يجب أن يكون سعر أخذ الربح أقل من سعر السوق",
        thetakeprofit4: "يجب أن يكون سعر وقف الخسارة أعلى من سعر السوق",
        thetakeprofit5: "كمية خاطئة",
        estimatedprofitloss: "الربح والخسارة المقدرة",
        positionAmount: "عدد المناصب",
        closeAmount: "كمية الإغلاق",
        whenthelatest: "عندما يتم الوصول إلى أحدث سعر",
        whenthelatest2: "، سيتم تفعيل أمر أخذ الربح بسعر السوق لإغلاق المركز الحالي. الربح والخسارة المتوقعة",
        whenthelatest3: "، سيتم تشغيل أمر وقف الخسارة في السوق لإغلاق المركز الحالي. الربح والخسارة المتوقعة",
        contract: "عقد",
        ordercanceledsuccessfully: "تم إلغاء الطلب بنجاح",
        successfullyordered: "تم طلبه بنجاح",
        positionopeningreminder: "تذكير بفتح المركز",
        wrongquantity: "كمية خاطئة",
        quantitycannot: "لا يمكن أن تكون الكمية أكبر من",
        modificationstoploss: "تم تعديل وقف الربح ووقف الخسارة بنجاح",
        positionclosedsuccessfully: "تم إغلاق المركز بنجاح",
        orderCancellation: "إلغاء الطلب",
        orderCancellation2: "هل أنت متأكد أنك تريد إلغاء الطلب؟",
        tobeexecuted: "ليتم تنفيذها",
        expired: "منتهي الصلاحية",
        failed: "فشل",
        contractTransaction: "العقد الدائم",
        increase: "يزيد",
        openPosition: "مستودع كامل",
        isolatedPosition: "موقف معزول",
        limitOrder: "حد السعر",
        marketOrder: "سعر السوق",
        pleaseSelectLeverage: "الرجاء تحديد الرافعة المالية",
        pleaseEnterQuantity: "الرجاء إدخال الكمية",
        canOpenMore: "الحد الأقصى للموقف الذي يمكن فتحه",
        contractAmount: "قيمة العقد",
        openingFee: "رسوم المعالجة",
        currentPosition: "موضع",
        historicalPositions: "المعاملات التاريخية",
        currentCommission: "النظام الحالي",
        historicalCommission: "اللجنة التاريخية",
        buyPrice: "سعر الشراء",
        income: "ربح",
        quantity: "كمية",
        marginRate: "معدل الهامش",
        initialMargin: "الهامش المبدئي",
        estimatedPriceParity: "سعر التصفية القسرية",
        operate: "العمل",
        finishQuantity: "حجم الصفقة",
        averagePrice: "متوسط ​​سعر الصفقة",
        pleaseEnterLeverage: "الرجاء إدخال الرافعة المالية",
        takeProfitAndStopLoss: "جني الربح ووقف الخسارة",
        takeProfit: "اخذ ربح",
        stopLoss: "إيقاف الخسارة",
        openLong: "مفتوح لفترة طويلة",
        openShort: "فتح قصيرة",
        liquidation: "موقف قريب",
        marketLquidation: "سعر السوق ثابت",
        cancel: "الغاء الطلب",
        enterBuyPrice: "الرجاء إدخال سعر الشراء",
        areYouSureLquidation: "هل أنت متأكد من إغلاق الموقف؟",
        confirm: "بالتأكيد",
        enterStopWinPrice: "الرجاء إدخال سعر جني الأرباح",
        enterStopLossPrice: "الرجاء إدخال سعر وقف الخسارة",
        commissionTime: "وقت اللجنة",
        commissionPrice: "سعر العمولة",
        commissionQuantity: "المبلغ الإجمالي للعمولات",
        volumeAlreadyTraded: "تم الانتهاء من المبلغ",
        areYouSureToWinOrLoss: "هل أنت متأكد أنك تريد إيقاف الربح وإيقاف الخسارة؟",
        maxQuantity: "أقصى موقف",
        saleQuantity: "الموقف مغلق",
        salePrice: "متوسط ​​سعر الإغلاق",
        saleTime: "وقت",
        succeeded: "نجح",
        cancelled: "ألغيت",
        piece: "يفتح",
        triggerPrice: "سعر الزناد",
        setWin: "تعيين جني الأرباح",
        setLoss: "تعيين وقف الخسارة",
        enterWinQuantity: "الرجاء إدخال مبلغ جني الأرباح",
        enterLossQuantity: "الرجاء إدخال مبلغ وقف الخسارة",
        planCommission: "عمولة الخطة",
        enterWinTriggerPrice: "الرجاء إدخال السعر المحفز لأخذ الربح",
        enterLossTriggerPrice: "الرجاء إدخال سعر إيقاف الخسارة",
        insufficientQuantity: "كمية غير كافية",
        enterTriggerPrice: "الرجاء إدخال سعر الزناد",
        tips1: "سعر طلبك أعلى من السعر الحالي، وسيتم تنفيذه مباشرة بسعر السوق الحالي، هل أنت متأكد من رغبتك في الشراء؟",
        tips2: "سعر طلبك أقل من السعر الحالي، وسيتم تنفيذه مباشرة بسعر السوق الحالي، هل أنت متأكد من الشراء؟",
        type: "يكتب",
        increaseQuantity: "زيادة الموقف",
        reduceQuantity: "تقليل المواقف",
        limitCommission: "طلب محدود",
        tips3: "يجب أن يكون سعر وقف الخسارة أقل من سعر السوق",
        tips4: "يجب أن يكون سعر أخذ الربح أعلى من سعر السوق",
        tips5: "يجب أن يكون سعر وقف الخسارة أعلى من سعر السوق",
        tips6: "يجب أن يكون سعر أخذ الربح أقل من سعر السوق",
        tips7: "يجب أن يكون سعر وقف الخسارة أقل من سعر السوق وأقل من سعر الأمر",
        tips8: "يجب أن يكون سعر أخذ الربح أكبر من سعر الأمر (ويمكن أن يكون أقل من سعر السوق)",
        tips9: "يجب أن يكون سعر وقف الخسارة أكبر من سعر السوق وأكبر من سعر الأمر",
        tips10: "يجب أن يكون سعر أخذ الربح أقل من سعر الأمر (ويمكن أن يكون أكبر من سعر السوق)",
        accountEquity: "رصيد الحساب",
        unrealizedPL: "الربح أو الخسارة غير المحققة",
        tips11: "هل أنت متأكد أنك على حق",
        tips12: "سعر السوق",
        tips13: "إغلاق كافة المواقف",
        tips14: "لا أذكر مرة أخرى",
        profitRate: "إغلاق معدل العائد",
        openTime: "وقت مفتوح",
        unwindTime: "وقت الإغلاق",
        dealTime: "وقت الصفقة",
        amountClose: "مقدار الموقف المغلق",
        long: "كثير",
        short: 'باطل',
        orderQuantity: "كمية الطلب",
        entrustedMargin: "الهامش الموكل",
    },
    spotGoods: {
        spotGoods: "تبادل فلاش",
        sellPrice: "سعر البيع",
        orderRecord: "سجل الفداء فلاش",
        transactionTime: "وقت الصفقة",
        maxBuy: "متاح للشراء",
        maxSell: "متاحة للبيع",
        purchaseQuantity: "كمية الشراء",
        slippage: "الانزلاق",
        convertTips: "سنقوم بإغلاق الصفقة بالسعر الأكثر ملاءمة مع انزلاق بنسبة 0-5٪.",
        changePrice: "سعر الصرف الحالي",
        consume: "تستهلك",
        get: "يحصل",
        changeTips: "هل أنت متأكد أنك تريد الاسترداد؟",
        confirm: "تأكيد الخلاص"
    },
    news: {
        title: "مركز الأخبار",
        redMore: "اقرأ أكثر",
    },
    credit: {
        credit: "مستوى الرصيد",
        creditScore: "مجموع نقاط الائتمان",
        latestEntry: "أحدث سجل",
        liveUpdate: "التحديث المباشر",
        systemEvaluation: "تقييم النظام",
        time: "تاريخ",
        name: "اسم",
        reason: "سبب",
        scoreStory: "سجل درجة الائتمان"
    },
    borrowing: {
        borrowing: "يُقرض",
        diversifiedMarket: "أسواق متنوعة",
        totalSupply: "إجمالي العرض",
        totalBorrowings: "إجمالي القروض",
        tips1: "هذا هو المبلغ الإجمالي الذي يمكنك اقتراضه مقابل ضماناتك، حتى حد الاقتراض.",
        tips2: "سيظل سعر الفائدة الثابت الخاص بك كما هو طوال مدة القرض الخاص بك. يوصى به للإمداد على المدى الطويل والمستخدمين الذين يحبون القدرة على التنبؤ.",
        totalSupplys: "إجمالي العرض",
        supplyAPR: "معدل العرض",
        totalBorrowings: "إجمالي الاقتراض",
        borrowAPR: "معدل الاقتراض",
        check: "يفحص",
        market: "سوق",
        availableLiquidity: "السيولة المتاحة",
        currencyPrice: "سعر العملة الحالي",
        supplyAssets: "أصول العرض",
        tips3: "ملحوظة: سعر الفائدة أدناه هو سعر فائدة ثابت، وبعد انتهاء فترة العرض، سيتم إرجاع أصل المبلغ بالإضافة إلى الأرباح إلى حسابك.",
        supplyCycle: "دورة العرض",
        minimumSupply: "الحد الأدنى من العرض",
        supply: "إمداد",
        borrowedAssets: "الأصول المقترضة",
        tips4: "من المهم أن تعرف: لاقتراض المال، سوف تحتاج إلى تقديم أي أصول ضمانات أو إثبات،",
        tips5: "يرجى الاطلاع على وصف أصول الرهن العقاري.",
        repaymentCycle: "دورة السداد",
        BorrowableAmount: "مبلغ القرض المتاح",
        borrow: "يُقرض",
        accountInformation: "معلومات الحساب",
        walletBalance: "رصيد المحفظة",
        canSupply: "متاح",
        AmountCanBeBorrowed: "إجمالي مبلغ القرض المتاح",
        borrowedAmount: "المبلغ المقترض",
        remainingAmount: "الرصيد المتبقي",
        getBorrowednAmount: "الحصول على حد القرض",
        supplyOrder: "أمر التوريد",
        borrowedOrder: "أمر القرض",
        supplyAmount: "كمية العرض",
        expectedProfits: "العودة المتوقعة",
        supplyTime: "وقت العرض",
        expirationTime: "تاريخ انتهاء الصلاحية",
        borrowingAmount: "مبلغ القرض",
        dueRepayment: "مبلغ السداد",
        borrowingTime: "وقت الافراج",
        repaymentTime: "وقت السداد",
        repay: "السداد",
        supplyCurrency: "عملة العرض",
        buyNow: "اشتري الآن",
        supplyRecords: "سجلات التوريد",
        borrowRecords: "سجلات القروض",
        completed: "مكتمل",
        borrowNow: "الاقتراض الآن",
        borrowCurrency: "عملة الاقتراض",
        borrowMin: "الأصغر",
        borrowMax: "أقصى",
        borrowRange: "مبلغ الاقتراض (الحد الأدنى/الحد الأقصى)",
        tips6: "يرجى السداد في الوقت المناسب، وفي حالة التأخر عن السداد سيتم تحصيل مبلغ يومي إضافي قدره (أصل المبلغ + الفائدة).",
        tips7: "الرسوم المتأخرة.",
        pleaseEnterBorrowAmount: "الرجاء إدخال مبلغ القرض",
        interest: "اهتمام",
        tips8: "هل أنت متأكد أنك تريد السداد مقدما؟",
        borrowChannel: "قناة الاقتراض",
        tips9: "لنبدأ بالتعرف عليك",
        name: "اسم",
        lastName: "اسم العائلة",
        birthDate: "تاريخ الميلاد",
        tips10: "مزيد من المعلومات، ما هو عنوانك؟",
        familyAddress: "عنوان المنزل (بما في ذلك رقم الوحدة)",
        city: "مدينة",
        country: "أمة",
        postalCode: "رمز بريدي",
        pleaseEnterYourHomeAddress: "الرجاء إدخال عنوان منزلك",
        tips11: "أخبرنا عن وضعك المالي",
        tips12: "قد نحتاج إلى تأكيد هذه المعلومات لاحقًا، لذا يرجى محاولة توخي الدقة قدر الإمكان",
        yourMonthlyIncome: "دخلك الشهري",
        enterYourMonthlyIncome: "الرجاء إدخال دخلك الشهري",
        extraIncomeOptional: "دخل إضافي (اختياري)",
        enterExtraIncome: "الرجاء إدخال دخلك الإضافي",
        monthlyRentOrMortgagePayment: "الإيجار الشهري أو دفع الرهن العقاري",
        enterMonthlyRent: "الرجاء إدخال الإيجار الشهري أو دفع الرهن العقاري",
        tip13: "أخبرنا إذا كان لديك رهن عقاري",
        tip14: "إذا لم يتم تحديد ضمان الرهن العقاري، فإن احتمال اجتياز المراجعة منخفض.",
        isThereMortgage: "هل هناك رهن عقاري؟",
        selectIsThereMortgage: "الرجاء تحديد ما إذا كان هناك رهن عقاري",
        selectMortgageType: "الرجاء تحديد نوع الرهن العقاري",
        yes: "نعم",
        no: "لا",
        uploadScreenshotOfTransaction: "يرجى تحميل لقطات الشاشة للمعاملة",
        tip15: "لنبقى على تواصل",
        tip16: "قد نتصل بك على هذا الرقم إذا كنا بحاجة إلى مزيد من المعلومات حول طلب القرض الخاص بك",
        tip17: "لقد قرأت موافقتي ووقعتها إلكترونيًا",
        tip18: "شروط الاستخدام والموافقة الإلكترونية",
        tip19: "，يتضمن ذلك موافقتك على أن نرسل لك معلومات حول موقعنا",
        tip20: "شروط استخدام المنتج والخدمة",
        tip21: "بعد ملء المعلومات، يرجى انتظار تقييم النظام ومراجعته.",
        phoneNumber: "رقم هاتف",
        enterPhoneNumber: "يرجى إدخال رقم الهاتف الخاص بك",
        tip22: "يرجى تحديد المربع للموافقة على شروط الاستخدام ونموذج الموافقة الإلكترونية",
        inReview: "قيد المراجعة",
        applicationAmount: "مبلغ الطلب",
        reasonForRejection: "سبب الرفض",
        reapply: "إعادة التقديم",
        applyStatus: "حالة التطبيق",
        currentOrder: "النظام الحالي",
        interestRate: "سعر الفائدة"
    },
    yuebao: {
        filed1: "يستثمر",
        filed2: "أخرج",
        filed3: "الدخل اليومي",
        filed4: "تسريع الفريق",
        filed5: "المودعة",
        filed6: "أرباح الأمس",
        filed7: "إجمالي الإيرادات",
        filed8: "الأموال المتاحة",
        filed9: "الرجاء إدخال المبلغ",
        filed10: "تعدين السيولة",
        filed11: "مقدمة",
        field12: "الحد الأدنى لسعر الشراء",
        field13: "أقصى ما يمكنك شراؤه",
        filed14: "الاشتراك",
        filed15: 'عادي',
        filed16: 'سعر الفائدة السنوي',
    },
    tipsPopover: {
        title: "تثبيت هذا التطبيق",
        press: "وفق",
        and: "و",
        scrren: "أضف إلى الشاشة الرئيسية",
        hasInstall: "(إذا تم تثبيته بالفعل، يرجى إغلاق النافذة)",
    },
    follow: {
        title1: "خطة المتابعة",
        follow_text1: "بدء أمر المتابعة",
        follow_text2: "تاريخ الإصدار",
        follow_text3: "عنوان",
        follow_text4: 'زوج التداول',
        follow_text5: 'مدة الشراء',
        follow_text6: 'وقت الطلب',
        follow_text7: 'وقت الافراج',
        follow_text8: 'عدد المتابعين',
        follow_text9: 'كمية الطلب',
        follow_text10: 'العمل',
        follow_text11: 'يدعو',
        follow_text12: 'اتبع اتجاه الطلب',
        follow_text13: 'ضبط شروط الطلب',
        follow_text14: 'شروط اتباع الأوامر',
        follow_text15: 'مبلغ ثابت',
        follow_text16: 'نسبة مئوية',
        follow_text17: 'الناس المدعوين',
        follow_text18: 'هوية الطرف الآخر',
        follow_text19: "تأكيد لمتابعة الطلب",
        follow_text20: "مبلغ طلبك (اختياري)",
        follow_text21: "الرجاء إدخال عنوان",
        follow_text22: "الرجاء اختيار زوج التداول",
        follow_text23: "الرجاء تحديد اتجاه الطلب",
        follow_text24: "الرجاء تحديد مدة الشراء",
        follow_text25: "الرجاء تحديد وقت الطلب",
        follow_text26: "الرجاء تحديد كمية الشراء",
        follow_text27: "لا يمكن أن يكون الحد الأقصى لكمية الشراء أقل من الحد الأدنى لكمية الشراء",
        follow_text28: "الحد الأدنى للشراء",
        follow_text29: "معظم الشراء",
        follow_text30: "تم إنشاء أمر النسخ بنجاح",
        follow_text31: "قائمة زوج التداول",
        follow_text32: "لقد اتبعت الطلب بالفعل",
        follow_text33: "الرجاء إدخال معلومات المدعو",
        follow_text34: "مدعو",
        follow_text35: "دعاني",
        follow_text36: "نسخ التاريخ",
        follow_text37: "نطاق مبلغ الطلب"
    },
    followHistory: {
        title1: 'تاريخ الإصدار',
        title2: "نسخ التاريخ",
        follow_h_text1: "المبلغ الإجمالي للطلب",
        follow_h_text2: "إجمالي عدد الأشخاص الذين يتابعون الأوامر",
        follow_h_text3: "عدد الإصدارات",
        follow_h_text4: "معدل الفوز",
        follow_h_text5: "عدد مرات تنفيذ الأوامر",
        follow_h_text6: "الربح من متابعة الأوامر",
        follow_h_text7: "اتجاه",
        follow_h_text8: "يفوز",
        follow_h_text9: "العجز",
    },
    "asset.index.1579E8B41F8D5134": "أصولي",
    "asset.index.B3F6F05Dfbe431Cd": "محاكاة",
    "asset.index.B9Aeab4Ee8F33746": "تقييم الأصول",
    "asset.index.583Df8Bd541Eb7E8": "اتجاهات الأرباح",
    "asset.index.B1254815D133Cc26": "7 أيام",
    "asset.index.2F615F6C029F01B7": "30 يوما",
    "asset.index.29439Fb58E4Bbe22": "ينسحب",
    "asset.index.831Ab31568A78285": "فوق حتى",
    "asset.index.39F7A0D48D12E8C9": "تحويل",
    "asset.index.B99063C5689Beaec": "تبادل فلاش",
    "asset.index.E1Dc620F2F038B2A": "تلقي الأصول",
    "asset.index.972A0B4C2B4393D0": "حسابي",
    "asset.index.9Dfb0Fb334620733": "الرصيد المتوفر",
    "asset.index.Cdc5Fbd26790Cb47": "كمية مجمدة",
    "asset.index.3D8132D72Ae965Cd": "تقييم",
    "asset.receive.825503Cc430559Cb": "الحصول على الأصول",
    "asset.receive.54154A0553A535B7": "الأصول التجريبية",
    "asset.receive.F2E57B00A9C5D129": "1.يُسمح باستخدام هذا الأصل فقط في وضع الحساب التجريبي",
    "asset.receive.Ea0D1D52989299Bd": "2.لمنع إساءة الاستخدام، يُسمح بعملية استرداد واحدة فقط خلال شهر واحد.",
    "asset.receive.090E0037Aa235D3D": "3.سيؤدي استلام الأصول إلى إضافة مبلغ الأصول المستلمة على الأساس الأصلي ولن يغير حالة المركز.",
    "asset.receive.7Fd4E1Abc805C0A6": "4.إذا كان لديك أي أسئلة أخرى، يرجى الاتصال بخدمة العملاء",
    "asset.receive.Df5B3A511D986575": "احصل على الأصول التجريبية الآن",
    "asset.records.1Fa02E8187A39592": "الأشهر الثلاثة الماضية",
    "asset.records.7B291C37333Fe34D": "من القريب إلى البعيد",
    "asset.records.A476Cd1Ec868Fc5F": "الأشهر الثلاثة الماضية,",
    "asset.records.9D5755A842E55Bb7": "من القريب إلى البعيد,",
    "asset.records.E3Fa05E607203Cdc": "فوق حتى",
    "asset.records.B4F206C6Bec08Dea": "ينسحب",
    "asset.index.Df5B3A511D986576": "لقد استلمت الأصول التجريبية بنجاح",
    "deposit.depositSpeedy.Efe81D0E0174239B": "طريقة الدفع او السداد",
    "deposit.depositSpeedy.B9293Ee96E435F85": "عملة ورقية",
    "deposit.depositSpeedy.16658A855F07D171": "كمية",
    "deposit.depositSpeedy.204Ffab8A6E01870": "بالتأكيد",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "لايمكن ان يكون فارغا",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "الحد الأدنى من التغذية",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "الحد الأقصى لإعادة الشحن",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "قم بإيداع USDT",
    "deposit.depositWallet.551640C76F4Ca4A1": "إعادة شحن العملة",
    "deposit.depositWallet.B404C543712B71D5": "شبكة إعادة الشحن",
    "deposit.depositWallet.A484B0F5E94B3F4F": "عنوان الإيداع",
    "deposit.depositWallet.D3980D54Acf242Db": "حفظ رمز الاستجابة السريعة",
    "deposit.depositWallet.A06E84801D6582Be": "مبلغ إعادة الشحن",
    "deposit.depositWallet.Daaaeb1B7B22B126": "تَلمِيح",
    "deposit.depositWallet.D599E40E1Eeec1B3": "تمت إعادة الشحن بنجاح",
    "deposit.depositWallet.4F48Abbafce532Bb": "تم الإرسال بنجاح، في انتظار مراجعة خدمة العملاء",
    "deposit.depositWallet.A88C481228811696": "لقد رفضت تبديل الشبكات",
    "deposit.depositWallet.C6Ebf27895C21932": "يرجى فتح البرنامج الإضافي لمحفظة TRON يدويًا وتسجيل الدخول",
    "deposit.index.B5491C8Dd09D8183": "حدد العملة التي تريد شحنها",
    "deposit.index.2F784B2298D39987": "شحن سريع",
    "deposit.index.0Daa03Dcdcabd837": "روابط خارج الموقع",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "تأكيد السحب",
    "withdraw.bottomSheet.A086Fa20082C849D": "يرجى التأكد من صحة المعلومات أدناه",
    "withdraw.bottomSheet.Bf8B184D32971501": "شبكة السحب",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "عنوان",
    "withdraw.bottomSheet.F9Db93B87E08763B": "كمية",
    "withdraw.bottomSheet.4Ad26475002878C0": "رسوم المعالجة",
    "withdraw.index.A96454041169Af6D": "اختر العملة",
    "withdraw.index.2A563B7081Dd5B83": "عنوان السحب",
    "withdraw.index.B189C2Ef713Cfff4": "كمية العملات المسحوبة",
    "withdraw.index.558F35140E84B562": "متاح:",
    "withdraw.index.F4E759087E12Cb77": "سحب الكل",
    "withdraw.index.5Cbd9A6B284D25Ae": "كلمة مرور الصندوق",
    "withdraw.index.B89Cbe4Bfc33Bb41": "رسوم المعالجة:",
    "withdraw.index.C886De8D8A27026E": "ملاحظة هامة",
    "withdraw.index.Eac7C5A4095F5606": "1.من أجل منع المراجحة، يمكنك التقدم بطلب لسحب العملة عندما يصل حجم المعاملة إلى الحصة النسبية.",
    "withdraw.index.C3Cc7Fff24504Be9": "2.سيتم اعتماد طلب السحب خلال 24 ساعة بعد تقديمه، إذا لم يتم استلام السحب بعد وقت السحب المقدر، فيرجى استشارة خدمة العملاء عبر الإنترنت.",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3.بعد تقديم طلب السحب، تكون الأموال في حالة تجميد لأن عملية السحب جارية ويتم الاحتفاظ بالأموال بشكل مؤقت من قبل النظام، وهذا لا يعني أنك فقدت الأصل أو أن هناك خللًا في الأصل.",
    "withdraw.index.4639F3A6E07C00B3": "الخطوة التالية",
    "withdraw.index.81Fba0D65Cc5C3Aa": "الرجاء إدخال أو لصق عنوان محفظتك",
    "withdraw.index.25768141C8Bdea91": "الرجاء إدخال مبلغ السحب",
    "withdraw.index.97A85734Cd1B7Bf0": "الرجاء إدخال كلمة المرور للصندوق",
    "withdraw.index.39C7D74B33241C78": "مبلغ السحب خاطئ",
    "withdraw.index.86A5D17908197525": "تأكيد السحب، في انتظار المراجعة لإصدار العملات المعدنية)",
    "withdraw.index.48Fb603C6638C7F6": "يرجى تعيين كلمة مرور للصندوق",
    "withdraw.index.873Ade9A3Bacc2F7": "تَلمِيح",
    "bill.index.5Bbb2A6Ac68D6B2E": "الحساب الفعلي",
    "bill.index.75751E0Eb8261C40": "حساب تجريبي",
    "bill.index.Cbc853B37Ecc2E97": "نوع تغيير الحساب",
    "bill.index.4A0Cacddeb2E38B1": "نوع الحساب",
    "bill.index.F16217028E098B2F": "عملة",
    "bill.index.A620Dbe6F06B78Ba": "كل الانواع",
    "bill.index.2C3C148Bb94D9B3F": "جميع الحسابات",
    "c2C.index.Ab45695E4Bf1E382": "معاملة C2C",
    "c2C.index.2702Dc911Dfea762": "أريد شراء",
    "c2C.index.D737D5B8367A53C4": "أريد أن أبيع",
    "c2C.index.C0C540A3Ba9Abe01": "مقدار",
    "c2C.index.D8644E8D60C8Bd8A": "سعر الوحدة",
    "c2C.index.Ebb8670B203D3604": "حد",
    "c2C.index.F8C6245A2Ebf1D27": "جميع البطاقات المصرفية",
    "c2C.index.F8C6245A2Ebf1D28": "جمع العملة",
    "c2C.index.F8C6245A2Ebf1D29": "بطاقة مصرفية",
    "c2C.index.Cc3Aa8141Da9Ec9E": "يشتري",
    "c2C.index.Cc3Aa8141Da9Ec9F": "يبيع",
    "c2C.index.67A39834582Ad99A": "لايوجد بيانات",
    "c2C.order.04C23810B47F77F7": "حسب المبلغ",
    "c2C.order.6905365902E0A73D": "حسب الكمية",
    "c2C.order.3F0Ecebbd9D2F23B": "متاح",
    "c2C.order.16853Bda54120Bf1": "الجميع",
    "c2C.order.Ad3335796F2C95F7": "مبلغ مقطوع",
    "c2C.order.61092E27A6879318": "لحماية سلامة الأصول، يرجى زيادة الوعي بالوقاية!",
    "c2C.order.0F51881F0Fb920B2": "معلومات التداول",
    "c2C.order.Dd74Ceca85E5Bc65": "الحد الزمني للدفع",
    "c2C.order.De3341388A59073F": "30 دقيقة",
    "c2C.order.D334B597A352167F": "لقب البائع",
    "c2C.order.D334B597A352167G": "لقب المشتري",
    "c2C.order.9D5F89F99Ced890D": "وسائل المعاملات",
    "c2C.order.8Adf755B14Bc6B7C": "يرجى قراءة ما يلي أولا",
    "c2C.order.686B16F454A7338B": "لا تدلي بأي ملاحظات عند إجراء التحويلات بالبطاقة المصرفية، وإلا فلن يتم تحرير الأموال وسيتم حظر الحساب مباشرة. بعد الدفع، يتعين عليك تقديم رسم تخطيطي جديد لتفاصيل المعاملة بعد الدفع (إذا تم إبلاغ النظام الأساسي بالمخطط P أو تفاصيل المعاملة المخفية لتجميد الحساب)",
    "c2C.order.9Bec8Fba867B739B": "اختار طريقة الدفع",
    "c2C.order.Cf2D892C419Fea36": "الحد الأدنى للشراء",
    "c2C.order.7290A6B7456A03E0": "أكبر عملية شراء",
    "c2C.orderConfirm.F7E99B8C203E2B07": "تفاصيل الطلب",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "تم إنشاء الطلب",
    "c2C.orderConfirm.0F5433503F3845Ce": "يرجى الدفع للبائع خلال وقت العد التنازلي.",
    "c2C.orderConfirm.24C3Bcacdb85A451": " في انتظار الدفع للمشترين ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "تواصل مع البائع",
    "c2C.orderConfirm.8E8140D7Ab607B25": "اتصل بالمشتري",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "تم الآن إيداع العملة الرقمية للبائع في حساب الضمان، فلا تتردد في الدفع  ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "اسم المشتري الحقيقي:",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "دعم خدمة العملاء 7 × 24 ساعة ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "رقم الأمر",
    "c2C.orderConfirm.726C51702F70C486": "وقت الابتكار",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "اتصل بخدمة العملاء",
    "c2C.orderConfirm.103408Fbbe30287F": "الغاء الطلب",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "لقد أكدت الدفع",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "مدفوع بالفعل",
    "c2C.orderConfirm.387Ca3625B260E4E": "طلبك المدفوع",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "إلغاء الصفقة",
    "c2C.orderConfirm.76C86566Cc3751C0": "تم إلغاء الطلب",
    "c2C.orderConfirm.205B349751D176B4": "تم الدفع بنجاح",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "طريقة الدفع او السداد",
    "c2C.paymentAdd.C87E865Df6800304": "تكوين طريقة الدفع",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "نوع طريقة الدفع",
    "c2C.paymentAdd.06910Bddf002695F": "نوع طريقة الدفع",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "حساب باي بال",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "رقم الحساب البنكي/رقم البطاقة",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "اسم العملة",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "دفع رمز الاستجابة السريعة",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "دفع رمز الاستجابة السريعة",
    "c2C.paymentAdd.6Daae340F2E9718A": "اسم البنك",
    "c2C.paymentAdd.347751415C3Da489": "اسم السلسلة",
    "c2C.paymentAdd.15B3627Faddccb1D": "ملاحظة",
    "c2C.paymentAdd.86Da96Cbd274058F": "تذكير دافئ: عند بيع العملة الرقمية، سيتم عرض طريقة الدفع التي اخترتها للمشتري، يرجى التأكد من ملء المعلومات بدقة.",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "يحفظ",
    "c2C.paymentAdd.90D2A3A5C07179C0": "الرجاء إدخال اسمك الحقيقي",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "من فضلك أدخل",
    "c2C.paymentAdd.Ec389E6190Eedb62": "الرجاء إدخال اسم البنك الخاص بك",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "الرجاء إدخال اسم السلسلة الخاصة بك",
    "c2C.paymentAdd.94F034F0E01439E0": "الرجاء إدخال عنوانك",
    "c2C.paymentAdd.B12E035663A42B32": "جميع البطاقات المصرفية",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "جمع العملة",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "يرجى تحميل رمز الدفع",
    "c2C.paymentAdd.07C443212Ff04Bfb": "اضيف بنجاح",
    "c2C.paymentDetail.86Da96Cbd274058F": "تذكير دافئ: عند بيع العملة الرقمية، سيتم عرض طريقة الدفع التي اخترتها للمشتري، يرجى التأكد من ملء المعلومات بدقة.",
    "c2C.paymentDetail.C4C613Da60226De4": "تم التعديل بنجاح",
    "c2C.paymentMethod.7609F7C8337C7A17": "طريقة الدفع C2C",
    "c2C.records.9E36893D5736Abb4": "لائحة الطلبات",
    "c2C.records.4B53F9A5B13B3235": "في انتظار الدفع",
    "c2C.records.4B53F9A5B13B3236": "إلغاء الصفقة",
    "c2C.records.4B53F9A5B13B3237": "مدفوع بالفعل",
    "contract.index.9706Df877458F0Be": "مستودع كامل",
    "contract.index.623Fc7D3D40Aa479": "الهامش المبدئي",
    "contract.index.F92Dce1169330387": "سعر السوق ثابت",
    "contract.index.3598B97Dd28Dbf20": "الرجاء إدخال الكمية (قطع)",
    "contract.index.6E1Fae821Dc0B797": "مستمر",
    "contract.index.Eb07Eb0C37C14575": "توصيل",
    "contract.index.Eb6492F19B1C1Fb6": "تفاصيل المعاملات التاريخية",
    "contract.index.873Ade9A3Bacc2F7": "تَلمِيح",
    "contract.index.Ff63B0F93D39F50B": "هل أنت متأكد أنك على حق",
    "contract.index.Ff63B0F93D39F50D": "ذهاب لفتره طويلة",
    "contract.index.Ff63B0F93D39F50E": "قصير",
    "contract.index.Ff63B0F93D39F50F": "سلوك",
    "contract.index.Ff63B0F93D39F50G": "إغلاق كافة المواقف؟",
    "customer.dialog.3D410C88E8A24Fe8": "تأكيد اللقب",
    "customer.dialog.4B3340F7872B411F": "يلغي",
    "customer.dialog.549B1456Bb5F98B3": "الرجاء إدخال اسم الشهرة الخاص بك",
    "customer.dialog.B773B3E45Ccfad00": "اللقب فارغ",
    "customer.index.7308C6B849D159E9": "تفاصيل الصورة",
    "delivery.callDialog.C74F562C3Fe91Fe3": "تم طلبه بنجاح",
    "delivery.callDialog.B8C98F42Caa15Ca5": "اتجاه",
    "delivery.callDialog.B79A581Dae543974": "شراء طويل",
    "delivery.callDialog.B79A581Dae543975": "شراء قصير",
    "delivery.callDialog.3B26F0F7E90773F3": "سعر الافتتاح",
    "delivery.callDialog.65Fb252Bd94E1039": "الإيرادات المقدرة",
    "delivery.index.B483Ba4Fce633539": "توصيل",
    "delivery.index.224Dd6Cb58D58249": "فترة التداول",
    "delivery.index.28158F517E902788": "الحد الأدنى للشراء",
    "delivery.index.A1232162B14Bec76": "أمر التسليم",
    "delivery.index.445B1D3176D9C69D": "أوامر تاريخية",
    "delivery.index.Ab6Cb86E28653C5C": "توصيل",
    "delivery.index.20A7A6F14D3698C0": "وقت مفتوح",
    "delivery.index.Ec6821A49395Ddc2": "وقت الإغلاق",
    "delivery.index.254Fdc665C391743": "سعر نهائي",
    "delivery.index.2A0C5E68B44A472A": "الربح والخسارة",
    "delivery.index.E05B33309F6D236A": "الرجاء إدخال الكمية",
    "delivery.index.B2899E073Cb355D9": "الحد الأدنى للشراء",
    "download.description.869467A93C7Dd4A1": "ملاحظات التركيب",
    "download.description.C80C1Edd6Bee12Bc": "1.السماح بتنزيل ملفات الوصف",
    "download.description.2F5Bc180F92642B4": "2.تم تنزيل ملف الوصف",
    "download.description.3C3B849F6Ccf712F": "3.أدخل [الإعدادات] على هاتفك وسيتم عرض ملف الوصف الذي تم تنزيله أسفل معلومات الحساب.",
    "download.description.86Affd862985E045": "4.انقر لتحميل ملف الوصف",
    "download.description.32832492Bfc1D95E": "5.انقر للتثبيت",
    "download.index.245883C80F181C4A": "تحميل",
    "download.index.Ef3De8Cb2722937A": "ابدأ التداول في أي وقت وفي أي مكان",
    "download.index.F4A87Eaeed691638": "ابق على اطلاع بآخر الأخبار من خلال تطبيقنا",
    "download.index.Ad3Aba52D0D39D01": "قم بتنزيل حزمة التثبيت",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "نصائح لطيفة",
    "download.safariGuide.2Cc68Aaadf623477": "يرجى زيارة في سفاري",
    "download.safariGuide.C80Fff2A6Ff4E768": "أنت تزور موقعًا إلكترونيًا لا مركزيًا، يرجى تشغيله في تطبيق Safari",
    "download.safariGuide.06Da016F3E2F79Bd": "نسخ الوصلة",
    "finance.index.B034B367545D5236": "مشاريع عالمية عالية الجودة",
    "finance.index.6Ebfac2C0A9228B0": "أكثر من 1.6 مليون مستخدم نشط، 122.4 مليار دولار",
    "finance.index.52Bdcaf19659363D": "تعدين السيولة",
    "finance.index.D66924272B031Bf5": "دخل سنوي مرتفع للغاية",
    "finance.index.34789D88Afcefa64": "عرض العناصر",
    "finance.index.E12676B95654D21E": "قرض الائتمان",
    "finance.index.4C924Cfd89A11Ac7": "اكتشف المزيد من فرص التداول",
    "finance.index.C251D4B594Da2848": "القياس الكمي للذكاء الاصطناعي",
    "finance.index.4E53Cf2E5F173Dd3": "التأجير بنقرة واحدة، والمعاملات الذكية المدعومة بالذكاء الاصطناعي",
    "finance.records.Cce168301447D1Ba": "في تَقَدم",
    "finance.records.4587B6Bd7Cfa4454": "مستردة",
    "finance.records.18685418E3A50147": "التعدين",
    "finance.records.Df9Fd438F97Cc908": "سماء",
    "finance.records.E23Cb03C5Ad359E5": "على المدى",
    "finance.records.Bd87Dac6Cba7Eba8": "كمية الشراء الأجل",
    "finance.records.2D0Fff99265E8B1A": "مربحة",
    "finance.records.2D0Fff99265E8B1B": "الربح عند الاستحقاق",
    "finance.records.024F5F0B851782De": "وقت التعدين：",
    "finance.records.3143A26D3F983896": "وقت الفداء：",
    "finance.records.96574Aaf8Db85Ead": "الفداء المبكر",
    "finance.records.531E1829203181Ac": "هل أنت متأكد أنك تريد الاسترداد المبكر؟;",
    "finance.records.9205843B8923Dac3": "تم الفداء بنجاح;",
    "loan.applyCallback.B9258Ec8746A74D8": "قناة الاقتراض",
    "loan.applyForm.7B8Ed340400A6E2B": "اسم",
    "loan.applyForm.549A44Bae725F4A2": "اسم العائلة",
    "loan.applyForm.2A27E61375E7Fea8": "تاريخ الميلاد",
    "loan.applyForm.89925B019D537738": "عنوان البريد الإلكتروني",
    "loan.applyForm.Afaeb32Cb4D62E28": "عنوان المنزل (بما في ذلك رقم الوحدة)",
    "loan.applyForm.E05B5D049B64B040": "مدينة",
    "loan.applyForm.B166E4E8Fe9513Fa": "أمة",
    "loan.applyForm.83Aaede109E395F8": "رمز بريدي",
    "loan.applyForm.647Ed21332A4968B": "دخلك الشهري",
    "loan.applyForm.Cf0A9B845D34D4B4": "دخل إضافي (اختياري)",
    "loan.applyForm.7048F618Acc6A54C": "الإيجار الشهري أو دفع الرهن العقاري",
    "loan.applyForm.15C1Bba18Ae18545": "أخبرنا إذا كان لديك رهن عقاري",
    "loan.applyForm.3921E90625A84E36": "إذا لم يتم تحديد ضمان الرهن العقاري، فإن احتمال اجتياز المراجعة منخفض.",
    "loan.applyForm.262Eec4F8906B0F6": "هل هناك رهن عقاري؟",
    "loan.applyForm.8634Eb21B6910F21": "الرجاء تحديد نوع الرهن العقاري",
    "loan.applyForm.A4897B3A331B5E61": "قسيمة المعاملة",
    "loan.applyForm.6623384C683De457": "لنبقى على تواصل",
    "loan.applyForm.A8360Bdba86834Cb": "قد نتصل بك على هذا الرقم إذا كنا بحاجة إلى مزيد من المعلومات حول طلب القرض الخاص بك",
    "loan.applyForm.C1C373F385A4C7B3": "رقم هاتف",
    "loan.applyForm.1B9785Badb91191F": "بعد ملء المعلومات، يرجى انتظار تقييم النظام ومراجعته.",
    "loan.applyForm.8755B9C761D4080C": "لقد قرأت موافقتي ووقعتها إلكترونيًا",
    "loan.applyForm.3E987B787468148C": "شروط الاستخدام والموافقة الإلكترونية",
    "loan.applyForm.Bc54E76B61C50Fb9": "يرجى قراءة الاتفاقية والموافقة عليها",
    "loan.applyForm.89E158384F634Eb8": "العودة إلى الخطوة السابقة",
    "loan.applyForm.Efa5A4548E2F3510": "عنوان المنزل",
    "loan.applyForm.4Cadf1787541B35D": "نعم,",
    "loan.applyForm.Fab408D2F4100447": "لا,",
    "loan.applyForm.3Bad7A307480Cc56": "تنسيق البريد الإلكتروني خاطئ;",
    "loan.applyForm.306322F49B36855D": "تم تقديم الطلب، برجاء انتظار مراجعة خدمة العملاء",
    "loan.applyForm.3C6E0E858C995Ff9": "يرجى تحميل قسيمة المعاملة",
    "loan.applyForm.2Bf443476B271376": "يمكن تحميل ما يصل إلى 4 صور",
    "loan.applyQuota.Ef1D8E23003B9A6F": "قناة الاقتراض",
    "loan.applyQuota.Ef1D8E23003B9A6G": "الحصول على حد القرض",
    "loan.applyQuota.9E0780F9352C7273": "رصيد المحفظة",
    "loan.applyQuota.Cbc1Eb9D639A6105": "إجمالي مبلغ القرض المتاح",
    "loan.applyQuota.033A05462195B080": "المبلغ المقترض",
    "loan.applyQuota.9Aa89B036Ef4821D": "الرصيد المتبقي",
    "loan.applyQuota.5656Fb52Ef17A557": "الحصول على حد القرض",
    "loan.applyQuota.6Ff6702B4C917D40": "قيد المراجعة",
    "loan.applyQuota.6Ff6702B4C917D41": "مرفوض",
    "loan.applyQuota.6Ff6702B4C917D42": "نجح",
    "loan.applyQuota.0Bad0E857Ea4A952": "وقت التطبيق",
    "loan.applyQuota.3E7E56E2De2Cc546": "مبلغ الطلب",
    "loan.applyQuota.8F1D170D9E54Af5B": "تقدم التطبيق",
    "loan.applyQuota.6167Ca035B93Caf3": "سبب",
    "loan.applyQuota.410C7954F9A3981D": "إعادة التقديم",
    "loan.bottomSheet.61Cd62F861D8F5B2": "الرجاء تحديد العملة",
    "loan.index.4C10307B22E885C3": "اكتشف المزيد من الفرص التجارية وكوّن الثروة",
    "loan.index.Ae8D37Dea2B3717B": "سعر الفائدة",
    "loan.index.1Bb54625682E85Db": "مقدمة القاعدة",
    "loan.index.5Bd31Fab681C6E9D": "شاهد المزيد",
    "loan.index.111F95F404814327": "عملة القرض",
    "loan.index.6Bc2723B3Bec106D": "يُقرض",
    "loan.index.3200106280E6E370": "سعر الفائدة",
    "loan.index.049A6Ccb6C81A635": "مبلغ القرض (الحد الأدنى/الحد الأقصى)",
    "loan.productDetails.5037Ad86Ab4B7E02": "يُقرض",
    "loan.productDetails.37181C74559Db182": "طلب ",
    "loan.productDetails.5802Aaf06A1E4755": "السيولة",
    "loan.productDetails.89Aea9Cf9337C40F": "الكمية المتاحة للاقتراض",
    "loan.productDetails.9E419Ae2D06F3Ad9": "الكمية التي سيتم سدادها",
    "loan.productDetails.C65F329Ef14C4A3D": "كمية العملات المقترضة",
    "loan.productDetails.3A74667815Adc8Bf": "أقصى",
    "loan.productDetails.Ab851D8351573227": "سماء",
    "loan.productDetails.Ddf82D8A2C8F46A0": "دورة السداد",
    "loan.productDetails.C105E5562005Aeb1": "سماء",
    "loan.productDetails.3F346Be2137F0756": "مبلغ القرض (الحد الأدنى/الحد الأقصى)",
    "loan.productDetails.2707F78Df864E194": "اهتمام",
    "loan.productDetails.B9Dc9B81E726Bdf1": "يرجى السداد في الوقت المناسب، وفي حالة التأخر عن السداد سيتم تحصيل مبلغ يومي إضافي قدره (أصل المبلغ + الفائدة).",
    "loan.productDetails.B9Dc9B81E726Bdf2": "%للرسوم المتأخرة",
    "loan.productDetails.4D94Faa033Be7356": "لقد وافقت",
    "loan.productDetails.62429A957A22780A": "اتفاقية خدمة إقراض العملة",
    "loan.productDetails.Bc54E76B61C50Fb9": "يرجى قراءة الاتفاقية والموافقة عليها",
    "loan.productDetails.Ec8Ba071C53A67Cb": "الاقتراض الآن",
    "loan.productDetails.59246A0Bf081D398": "الأقل",
    "loan.productDetails.Ec3F1172B71Cd36C": "الحد الأدنى للمبلغ",
    "loan.productDetails.7Ccc4Cf1D81930B3": "أكبر مبلغ",
    "loan.productDetails.071488598990Aa68": "يرجى قراءة الاتفاقية والموافقة عليها;",
    "loan.productDetails.579Dd216D3339119": "القرض ناجح;",
    "loan.records.4951B36Fd0D2A831": "تاريخ القروض والائتمان",
    "loan.records.Bb0Aaa30514B7Cba": "السجل الحالي",
    "loan.records.0F8B0A7D80769051": "تاريخ السداد",
    "loan.records.D4B42E43D88De6F1": "سجل التاريخ",
    "loan.records.Cb1847D6281897E6": "مبلغ القرض",
    "loan.records.C7Bfcacfc2Adea58": "مبلغ السداد",
    "loan.records.8B369164D5Fea340": "وقت القرض：",
    "loan.records.Ab2402Ad3Ffd35E7": "سداد القرض في وقت مبكر",
    "loan.records.B7A0Ca0C195D0Cd1": "وقت الافراج：",
    "loan.records.42014Bdcfd9C411B": "وقت السداد：",
    "loan.records.A0Acfaee54F06458": "مكتمل",
    "loan.records.67A39834582Ad99A": "لايوجد بيانات",
    "loan.records.6A687Ff5B8F1598F": "هل أنت متأكد أنك تريد السداد مقدما؟",
    "loan.records.7C20D7008D0Eae86": "تم السداد بنجاح",
    "mining.bottomSheet.Ba91637D32Ccba04": "تعدين السيولة",
    "mining.bottomSheet.7De3D636A165E008": "معدل العائد",
    "mining.bottomSheet.F3003345B189D683": "فترة التعدين",
    "mining.bottomSheet.3E4C0A8C533F5848": "نوع العملة",
    "mining.bottomSheet.Adcba368993Ae65E": "مبلغ الاشتراك (الحد الأدنى/الحد الأقصى)",
    "mining.bottomSheet.94743B24E4Ed4166": "غير محدود",
    "mining.bottomSheet.286944Fedd211257": "الأموال المتاحة",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "الإيرادات المقدرة:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "الألغام الآن",
    "mining.bottomSheet.Bb1493C567C114Dd": "نصائح لطيفة：",
    "mining.bottomSheet.D2315E87E34A3Bd3": "رسوم الاسترداد المبكر",
    "mining.index.8B2Bba1422Eed6F0": "خلق عشرات المليارات من الثروة، 100٪ صافي الدخل",
    "mining.index.42997E300086Ee89": "أعلى عائد",
    "mining.index.510F74F42052Ecce": "منتجات التعدين",
    "mining.index.B853F96626106912": "اذهب للتعدين",
    "mining.productList.37181C74559Db182": "طلب",
    "mining.productList.Aac5B810004C46C8": "عادي",
    "mining.productList.D2Fb4F78C387D888": "لقد قمت بالتعدين بنجاح",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "أكد الطلب",
    "quantify.bottomSheet.6915303B698303B1": "إنسان آلي",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "مبلغ الإيجار",
    "quantify.bottomSheet.Fbd931286Be387Aa": "دورة",
    "quantify.bottomSheet.9Ad84Db481807844": "إلى أجل غير مسمى",
    "quantify.bottomSheet.994D7226Ca083Ea5": "معدل العائد اليومي",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "تاريخ القيمة",
    "quantify.bottomSheet.88008603Ba7Bde64": "تم الشراء بنجاح",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "احصل على حصتك من الإيرادات بدءًا من اليوم!",
    "quantify.bottomSheet.C778A674C4A054F9": "تحقق من الطلب",
    "quantify.bottomSheet.685Dad322B7029B0": "معاملة العودة",
    "quantify.buyForm.Acf8560A04334E95": "سعر الشراء",
    "quantify.buyForm.Cf9Cfbc2C554944F": "ملخص",
    "quantify.buyForm.8788Df2B625E97B5": "إنسان آلي",
    "quantify.buyForm.50Ca63B45E881D50": "وقت دفع الأرباح",
    "quantify.buyForm.Fcda03Adbc5Fca97": "كل يوم",
    "quantify.buyForm.Afb89504Fc399189": "الرجاء إدخال كمية الشراء",
    "quantify.buyForm.1435Afdfb4Ec0596": "الحد الأدنى لكمية الشراء",
    "quantify.buyForm.1B97F1730Bacd839": "الحد الأقصى لكمية الشراء",
    "quantify.index.32F56C9B7D0E250A": "المدة: غير محددة",
    "quantify.index.E34F028D00904630": "سعر",
    "quantify.index.E34F028D00904631": ",معدل العائد اليومي",
    "quantify.productDetail.6Bbe178Df539A5Ca": "مبلغ الضمان",
    "quantify.productDetail.53635B53Bca31Dfa": "الدخل اليومي المقدر",
    "quantify.productDetail.F9E46449A6269E09": "الدخل التراكمي",
    "quantify.productDetail.98Fc46B1B8C993B2": "طلب الاستضافة",
    "quantify.productDetail.93624Bb045830966": "الإيجار الآن",
    "quantify.records.0C16A18C32B9Cf4A": "سجل الطلب",
    "quantify.records.B191F5614B0E2312": "تاريخ شراء",
    "quantify.records.6A06033B86A73F7C": "سجل الفداء",
    "quantify.records.45E0F7E71611618E": "الدخل التراكمي",
    "quantify.records.45E0F7E71611618F": "الربح عند الاستحقاق",
    "quantify.records.Aae1201E38C4Baaa": "جري",
    "flash.index.C41Fd4F2Fdfa594B": "تستهلك",
    "flash.index.1Ac59Fa2218E3B00": "متاح:",
    "flash.index.Bf7233A660821549": "يحصل",
    "flash.index.548E775Dd67C7472": "تأكيد الصرف",
    "flash.index.066A2449Bd616F03": "الرجاء إدخال كمية الاسترداد",
    "flash.index.Fca26Fcf9C06563F": "تم استرداد الفلاش بنجاح",
    "message.index.40031524233F4564": "مركز الرسائل",
    "message.index.8E5586210Fd44D69": "تذكير الموقف الدائم",
    "message.index.Bcd3F470E88C197D": "لا يوجد إشعار حتى الآن",
    "message.index.23A557Fbce545879": "إشعار التصفية الدائمة",
    "message.index.B5E19B36B5125550": "تذكير بالانسحاب",
    "message.index.716A981465138334": "تذكير بإعادة الشحن",
    "message.index.884474Ebff2977Ba": "معلومات النظام",
    "message.index.08Cc7D24752Ec42C": "خدمة الإنترنت",
    "message.index.1D820A6A19Ab025D": "خدمة يدوية عبر الإنترنت 7 × 24 ساعة",
    "more.index.29881E0B5Df58Bf7": "المزيد من المزايا",
    "more.index.C77Ee35911D10D9D": "الوظائف المشتركة",
    "more.index.3558F69B0260Be61": "وظيفة التداول",
    "more.index.7243Fe610Ed98Cec": "حساب حقيقي",
    "more.index.7243Fe610Ed98Ced": "حساب تجريبي",
    "more.index.693C7B35Bdf3E3A3": "تمويل",
    "more.index.F855997Fac114554": "مركز المساعدة",
    "more.index.Ae9A1252278Da742": "يساعد",
    "more.index.Ace012504Bc529E0": "معلومة",
    "more.index.05682Dbef5Eeb0A8": "خدمة الزبائن",
    "more.index.C7Af227A5D5C7Bb9": "لقد قمت بالتبديل إلى حساب حقيقي",
    "more.index.D818A65592694786": "لقد قمت بالتبديل إلى حساب تجريبي",
    "notice.details.B360807Dbe727E6E": "معلومات عنا",
    "notice.details.92F2552F695E70E3": "معلومات عنا",
    "notice.details.7Aaceaf69B67F3D1": "مقدمة القاعدة",
    "notice.index.6128438Be34Bcb9F": "إشعار دائم",
    "notice.index.71627F5Eb7128261": "إشعار الانسحاب",
    "notice.index.2Dce570Fda841705": "إشعار إعادة الشحن",
    "notice.index.228A7C9C6448A9B1": "إشعار النظام",
    "setting.dialog.8B4F5B74660Deabe": "تكوين اللون",
    "setting.dialog.Bfc7D25486Ebc1A9": "صعود أخضر وسقوط أحمر",
    "setting.dialog.796Ea0E1A82C320B": "يرتفع الأحمر ويسقط الأخضر",
    "setting.index.8Ddc5864E1739466": "التفضيلات",
    "setting.index.F5205801D57D6727": "لغة",
    "setting.index.2E2C12Dd0Cc1B63B": "عملة الاقتباس",
    "setting.index.322D8496Aec3D918": "رقم الإصدار",
    "setting.index.Aa7E6C204C751Cfa": "خروج",
    "share.index.75Bc38Bbf7D2E766": "ادعو أصدقاء",
    "share.index.45575Eb5D42213E6": "رمز الدعوة الخاص بي",
    "share.index.29F67F0644A78Bf3": "ينسخ",
    "share.index.19Cc65Bb6Bb98Fe7": "رابط دعوتي",
    "share.index.5372712F162Fb78B": "عدد المرؤوسين المباشرين",
    "share.index.C88D6B95E3Ab7D6F": "إجمالي عدد المرؤوسين",
    "share.index.Bb5Fa5471Aeecc62": "خصم العمولة للمرؤوسين المباشرين",
    "share.index.426B6Bac9A5F3D4F": "الخصم الإجمالي",
    "share.index.197E4A428F0401C9": "عرض سجلات الخصم",
    "share.records.88A6B2E2B74B13C4": "سجل اللجنة",
    "share.records.B671A35Ffdc93B89": "الخصم",
    "transfer.index.B17617A869C03Ca5": "محفظة",
    "transfer.index.E4116Dcc6F6C7270": "تم النقل بنجاح",
    "transfer.index.48Fb603C6638C7F6": "يرجى تعيين كلمة مرور للصندوق",
    "transfer.records.Eb0E39Eb5911D418": "يحصل",
    "transfer.records.977554Be0Aa65052": "وقت استرداد الفلاش:",
    "transfer.records.9Aa33884Aa400B7C": "وقت النقل:",
    "form1.forget.76636Db8Fae5B124": "صندوق البريد الخاص بك",
    "form1.forget.35354B9Cca1Bb0E6": "رمز التحقق من البريد الإلكتروني",
    "form1.forget.604A0B11E916F387": "كلمة المرور",
    "form1.forget.9Cb1C9C51E7503D6": "تأكيد كلمة المرور",
    "form1.forget.3A4F7E04B008453C": "رقم تليفونك",
    "form1.forget.Cf11Dce5B4713B5A": "رمز التحقق من الهاتف المحمول",
    "form1.forget.3012Edba57D4F297": "رجاءا أدخل بريدك الإلكتروني",
    "form1.forget.F1Ee1D97E869E447": "الرجاء إدخال رمز التحقق",
    "form1.forget.3A5C4C9Ae68Fa0F6": "من فضلك أدخل رقمك السري",
    "form1.forget.C6Cb174Da411D5F7": "الرجاء إدخال هاتفك المحمول",
    "form1.forget.947B3B21Ee45E582": "يرسل",
    "form1.forget.3E0580F1E8630Df6": "كلمتا مرور غير متناسقتين",
    "form1.forget.C5A61836B8Cc74C6": "تمت إعادة توجيه تغيير كلمة المرور بنجاح...",
    "form1.forget.B96D072Bc8962565": "إعادة إرسال",
    "form1.login.2B72C27Ff828Cfc4": "كلمة السر خاصتك",
    "form1.login.D7C6B872Af9Cb17F": "تذكر كلمة المرور الخاصة بي",
    "form1.login.0Fff44C827A4F3B6": "تسجيل الدخول",
    "form1.login.1B7F2C1Ede3990Cc": "لا تملك حسابا حتى الآن؟ أفتح حساب الأن",
    "form1.login.F657F7Be1Cc33Bd2": "ننسى كلمة المرور",
    "form1.login.2074A1Eec4B088B0": "تسجيل الدخول إلى المحفظة",
    "form1.login.1B8938Fbcc3C9B56": "تم تسجيل الدخول بنجاح وإعادة التوجيه...",
    "form1.login.07162A7Bb9310555": "الرجاء تثبيت محفظة ERC20",
    "form1.login.766969966Ae151Dc": "الرجاء تثبيت محفظة TRC20",
    "form1.login.B88171E1Ad4C50Bf": "لقد رفضت تبديل الشبكات",
    "form1.register.Aaef0710Cb4Db554": "أدخل كلمة المرور مرة أخرى",
    "form1.register.54Dfea0415C708Ff": "شفرة الدعوة",
    "form1.register.6839940Dac08C794": "يسجل",
    "form1.register.9901A3B5550Aa58E": "هل لديك حساب؟ تسجيل الدخول",
    "form1.register.2548Ca53785252Ea": "الرجاء إدخال رمز الدعوة الخاص بك",
    "form1.register.Dd71Fd65900Dd03E": "يرجى قراءة اتفاقية خدمة التسجيل والموافقة عليها,",
    "form1.register.7975613909490B77": "تم التسجيل بنجاح وتم إعادة التوجيه...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "اختر المحفظة",
    "userc.certification.2Af9511640153D2E": "شهادة جونيور",
    "userc.certification.177B191D8E0Fa84D": "غير معتمد",
    "userc.certification.601218D4D572E98A": "تم التحقق",
    "userc.certification.6E37C51Ba9012Fc8": "لم ينجح",
    "userc.certification.1B9B563E05C96474": "شهادة متقدمة",
    "userc.certification.1418B83803Ab56B4": "يرجى إكمال الشهادة الأساسية أولاً",
    "userc.creditScore.0B149112601Acc4D": "مستوى الرصيد",
    "userc.creditScore.88Ac7F84E106C5F6": "مجموع نقاط الائتمان",
    "userc.creditScore.3E1D6E0E7F1E6155": "تقييم النظام",
    "userc.index.62Eac58D04Bbca0F": "مركز شخصي",
    "userc.index.F4Cac53F93Cb2Aff": "محاكاة التداول",
    "userc.index.42F409Fdf3559773": "مركز أمني",
    "userc.index.1B8E211009807Db8": "المصادقة",
    "userc.index.Bdfcf21Eb8F2Eb70": "سجلات تغيير الحساب",
    "userc.index.6C18D67F6D19586B": "مقدمة المنصة",
    "userc.index.807A814Ed58A93Bc": "مسح ذاكرة التخزين المؤقت",
    "userc.index.E43Daad59759611C": "غير معتمد",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "تغيير كلمة المرور لتسجيل الدخول",
    "userc.secureCenter.1F37Ba427Debf24A": "ربط الحساب",
    "userc.secureCenter.F9A5Ffa750664004": "ربط أداة مصادقة Google",
    "userb.certificationForm.7685Af7D30043Cd6": "شهادة جونيور",
    "userb.certificationForm.7685Af7D30043Cd7": "شهادة متقدمة",
    "userb.certificationForm.70E37C35Abc3F462": "دولة",
    "userb.certificationForm.F92C25A68E4D08C5": "مدينة",
    "userb.certificationForm.0Ccc6387B38E0318": "اسمك",
    "userb.certificationForm.94C67Bba370Fdd46": "رقم الرخصة",
    "userb.certificationForm.B2D4C7Fffe79258E": "بريد",
    "userb.certificationForm.57554268761Fb47A": "قم بتحميل الجزء الأمامي من بطاقة هويتك",
    "userb.certificationForm.D79E90201F5319Bd": "قم بتحميل الجزء الخلفي من بطاقة هويتك",
    "userb.certificationForm.9C6Bad08Af29E20E": "قم بتحميل صورة لبطاقة الهوية الخاصة بك",
    "userb.certificationForm.F96B62Dfa31Cde45": "تأكيد التقديم",
    "userb.certificationForm.2125D78Ea8C6D287": "متطلبات التحميل",
    "userb.certificationForm.0D64864D3076A824": "عرض المثال",
    "userb.certificationForm.35287Deb72281E87": "1.يجب أن يكون قادرًا على قراءة معلومات بطاقة الهوية بوضوح.",
    "userb.certificationForm.3007F2E01Efc7B57": "2.محتوى الصورة صحيح وصحيح ولا يسمح بأي تعديل.",
    "userb.certificationForm.25A90660554319F9": "3.يجب أن يكون رقم الهوية والاسم مرئيين بوضوح، ويدعم JPG / JPEG / PNG",
    "userb.certificationForm.5A9Aefbc03C778F7": "تفضل",
    "userb.certificationForm.3B7A1D8Ce27C937E": "تم الإرسال بنجاح، يرجى انتظار المراجعة",
    "userb.certificationForm.744Bd07D8Abd2513": "يرجى تحميل الجزء الأمامي من بطاقة الهوية الخاصة بك",
    "userb.certificationForm.6De1C319130F7Fef": "يرجى تحميل الجزء الخلفي من بطاقة الهوية الخاصة بك",
    "userb.certificationForm.79F8E8B172E30C8A": "يرجى تحميل صورة بطاقة الهوية الخاصة بك",
    "userb.certificationForm.46412E16D28A4753": "فشل تحميل الصورة",
    "userb.changeDialog.676B0Adca1F8Eea4": "تغيير كلمة المرور",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "رقم التليفون",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "تبديل المدقق",
    "userb.modifyInfoDialog.62149E566814154C": "حدد المدقق",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "افتح تطبيق المصادقة، ثم اضغط على +",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": 'حدد "مسح رمز الاستجابة السريعة" أو "أدخل المفتاح".',
    "userb.modifyInfoDialog.93F094E2B4369472": "امسح رمز الاستجابة السريعة ضوئيًا أو أدخل المفتاح",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "اقفل افتح",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "كلمة مرور الصندوق القديمة",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "كلمة المرور الجديدة للصندوق",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "تأكيد كلمة المرور الجديدة للصندوق",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "تأكيد كلمة مرور الصندوق",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "الرجاء إدخال كلمة المرور الخاصة بصندوقك القديم",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "الرجاء إدخال كلمة المرور الجديدة للصندوق",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "الرجاء إدخال كلمة مرور الصندوق الخاص بك",
    "userb.modifyInfoDialog.258A534498Fe984F": "يرجى تأكيد كلمة مرور الصندوق الخاص بك",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "ربط الحساب",
    "userb.modifyInfoDialog.D34746090C25E5Db": "كلمة مرور الصندوق",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "يتأكد",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "تم إغلاق أداة التحقق",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "تم تفعيل التحقق من Google بنجاح",
    "userb.modifyInfoDialog.24Dba26593F43C74": "تم تعديل كلمة مرور الصندوق بنجاح",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "تم ضبط كلمة مرور الصندوق بنجاح",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "الربط ناجح",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "خطأ في رمز التحقق",
    "userb.veBottomSheet.Eecc4683C725F6F2": "مثال لتحميل الشهادة",
    "userb.veBottomSheet.6F38A0D5858633Ee": "خلفية نظيفة داكنة",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "إضاءة كافية",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "محاذاة الإطار المساعد",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "الصور واضحة",
    "userb.veBottomSheet.50B741382E71F103": "الأسباب المحتملة للفشل",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "لم تتم إزالة الغطاء الواقي لبطاقة الهوية",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "تتأثر الخلفية بالفوضى",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "بطاقة الهوية عاكسة",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "الضوء غامق جدًا/محجوب بظل الهاتف المحمول",
    "userb.veBottomSheet.1A75D2Fc2614592B": "يحمل بطاقة الهوية",
    "home.home.C3525Eb110Db58Aa": "أكثر",
    "home.home.29761532Fc8Ebecc": "تجارة",
    "home.home.F15Ea99Bfa6E1848": "شهادة السلامة، قم بالشراء بثقة",
    "home.home.46E1Ab39Bae7182A": "سريع",
    "home.home.Fde2Eb7F6F3Dd800": "معاملات سريعة وعملية بسيطة",
    "home.home.Ea4756Bc1642E0F1": "اسم",
    "home.home.B6B1127Ede1C97B4": "أحدث السعر",
    "home.home.3587Ad870Eb0Ab86": "24 ساعة زيادة ونقصان",
    "home.home.Cb1A4E14Ec2Cd2B5": "يكتشف",
    "home.home.0F1B1Ac0E96A40D1": "بلوكتشين",
    "home.home.8Eaefb1107A0E210": "الفوركس",
    "home.home.Ad65263D034Ea051": "المعادن الثمينة",
    "home.home.5Fc1474Dbf74Ac1A": "المنتجات الزراعية",
    "home.home.135318F4664F7773": "طاقة",
    "kline.kLine.C96Ff250Cf6B8B84": "24 ساعة أعلى سعر",
    "kline.kLine.6E8Be64F19D74565": "حجم التداول على مدار 24 ساعة",
    "kline.kLine.08A4Bfbe26A0F262": "24 ساعة بأقل سعر",
    "kline.kLine.8701Deb075066331": "حجم التداول على مدار 24 ساعة (USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1 نقطة",
    "kline.kLine.B49Cca240E013124": "5 نقاط",
    "kline.kLine.F27A90Be97Ba3D6C": "15 علامة",
    "kline.kLine.Ff3E62395B694384": "30 نقطة",
    "kline.kLine.C1993F9C6Db1A674": "1 ساعة",
    "kline.kLine.Fd2Cbbae405E284C": "يوم 1",
    "kline.kLine.C9Cd71904395042C": "يناير",
    "kline.kLine.7D8127Ec18C7B8C7": "يشتري",
    "kline.kLine.E3D3Ffdb9B3E5636": "الكمية (قطع)",
    "kline.kLine.6834Fec1838D8029": "السعر (USDT)",
    "kline.kLine.16C52Aae474Bc9Cd": "أمر بيع",
    "kline.kLine.B9Bea1A9A88D1419": "مفتوح لفترة طويلة",
    "kline.kLine.D36Cfa96Ee2B343B": "فتح قصيرة",
    "other.languageRateSelect.6897784800834C1B": "إعدادات اللغة",
    "other.languageRateSelect.6897784800834C1C": "الرجاء تحديد عملة التسعير",
    "other.navigationBar.F6E62F9642018Cbb": "الصفحة الأمامية",
    "other.navigationBar.0Ec227161A47B49D": "يقتبس",
    "other.navigationBar.29761532Fc8Ebecc": "تجارة",
    "other.navigationBar.693C7B35Bdf3E3A3": "تمويل",
    "other.navigationBar.9734C54620Eb09Af": "أصول"
}
