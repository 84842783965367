<template>
  <v-dialog v-model="isShow" persistent>
    <v-card>
      <v-card-title class="text-h6" v-if="title">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <div v-html="text"></div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="closeClick" v-if="closeText">
          {{ closeText }}
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="confirmClick"
          v-if="confirmText"
        >
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isShow: false,
    };
  },
  watch: {
    value(v) {
      this.isShow = v;
    },
  },
  props: {
    isTime: {
      type: Boolean,
      default: () => false,
    },
    dwidth: {
      type: String,
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
    value: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    text: {
      type: String,
      default: () => "",
    },
    sub: {
      type: String,
      default: () => "",
    },
    confirmText: {
      type: String,
      default: () => "",
    },
    closeText: {
      type: String,
      default: () => "",
    },
    type: {
      type: String,
      default: () => "",
    },
    isSlot: {
      type: Boolean,
      default: () => false,
    },
    bg: {
      type: String,
      default: () => "transparent",
    },
    fullscreen: {
      type: Boolean,
      default: () => false,
    },
    dialogData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(["getThemeType"]),
  },
  methods: {
    closeClick() {
      this.$emit("update:value", false);
    },
    confirmClick() {
      this.$emit("confirm", this.dialogData);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.dialog-box {
  padding: 10px;
  .dialog-title {
    font-size: 24px;
    color: #ffffff;
    padding: 0 20px;
  }
  .dialog-text {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
    padding: 0 20px;
  }
  .dialog-sub {
    font-size: 13px;
    color: #c1c1c1;
    padding: 0 20px;
  }
  .btn-box {
    margin-top: 30px;
    button {
      height: 35px;
      //   background: rgba(79, 67, 145, 0.4);
      background: rgb(76 72 93 / 40%);
      border-radius: 10px;
    }
  }
  .confirm-btn {
    // background: #4f4391 !important;
  }
  .cancel-btn {
  }
}
.close {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  text-align: center;
  line-height: 27px;
  //   background-image: url("../../../public/img/icon/close.png");
  background-size: 100%;
  cursor: pointer;
}
</style>