<template>
  <v-bottom-navigation
    class="bottom-navigation"
    fixed
    v-model="value"
    color="#106CF5"
    grow
  >
    <v-btn :height="80" @click="$router.push('/')">
      <span class="mt-2">{{
        $vuetify.lang.t("$vuetify.other.navigationBar.F6E62F9642018Cbb")
      }}</span>
      <v-img
        max-height="25"
        max-width="25"
        src="../../public/image/tabbar/home.png"
      ></v-img>
    </v-btn>

    <v-btn :height="80" @click="$router.push('/market')">
      <span class="mt-2">{{
        $vuetify.lang.t("$vuetify.other.navigationBar.0Ec227161A47B49D")
      }}</span>
      <v-img
        max-height="25"
        max-width="25"
        src="../../public/image/tabbar/market.png"
      ></v-img>
    </v-btn>

    <v-btn :height="80" @click="contractClick">
      <span class="mt-2">{{
        $vuetify.lang.t("$vuetify.other.navigationBar.29761532Fc8Ebecc")
      }}</span>
      <v-img
        max-height="25"
        max-width="25"
        src="../../public/image/tabbar/trade.png"
      ></v-img>
    </v-btn>
    <v-btn :height="80" @click="$router.push('/finance')">
      <span class="mt-2">{{
        $vuetify.lang.t("$vuetify.other.navigationBar.693C7B35Bdf3E3A3")
      }}</span>
      <v-img
        max-height="25"
        max-width="25"
        src="../../public/image/tabbar/finance.png"
      ></v-img>
    </v-btn>
    <v-btn :height="80" @click="$router.push('/assets')">
      <span class="mt-2">{{
        $vuetify.lang.t("$vuetify.other.navigationBar.9734C54620Eb09Af")
      }}</span>
      <v-img
        max-height="25"
        max-width="25"
        src="../../public/image/tabbar/assets.png"
      ></v-img>
    </v-btn>
  </v-bottom-navigation>
</template>
  <script>
export default {
  data() {
    return {
      value: 0,
    };
  },
  mounted() {
    if (this.$route.path == "/") {
      this.value = 0;
    } else if (this.$route.path == "/market") {
      this.value = 1;
    } else if (
      this.$route.path == "/contract" ||
      this.$route.path == "/delivery"
    ) {
      this.value = 2;
    } else if (this.$route.path == "/finance") {
      this.value = 3;
    } else if (this.$route.path == "/assets") {
      this.value = 4;
    } else {
      this.value = 0;
    }
  },
  watch: {
    $route(v) {
      if (v.path == "/") {
        this.value = 0;
      } else if (v.path == "/market") {
        this.value = 1;
      } else if (v.path == "/contract" || v.path == "/delivery") {
        this.value = 2;
      } else if (v.path == "/finance") {
        this.value = 3;
      } else if (v.path == "/assets") {
        this.value = 4;
      } else {
        this.value = 0;
      }
    },
  },
  methods: {
    contractClick() {
      let contractType = localStorage.getItem("contract-type");
      if (contractType) {
        this.$router.push(contractType);
      } else {
        this.$router.push("/contract");
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.bottom-navigation {
  //   position: relative;
  //   background-color: var(--v-navigation_bg-base);
  .active-menu {
    position: fixed;
    bottom: 13px;
  }
  .v-btn--has-bg {
    // background-color: var(--v-navigation_bg-base);
  }
}
.v-bottom-navigation {
  height: 80px !important;
  border-radius: 30px 30px 0 0;
  overflow: hidden;
  .nat-span {
    // color: var(--v-secondary-base);
  }
}
</style>