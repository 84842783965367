import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Message from './plugins/toast/index'
import router from './router'
import store from './store'
import BackAlert from "@/components/alert/BackAlert.vue";
import "./assets/scss/style.scss";
import './mixins'
import '@/assets/font/font.css';
Vue.config.productionTip = false
Vue.prototype.$message = Message
Vue.component('BackAlert', BackAlert)
new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
}).$mount('#app')
