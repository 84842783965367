import pako from 'pako' // 解压gzip
import Vue from 'vue'
import config from '../config'
import bus from '@/utils/bus'
import store from '@/store'
export default class Socket {
    socket = null
    maxReload = 10
    that = null
    timeout = 10000
    heart = null;
    ws_state = false;
    constructor(that) {
        this.wsurl = config.WS_URL;
        this.that = that
        this.createSocket()
    }
    createSocket() {
        this.socket = new WebSocket(this.wsurl)
        this.socket.onopen = this._onopen.bind(this)
        this.socket.onclose = this._onclose.bind(this)
        this.socket.onerror = this._onerror.bind(this)
        this.socket.onmessage = this._onmessage.bind(this)
    }
    _onopen(msg) {
        console.log(msg, 'ws已连接')
        this.ws_state = true;
        bus.$emit('ws-connect')
        this.heartbeat();
    }
    _onclose(err) {
        // 关闭连接
        console.log(err, 'ws已断开')
        this.ws_state = false
        if (this.maxReload > 0) {
            setTimeout(() => {
                this.createSocket()
                this.maxReload -= 1
            }, 1000)
        }
    }
    _onerror(err) {
        this.ws_state = false
        //错误，结束
        console.log(err, 'ws连接错误')
        this.socket.close()
    }
    close() {
        console.log('aaa???')
        this.socket.close()
    }
    _onmessage(e) {
        let result = this.decoding(JSON.parse(JSON.stringify(e.data)));

        if (result.operationType == 4) {
            if (result.dataType == 1) {
                //行情数据
                let quoteData = JSON.parse(JSON.stringify(store.state.quoteData));
                let updateData = quoteData.map(item => {
                    if (item.coinId == result.data.coinId) {
                        return {
                            ...item,
                            close: result.data.close,
                            high: result.data.high,
                            low: result.data.low,
                            open: result.data.open,
                            range: result.data.range,
                            vol: result.data.vol
                        }
                    }
                    return item
                })
                this.that.setQuoteData(updateData)
            } else if (result.dataType == 2) {
                //永续合约行情数据
                let quoteData = JSON.parse(JSON.stringify(store.state.contractQuote));
                let lastClose = quoteData && quoteData[result.perpetualData.coinId] && quoteData[result.perpetualData.coinId]['close'];
                let lastRange = quoteData && quoteData[result.perpetualData.coinId] && quoteData[result.perpetualData.coinId]['range'];

                let color;
                if (lastClose > result.perpetualData.close) {
                    color = '#e35461' //red
                } else {
                    color = '#37b66a' //green
                }
                if (result.perpetualData.range) {
                    lastRange = result.perpetualData.range
                }
                quoteData[result.perpetualData.coinId] = {
                    ...result.perpetualData,
                    color,
                    lastRange
                }
                this.that.setContractQuote(quoteData)

                //交割币币行情数据deliveryQuote
                let delivery_quoteData = JSON.parse(JSON.stringify(store.state.deliveryQuote));
                let delivery_lastClose = quoteData && quoteData[result.data.coinId] && quoteData[result.data.coinId]['close'];
                let delivery_lastRange = quoteData && quoteData[result.data.coinId] && quoteData[result.data.coinId]['range'];
                let delivery_color;
                if (delivery_lastClose > result.data.close) {
                    delivery_color = '#e35461' //red
                } else {
                    delivery_color = '#37b66a' //green
                }
                if (result.data.range) {
                    delivery_lastRange = result.data.range
                }
                delivery_quoteData[result.data.coinId] = {
                    ...result.data,
                    color: delivery_color,
                    lastRange: delivery_lastRange
                }
                this.that.setDeliveryQuote(delivery_quoteData)

            } else if (result.dataType == 3) {
                //深度数据
                bus.$emit('depth-update', result.perpetualData)
                bus.$emit('depth-delivery-update', result.data)
            } else if (result.dataType == 4) {
                //成交历史数据
                bus.$emit('contract-deal-update', result.perpetualData)
                bus.$emit('contract-delivery-deal-update', result.data)
            }
        }
    }
    send(_data) {
        //发送订阅 
        if (this.ws_state) {
            let data = this.coding(_data)
            this.socket.send(data)
            return this
        }
    }
    //编码
    coding(input) {
        const output = pako.gzip(JSON.stringify(input), {
            to: "string"
        });
        return output;
    }
    //解码
    decoding(input) {
        let restored = pako.inflate(input, { to: 'string' });
        try {
            restored = JSON.parse(restored)
        } catch (error) {
            restored = restored
        }
        return restored;
    }
    heartbeat() {
        this.heart = setInterval(() => {
            this.send({ "operationType": 2 });
        }, this.timeout);
    }
    formatData(data) {
        if (this.that.getProductData && this.that.getProductData[data.symbol]) {
            let item = this.that.getProductData[data.symbol]
            let old_buy_price = item['buy_price']
            let old_sell_price = item['sell_price']
            if (old_buy_price > data.buy_price) {
                item['buy_price_direction'] = 'down'
            } else {
                item['buy_price_direction'] = 'up'
            }
            if (old_sell_price > data.sell_price) {
                item['sell_price_direction'] = 'down'
            } else {
                item['sell_price_direction'] = 'up'
            }
            item['buy_price'] = data.buy_price
            item['sell_price'] = data.sell_price
        }
    }
}
