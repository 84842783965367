export default {
    isLogin: false,
    configData: {},
    baseQuotes: {},
    updateQuotes: {},
    innerHeight: null,
    symbolList: [],
    showBalance: true,
    investData: [],
    userInfo: {},
    language: "",
    rateList: [],
    currentRate: {},
    authInfo: {},
    themeType: "",
    socket: null,
    quoteData: [],
    languageList: [],
    contractQuote: {},
    miningData: [],
    funds: {},
    loanList: [],
    deliveryQuote: {},
    selectFiatCoin: {},
    socketLastTime: {
        contract: "",
        delivery: "",
        market: ""
    },
    formFiles: [],
    formImgs: []
}