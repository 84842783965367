import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import zhHans from '@/langs/zhHans';
import en from '@/langs/en';
import ar from '@/langs/ar';
import de from "@/langs/de"
import zhHant from '@/langs/zhHant';
import es from '@/langs/es';
import fr from '@/langs/fr';
import id from "@/langs/id"
import it from '@/langs/it';
import ja from '@/langs/ja';
import ko from '@/langs/ko';
import nl from '@/langs/nl';
import pl from '@/langs/pl';
import pt from '@/langs/pt';
import ru from '@/langs/ru';
import sv from '@/langs/sv';
import th from '@/langs/th';
import vi from '@/langs/vi';
Vue.use(Vuetify);
Vue.component('my-component', {
    methods: {
        changeLocale() {
            this.$vuetify.lang.current = 'en'
        },
    },
})
export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary1: '#F2F4F7',
                secondary: "#131419",
                secondary_color: "#ABC4FA",
                color1: "#106CF5",//蓝色
                color2: "#B0B2B5",//灰色 副标题
                color3: "#111418",//黑色
                color4: "#656E7B",//灰色 table标题
                color5: "#93B3F9", // market tab
                bg_primary: "#106cf5",//蓝色卡片背景
                color6: "#888F99",//灰色，表单label
                border1: "#E7EAEE",//边框
                border2: "#C0C3C8"//边框2
            },
            dark: {
                primary1: "#000",
                secondary: "#888f99",
                secondary_color: "#ABC4FA",
                color1: "#106CF5",
                color2: "#B0B2B5",
                color3: "#111418",//黑色
                color4: "#656E7B",//灰色 table标题
                color5: "#93B3F9", // market tab
                bg_primary: "#106cf5",//蓝色卡片背景
                color6: "#888F99",//灰色，表单label
                border1: "#E7EAEE",//边框
                border2: "#C0C3C8"//边框2
            }
        }

    },
    lang: {
        locales: { zhHans, en, zhHant, ar, de, es, fr, id, it, ja, ko, nl, pl, pt, ru, sv, th, vi },
        current: 'en',
    },
});
